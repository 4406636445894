import React from 'react'
import PageContainer from '../../components/page/PageContainer'
import {
  RoleSettingsViewModel,
  IRoleSettingsViewModelProps,
  useRoleSettingsViewModel,
} from './RoleSettingsViewModel'
import { ROUTE_ROLES } from '../roles/Roles'
import { useParams } from 'react-router-dom'
import ErrorContainer from '../../components/error/ErrorContainer'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import PermissionList from '../../components/permissions/PermissionList'
import { formatSnakeCase } from '../../../core/utils/text-formaters/TextFormatters'

export const RoleSettings: React.FC = () => {
  return (
    <RoleSettingsViewModel>
      <_Page />
    </RoleSettingsViewModel>
  )
}

export const _Page: React.FC = () => {
  const { role }: IRoleSettingsViewModelProps = useRoleSettingsViewModel()
  // const canCreate = usePermission(ERole.FormCreate)
  const { id } = useParams()

  const renderRole = (name: string) => {
    return (
      <InnerPageContainer
        collapsible
        collapsed={true}
        headerTitle={name}
        sx={{ padding: '24px', marginBottom: '24px' }}
      >
        <PermissionList roles={role} />
      </InnerPageContainer>
    )
  }

  if (!role)
    return (
      <ErrorContainer>
        <p>Could not find role</p>
      </ErrorContainer>
    )
  const name = formatSnakeCase(role.name)
  return (
    <PageContainer
      title={`Role: ${name}`}
      breadCrumbs={[
        { title: 'All Roles', to: ROUTE_ROLES },
        { title: `${name}`, to: `${ROUTE_ROLES}/${id}` },
      ]}
    >
      <p>Role Page {id}</p>

      {renderRole(name)}
    </PageContainer>
  )
}
