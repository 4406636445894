import React, { useState } from 'react'
import { EdifyButton } from '../../components/buttons'
import { Box } from '@mui/material'
import FilterButtonGroup from '../../components/buttons/filter-button-group/FilterButtonGroup'
import { LevelsPageViewModelProps } from './LevelsViewModel'
import { ILevelsQueryField, searchLevels } from '../../../domain/domain'
import { useNavigate } from 'react-router-dom'
import EdifySearchDropdown, {
  ISearchResult,
} from '../../components/form/EdifySearch/EdifySearchDropdown'
import { LevelOptions } from '../../../domain/interfaces/IProjects'
import { useTableWidth } from '../../../core/hooks'
import FilterContainer from '../../components/page/FilterContainer'

interface ILevelsFilterProps {
  vm: LevelsPageViewModelProps
  buttonTitle: string
  canCreate?: boolean
}

const FILTER_OPTIONS = ['All', 'Favorites', 'Active', 'Inactive']

const LevelsFilter: React.FC<ILevelsFilterProps> = ({
  vm,
  buttonTitle,
  canCreate,
}) => {
  const navigate = useNavigate()
  const [activeButton, setActiveButton] = useState<string>('All')
  const { query } = vm.queryHelper
  const width = useTableWidth(true)

  const handleFilterButtonClicked = (button: string) => {
    const newLevelsQueryField: ILevelsQueryField = { ...query, tab: button }
    vm.fetchLevels(0, newLevelsQueryField)
  }

  const handleSearchSelected = (searchItems: ISearchResult[]) => {
    if (searchItems.length == 0) {
      vm.fetchLevels(query.skip, query)
      return
    }
    vm.onLevelSearch(searchItems[0])
  }

  return (
    <>
      <FilterContainer data-testid={'LevelsFilter'}>
        <FilterButtonGroup
          onButtonClicked={handleFilterButtonClicked}
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          buttons={[...FILTER_OPTIONS]}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <EdifySearchDropdown
            placeholder='Search Name'
            searchFunction={(searchTerm: string) =>
              searchLevels(searchTerm, LevelOptions.One)
            }
            onSelect={handleSearchSelected}
            // width={width < 900 ? 200 : 280}
            width={280}
          />
          {canCreate && (
            <EdifyButton
              onClick={() => navigate('/levels/1/new')}
              buttonStyle={{ marginLeft: '8px' }}
              title={width < 800 ? 'New' : `Create New ${buttonTitle}`}
              primary
            />
          )}
        </Box>
      </FilterContainer>
    </>
  )
}

export default LevelsFilter
