import React, { useContext, useEffect, useState } from 'react'
import { adminGetRoles } from '../../../domain/domain'
import { IRole } from '../../../domain/interfaces/IRole'

export interface IRoleSettingsViewModelProps {
  loading: boolean
  error: string | undefined
  roles: IRole[] | undefined
}

const RoleSettingsContext =
  React.createContext<IRoleSettingsViewModelProps | null>(null)

export function useRoleSettingsViewModel(): IRoleSettingsViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(RoleSettingsContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const RoleSettingsViewModel: React.FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [roles, setRoles] = useState<IRole[] | undefined>(undefined)

  const getRoles = async () => {
    setLoading(true)
    setError(undefined)
    const res = await adminGetRoles()
    setLoading(false)
    if (res.isLeft()) {
      setError(res.left?.message ?? 'Error loading roles.')
      return
    }
    setRoles(res.right)
  }

  useEffect(() => {
    getRoles()
  }, [])

  return (
    <RoleSettingsContext.Provider
      value={{
        roles,
        error,
        loading,
      }}
    >
      {children}
    </RoleSettingsContext.Provider>
  )
}
