import {
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  SxProps,
} from '@mui/material'
import React, { ReactNode } from 'react'
import { AppColors, AppFonts, AppHovers } from '../../../Theme'
import { ExpandLess } from '@mui/icons-material'
import { GridClearIcon } from '@mui/x-data-grid'

interface EdifySelectProps extends SelectProps {
  onChange: (event: any) => void
  onClear?: () => void
  dark?: boolean
  dropDownItems: any[]
  renderMenuItem: (item: any) => JSX.Element
  darkMode?: boolean
  width?: number | string
  sx?: SxProps
  small?: boolean
  showClear?: boolean
  disabled?: boolean
  value?:any
  showPlaceHolder?: boolean
}

export const EdifySelect: React.FC<EdifySelectProps> = ({
  dropDownItems = [],
  onChange,
  value,
  renderMenuItem,
  label,
  darkMode = false,
  width = 'auto',
  sx,
  small = false,
  placeholder,
  onClear,
  showClear = false,
  defaultValue,
  disabled = false,
  showPlaceHolder,
  ...rest
}) => {
  const backgroundColor = darkMode ? AppColors.gray900 : AppColors.baseWhite
  const color = darkMode ? AppColors.g000 : AppColors.gray950
  // const getIcon: ReactNode = () => {
  //   // if (onClear) {
  //   //   return (
  //   //     <IconButton sx={{ padding: '0px' }} onClick={onClear}>
  //   //       <GridClearIcon />
  //   //     </IconButton>
  //   //   )
  //   // }

  //   return ExpandLess
  // }

  return (
    <FormControl
      variant='standard'
      sx={{
        border: `1px solid ${darkMode ? 'none' : AppColors.neutral600}`,
        borderRadius: '5px',
        backgroundColor: backgroundColor,
        width: width,
        padding: small ? '4px 12px' : '8px 10px',
        ...sx,
      }}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        placeholder={placeholder}
        className={!darkMode ? 'whiteBackground e-input' : 'e-input'}
        data-testid='SelectDropdown'
        disableUnderline
        defaultValue={defaultValue ?? placeholder}
        disabled={disabled}
        endAdornment={
          showClear &&
          !disabled && (
            <GridClearIcon
              sx={{ cursor: 'pointer', width: '20px' }}
              onClick={onClear}
            />
          )
        }
        // displayEmpty
        sx={{
          ...AppFonts.textMRegular,
          // height: '40px',
          paddingLeft: small ? '2px' : '10px',
          color,
          outlineColor: color,
          '& .MuiSvgIcon-root': {
            color: darkMode ? AppColors.gray25 : AppColors.neutral950,
          },
          '&:MuiSelect-select &:hover': darkMode
            ? { ...AppHovers.dark }
            : { ...AppHovers.primary },

          '&:focus': {
            backgroundColor: backgroundColor,
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              marginTop: '12px',
              // width: width,
              bgcolor: darkMode ? AppColors.gray700 : AppColors.baseWhite,
              color: darkMode ? AppColors.neutral200 : AppColors.gray25,
              AppFonts: AppFonts.interBase,
              fontWeight: 600,
              border: 'none',
            },
          },
        }}
        value={value}
        onChange={onChange}
        // IconComponent={GridClearIcon}
        IconComponent={disabled ? () => null : showClear ? Icon : ExpandLess}
        displayEmpty
        {...rest}
      >
        {showPlaceHolder && (
          <MenuItem value='' onClick={onClear}>
            {placeholder} (All)
          </MenuItem>
        )}
        {dropDownItems.map((item) => renderMenuItem(item))}
      </Select>
    </FormControl>
  )
}
