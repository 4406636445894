import React, { useContext, useEffect, useState } from 'react'
import useQuery from '../../../core/hooks/useQuery'
import { DEFAULT_LIMIT } from '../users/UsersViewModel'
import {
  deleteLevels,
  getDivisionStats,
  getLevelById,
  getSingleLevels,
} from '../../../domain/domain'
import {
  IDivisionStats,
  ILevel,
  ILevelList,
  LevelOptions,
} from '../../../domain/interfaces/IProjects'
import { showConfirmationDeleteMessage } from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import { Either, Failure } from '../../../core/core'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import { useParams } from 'react-router-dom'
import { ISearchResult } from '../../components/form/EdifySearch/EdifySearchDropdown'
import { useLocation } from 'react-router-dom'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'

export interface LevelsPageViewModelProps {
  title: string
  queryHelper: Record<string, any>
  levels: ILevel[]
  isLoading: boolean
  isLoadMore: boolean
  levelsError: string | undefined
  getLevels: () => void
  levelsCount: number
  showDeleteConfirmation: (ids: string[]) => void
  loadMore?: (paginationModel: GridPaginationModel) => void
  totalCount: number
  fetchLevels: (fetchSkip: number, query: Record<string, any>) => void
  sortClickedHandler: (sorter: GridSortModel) => void
  onLevelSearch: (result: ISearchResult) => void
  divisionStats: IDivisionStats | undefined
  divisionStatsLoading: boolean
  divisionStatsError: string | undefined
  getStats: () => void
}

const LevelsPageContext = React.createContext<LevelsPageViewModelProps | null>(
  null,
)

export function useLevelsPageViewModel(): LevelsPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(LevelsPageContext)!
}
interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const LevelsPageViewModel: React.FC<Props> = ({ children }) => {
  const location = useLocation()
  const {getHierarchyName} = useOrganizationProvider()
  const isLevelsPage = location.pathname.includes('/levels')
  const { id, userId, contactId } = useParams()
  // allows to do a subsearch of projects for given id in level show page
  const queryHelper = useQuery({
    ...(id ? { projectId: id } : {}),
    ...(userId ? { userId } : {}),
    ...(contactId ? { contactId } : {}),
    skip: 0,
    limit: DEFAULT_LIMIT,
  })

  const { query, getQueryStringFromQuery, setNew } = queryHelper
  //STATE
  const [levels, setLevels] = useState<ILevel[]>([])
  const [isLoading, setIsLoading] = useState({
    initialLoad: false,
    loadMore: false,
    search: false,
  })
  const [levelsError, setLevelsError] = useState<string | undefined>()
  const [divisionStats, setDivisionStats] = useState<
    IDivisionStats | undefined
  >()
  const [divisionStatsLoading, setDivisionStatsLoading] =
    useState<boolean>(false)
  const [divisionStatsError, setDivisionStatError] = useState<
    string | undefined
  >(undefined)
  const [totalCount, setTotalCount] = useState<number>(0)

  const getStats = async () => {
    setDivisionStatsLoading(true)
    setDivisionStatError(undefined)
    const res = await getDivisionStats()
    setDivisionStatsLoading(false)
    if (res.isLeft()) {
      setDivisionStatError(res?.left?.message ?? 'Error loading stats')
      return
    }
    setDivisionStats(res.right)
  }
  const getLevels = async () => {
    setLevelsError(undefined)
    setIsLoading((oldState) => {
      return { ...oldState, initialLoad: true }
    })
    setLevels([])

    fetchLevels(0, query)
  }

  const fetchLevels = async (fetchSkip: number, query: Record<string, any>) => {
    setIsLoading((oldState) => {
      return { ...oldState, loadMore: true }
    })
    setLevelsError(undefined)

    // load submissions or corrective actions
    const newQuery = { ...query, skip: fetchSkip }
    const res: Either<Failure, ILevelList> = await getSingleLevels(
      getQueryStringFromQuery(newQuery),
      LevelOptions.One,
    )
    setIsLoading((oldState) => {
      return { ...oldState, initialLoad: false, loadMore: false }
    })
    if (res.isLeft()) {
      setLevelsError(res.left?.message ?? 'Error occurred.')
      return
    }
    setTotalCount(res.right?.total_count || 0)
    setLevels(res.right?.data as ILevel[])
    setNew(newQuery)
  }

  const loadMore = (paginationModel: GridPaginationModel) => {
    const fetchSkip = paginationModel.page * paginationModel.pageSize
    fetchLevels(fetchSkip, query)
  }

  const deleteSelectedLevels = async (ids: string[]) => {
    const res = await deleteLevels(ids)

    const divisionString = ids.length == 1 ? getHierarchyName(1) : getHierarchyName(1, true)
    const divisionStringLower = divisionString.toLowerCase()
    if (res.isRight()) {
      SuccessToast({ title: `${divisionString} removed successfully.` })
      fetchLevels(query.skip, query)
    } else {
      ErrorToast({ title: `Error removing ${divisionStringLower}.` })
    }
  }

  const showDeleteConfirmation = async (ids: string[]) => {
    const divisionString = ids.length == 1 ? getHierarchyName(1) : getHierarchyName(1, true)
    const divisionStringLower = divisionString.toLowerCase()
    const confirmMessage = `${ids.length} ${divisionStringLower}`

    showConfirmationDeleteMessage(confirmMessage, () =>
      deleteSelectedLevels(ids),
    )
  }

  const sortClickedHandler = (sorter: GridSortModel) => {
    const { query } = queryHelper
    const { field, sort } = sorter[0]
    // reset skip to ) for the first pages
    const newQuery = {
      ...query,
      skip: 0,
      sortBy: field,
      sortOrder: sort?.toUpperCase(),
    }
    fetchLevels(0, newQuery)
  }

  // level search not functional
  // TODO CHECK THIS UX WITH ANDREW
  const onLevelSearch = async (levelSearchResult: ISearchResult) => {
    // check to see if it is already in the page
    const matchedLevel = levels.find((l) => l.id == levelSearchResult.id)
    if (matchedLevel) {
      setLevels([matchedLevel])
      setTotalCount(1)
      return
    }
    // if not grab from API
    if (!matchedLevel) {
      setIsLoading((oldState) => {
        return { ...oldState, loadMore: true }
      })
      setLevelsError(undefined)
      setIsLoading((oldState) => {
        return { ...oldState, initialLoad: false, loadMore: false }
      })
      const res = await getLevelById(levelSearchResult.id)
      if (res.right) {
        setLevels([res.right])
        setTotalCount(1)
      } else {
        ErrorToast({ title: 'Could not find level' })
      }
    }
  }

  useEffect(() => {
    getLevels()
    // some other pages use this and don't need stats
    if (isLevelsPage) {
      getStats()
    }
  }, [])

  return (
    <LevelsPageContext.Provider
      value={{
        title: 'Level Page Placeholder',
        queryHelper,
        levels,
        isLoading: isLoading.initialLoad,
        isLoadMore: isLoading.loadMore,
        levelsError,
        loadMore,
        getLevels,
        levelsCount: totalCount,
        showDeleteConfirmation,
        totalCount,
        fetchLevels,
        sortClickedHandler,
        onLevelSearch,
        divisionStats,
        divisionStatsError,
        divisionStatsLoading,
        getStats,
      }}
    >
      {children}
    </LevelsPageContext.Provider>
  )
}
export default LevelsPageContext
