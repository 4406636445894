// State we load on mount and
import React, { useContext, useEffect, useState } from 'react'
import { IForm } from '../domain/interfaces/IForm'
import {
  adminGetRoles,
  formsSearch,
  getAdminProjects,
  getCorrectiveActionById,
  getCurrentOrg,
  getCurrentOrgId,
  getCurrentPerson,
  getCurrentUser,
  getForms,
  getOrgHierarchyNames,
  getSingleLevels,
  getSubLocationHierarchyNames,
  setCurrentOrg,
} from '../domain/domain'
import { Either, Failure } from '../core/core'
import { IFormList } from '../domain/interfaces/IFormList'
import { IHierarchyName } from '../domain/interfaces/IHierarchyName'
import { IVerifyOTPPayload } from '../domain/interfaces/IUserDetails'
import { ILevel, IProject } from '../domain/interfaces/IProjects'
import { IMinimalIdName, IOrg } from '../domain/interfaces/IOrg'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import {
  EHomeFormLinks,
  searchGlobalMinimal,
} from '../data/repositories/utils/UtilsRepository'
import { getHomePageData } from '../domain/usecases/utils/UtilsUsecasses'
import { ETemplate, TEMPLATES } from '../domain/interfaces/IGoal'
import { toggleFavoriteForm } from '../data/data'
import pluralize from 'pluralize'
import { IRole } from '../domain/interfaces/IRole'
import { useGlobalProvider } from './GlobalProvider'
import {
  getTitles,
  getTrades,
  IListItem,
} from '../data/repositories/lists/listsRepository'
import { ROUTE_ORG_SELECTION } from '../presentation/pages/org_selection/OrgSelectionPage'

import { ISubLocationHierarchyName } from '../domain/interfaces/ILocation'
import { usePublicOrganizationProvider } from './PublicOrganizationProvider'
import { getDocumentLinks } from '../domain/usecases/documents/DocumentsUsecases'
import { IDocumentLink } from '../domain/interfaces/IDocument'

interface IFlags {
  isLocationEnabled: boolean
  isSubLocationEnabled: boolean
  isUserQrCodeEnabled?: boolean
  homepageDocumentLibWidgetEnabled?: boolean
  documentLibWebViewEnabled?: boolean
  isDocumentLibraryEnabled?: boolean
}

export interface OrganizationProviderProps {
  org: IOrg | undefined
  forms: IForm[]
  formsLoading: boolean
  formsError: string | undefined
  categoryForms: IForm[]
  categoryFormsLoading: boolean
  categoryFormsError: string | undefined
  getAllForms: () => void
  searchForms: () => void
  hierarchies: IHierarchyName[]
  hierarchiesLoading: boolean
  hierarchiesError: string | undefined
  orgProviderLoading: () => boolean
  orgProviderError: () => string | undefined
  reload: () => void
  reloadForms: () => void
  reloadHierarchies: () => void
  getFormById: (id: string) => IForm
  getHierarchyName: (level: 0 | 1, plural?: boolean) => string
  isContractor: boolean
  person: IVerifyOTPPayload | undefined
  allLevels: ILevel[] | IMinimalIdName[]
  allProjects: IProject[] | IMinimalIdName[]
  templates: ETemplate[]
  homeFormLinks: EHomeFormLinks | null
  setForms: (forms: IForm[]) => void
  setCategoryForms: (forms: IForm[]) => void
  formLinks: IForm[]
  toggleFormFavorite: (id: string, favorite: boolean) => void
  isDemoAccount: boolean
  userHasSingleOrg: boolean
  showDrawer: boolean
  setShowDrawer: (show: boolean) => void
  isUserInOrg: boolean
  roles: IRole[] | undefined
  rolesLoading: boolean
  rolesError: string | undefined
  formUpdated: (updatedForm: IForm, addedRemovedCategory?: boolean) => void
  findForm: (id: string | undefined) => IForm | undefined
  trades: string[] | IListItem[] | undefined
  titles: string[] | IListItem[] | undefined
  subLocationHierarchies: ISubLocationHierarchyName[] | undefined
  subLocationHierarchiesLoading: boolean
  subLocationHierarchiesError: string | undefined
  flags: IFlags
  setFlags: (flags: IFlags) => void
  formData: IMinimalIdName[]
  documentLinks: IDocumentLink[] | undefined
  documentLinksLoading: boolean
  documentLinksError: string | undefined
  profileTile: any
  resetProfileTileCallback: (name: string, imageURL: string) => void
}

const OrganizationContext =
  React.createContext<OrganizationProviderProps | null>(null)

export function useOrganizationProvider(): OrganizationProviderProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(OrganizationContext)!
}
interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const OrganizationProvider: React.FC<Props> = ({ children }) => {
  const { isGlobalUserView } = useGlobalProvider()
  const {
    hierarchies: h,
    allProjects: ap,
    allLevels: al,
    subLocationHierarchies: slh,
    setPublicHierarchies,
    hierarchiesLoading: hl,
    subLocationHierarchiesLoading: sll,
    hierarchiesError: he,
    subLocationHierarchiesError: slhe,
    reloadHierarchies,
    publicOrgProviderLoaded,
  } = usePublicOrganizationProvider()
  // if (publicOrgProviderLoading()) return null
  const navigate = useNavigate()
  const location = useLocation()

  const org = getCurrentOrg()
  const orgId = getCurrentOrgId()
  const params = useParams()
  const person = getCurrentPerson()
  const user = getCurrentUser()

  const getIsContractor = () => {
    //for testing
    if (process.env.NODE_ENV == 'test') {
      return false
    }
    // no org? normally org in user.
    if (!org) return true

    // if accessing as global user
    if (isGlobalUserView) return false
    // first see if they are a user in the org
    const isUserInOrg = person?.users.some((u: any) => u.orgId == org?.id)
    if (isUserInOrg) return false
    const isCont = person?.contractors
      // contractors coming back null should have BE fix
      .filter((c: any) => c !== null)
      .some((c: any) => c.orgId == org?.id)
    return isCont
  }

  // Contractor view id person has contractors and that id matches current orgid.
  const isContractor = getIsContractor()

  const totalOrgs = person?.users.length + person?.contractors.length
  const userHasSingleOrg = totalOrgs == 1 ? true : false
  const isUserInOrg = user?.roles[0] ? true : false

  const isDemoAccount = org?.type ? org?.type == 'Demo' : false

  // hierarchies STATE
  const [hierarchies, setHierarchies] = useState<IHierarchyName[]>([])
  const [hierarchiesLoading, setHierarchiesLoading] = useState<boolean>(false)
  const [hierarchiesError, setHierarchiesError] = useState<string | undefined>(
    undefined,
  )
  const [profileTile, setProfileTile] = useState<any>({
    name: person?.fullName ?? '',
    imageURL: person?.imageURL ?? '',
  })

  const resetProfileTileCallback = (name: string, imageURL: string) => {
    setProfileTile({
      name: name,
      imageURL: imageURL,
    })
  }

  const [subLocationHierarchies, setSubLocationHierarchies] = useState<
    ISubLocationHierarchyName[]
  >([])
  const [subLocationHierarchiesLoading, setSubLocationHierarchiesLoading] =
    useState<boolean>(false)
  const [subLocationHierarchiesError, setSubLocationHierarchiesError] =
    useState<string | undefined>(undefined)

  // FORMS STATE
  const [forms, setForms] = useState<IForm[]>([])
  const [formLinks, setFormLinks] = useState<IForm[]>([])
  const [formsLoading, setFormsLoading] = useState<boolean>(true)
  const [formsError, setFormsError] = useState<string | undefined>(undefined)
  const [categoryForms, setCategoryForms] = useState<IForm[]>([])
  const [categoryFormsLoading, setCategoryFormsLoading] =
    useState<boolean>(true)
  const [categoryFormsError, setCategoryFormsError] = useState<
    string | undefined
  >(undefined)

  const [formData, setFormData] = useState<IMinimalIdName[]>([])

  const [allLevels, setAllLevels] = useState<ILevel[]>([])
  const [allProjects, setAllProjects] = useState<IProject[]>([])

  const [templates, setTemplates] = useState<ETemplate[]>(TEMPLATES)
  const [homeFormLinks, setHomeFormLink] = useState<EHomeFormLinks | null>(null)

  const [showDrawer, setShowDrawer] = useState<boolean>(true)

  const [rolesError, setRolesError] = useState<string | undefined>(undefined)
  const [rolesLoading, setRolesLoading] = useState<boolean>(false)
  const [roles, setRoles] = useState<IRole[] | undefined>(undefined)
  const [trades, setTrades] = useState<string[] | IListItem[] | undefined>(
    undefined,
  )
  const [titles, setTitles] = useState<string[] | IListItem[] | undefined>(
    undefined,
  )

  const [documentLinks, setDocumentLinks] = useState<IDocumentLink[]>([])
  const [documentLinksLoading, setDocumentLinksLoading] =
    useState<boolean>(true)
  const [documentLinksError, setDocumentLinksError] = useState<
    string | undefined
  >(undefined)

  //Flags : TODO BE not allowing to update flags, hardcoding for now
  const [flags, setFlags] = useState<IFlags>({
    isLocationEnabled: org?.isLocationEnabled ? true : false,
    isSubLocationEnabled: org?.isSubLocationEnabled ? true : false,
    isUserQrCodeEnabled: org?.isUserQrCodeEnabled ? true : false,
    isDocumentLibraryEnabled: org?.isDocumentLibraryEnabled ? true : false,
    documentLibWebViewEnabled: org?.documentLibWebViewEnabled ? true : false,
    homepageDocumentLibWidgetEnabled: org?.homepageDocumentLibWidgetEnabled
      ? true
      : false,
  })

  const getFormById = (id: string, subId?: string) => {
    let form = forms.find((f) => f.id === id) as IForm
    if (!form) {
      form = categoryForms.find((f) => f.id === id) as IForm
    }
    return form
  }

  const formCategoryUpdated = (updatedForm: IForm) => {
    // category has been added remove from forms and add to categoryForms
    if (updatedForm.category) {
      const updatedForms = forms.filter((form) => form.id !== updatedForm.id)
      setForms(updatedForms)
      setCategoryForms([...categoryForms, updatedForm])
      return
    }
    // category has been removed remove from categoryForms and add to forms
    const updatedForms = categoryForms.filter(
      (form) => form.id !== updatedForm.id,
    )
    setCategoryForms(updatedForms)
    setForms([...forms, updatedForm])
    return
  }

  const formUpdated = (updatedForm: IForm, addRemovedCategory = false) => {
    // if there is a category, remove from forms and add to categoryForms
    if (addRemovedCategory) {
      formCategoryUpdated(updatedForm)
      return
    }
    if (updatedForm.category) {
      const updatedForms = categoryForms.map((form) =>
        form.id === updatedForm.id ? updatedForm : form,
      ) as IForm[]
      setCategoryForms(updatedForms)
      return
    }
    const updatedForms = forms.map((form) =>
      form.id === updatedForm.id ? updatedForm : form,
    ) as IForm[]
    setForms(updatedForms)
  }

  const toggleFormFavorite = async (id: string, favorite: boolean) => {
    toggleFavoriteForm(id, favorite)
  }

  const getAllForms = async () => {
    setFormsError(undefined)
    setFormsLoading(true)
    const res = await getForms(0, 1000)
    const minRes = await searchGlobalMinimal('users,contractors', '')
    setFormData(minRes.right?.data ?? [])
    setFormsLoading(false)
    if (res.isLeft()) {
      setFormsError(res.left?.message ?? 'Error getting forms')
      return
    }
    const sortForms: IForm[] = res.right?.data ?? []
    // load all forms for searching/sorting
    setForms(sortForms)
  }
  const getAllCategoryForms = async () => {
    setFormsError(undefined)
    setFormsLoading(true)
    const res = await getForms(0, 1000, true)
    setCategoryFormsLoading(false)
    if (res.isLeft()) {
      setCategoryFormsError(res.left?.message ?? 'Error getting forms')
      return
    }
    const sortForms: IForm[] = res.right?.data ?? []
    // load all forms for searching/sorting
    setCategoryForms(sortForms)
  }

  const getRoles = async () => {
    setRolesLoading(true)
    setRolesError(undefined)
    const res = await adminGetRoles()
    const tradeRes = await getTrades()
    const titlesRes = await getTitles()
    setRolesLoading(false)
    if (res.isLeft() || tradeRes.isLeft() || titlesRes.isLeft()) {
      setRolesError(res.left?.message ?? 'Error loading roles.')
      return
    }
    setRoles(res.right)
    setTrades(tradeRes.right)
    setTitles(titlesRes.right)
  }

  // NOT SURE IF THIS IS NEED ANYMORE....
  const searchForms = async (formName?: string) => {
    if (formName) {
      setFormsLoading(true)
      const res: Either<Failure, IFormList> = await formsSearch(formName ?? '')
      setFormsLoading(false)
      setForms([...res.right!.data!] as IForm[])
    } else {
      getAllForms()
    }
  }

  // hierarchies in reverse order for nav this method
  // makes that less confusing on other none nave pages
  const getHierarchyName = (level: 0 | 1, plural = false) => {
    if (h.length == 0) return ''
    let name = ''
    if (level == 0) {
      name = h[1].name
    }
    if (level == 1) {
      name = h[0].name
    }
    return plural ? pluralize(name) : name
  }

  // maybe we should not do api call to get templates if
  // they are just hardcoded
  const getTemplates = async () => {
    // try {
    //   const res = await axios.get('goalTemplates')
    //   setTemplates(res.data.data as ETemplate[])
    // } catch (err) {
    //   setTemplates(null)
    // }
  }

  const loadHomePageData = async () => {
    try {
      const res = await getHomePageData()
      if (res.right) {
        setHomeFormLink(res.right)
      }
    } catch (err) {
      setHomeFormLink(null)
    }
  }

  // HIERARCHIES LOGIC
  const getHierarchies = async () => {
    setHierarchiesError(undefined)
    setHierarchiesLoading(true)
    // TODO: still have not decide on if there load more option on forms
    const res = await getOrgHierarchyNames()
    // TEMP FIX FORMIO: Load all projects and levels to find ids for form io, it is breaking many test
    if (process.env.NODE_ENV !== 'test') {
      const projectsRes = await getAdminProjects('')
      // using an empty sting will grab a level1's
      const levelsRes = await getSingleLevels('')
      if (projectsRes.right && levelsRes.right) {
        setAllProjects(projectsRes.right.data)
        setAllLevels(levelsRes.right.data)
      }
    }

    setHierarchiesLoading(false)
    if (res.isLeft() || res.right?.data === undefined) {
      setHierarchiesError(res.left?.message ?? 'Error getting hierarchies.')
      return
    }
    if (Array.isArray(res.right.data)) {
      const level1: any = res.right.data.find((l) => l.level == 1) ?? {
        name: 'Level 1',
        level: 1,
      }
      const level0: any = res.right.data.find((l) => l.level == 0) ?? {
        name: 'Level 0',
        level: 0,
      }

      setHierarchies([level1, level0])
      setPublicHierarchies([level1, level0])
      return
    }
    setHierarchies([])
    setPublicHierarchies([])
  }

  const getSubLocationsHierarchies = async () => {
    setSubLocationHierarchiesError(undefined)
    setSubLocationHierarchiesLoading(true)

    const res = await getSubLocationHierarchyNames()
    setSubLocationHierarchiesLoading(false)
    if (res.isRight() && res.right?.data) {
      setSubLocationHierarchies(
        res.right.data.sort((a, b) => a.level! - b.level!),
      )
      return
    }

    setSubLocationHierarchiesError(
      res.left?.message ?? 'Error loading sub location hieraarchy names',
    )
  }

  // Add any other loading state here
  const orgProviderLoading = () => {
    // return hierarchiesLoading || formsLoading
    return hl || formsLoading
  }

  // Add any other loading state here
  const orgProviderError = () => {
    return he || formsError
    // return hierarchiesError || formsError
  }

  // check for a email link
  // if so load org as they won't have it.
  const checkOrgID = async () => {
    if (!params.id) return
    const res = await getCorrectiveActionById(params.id as string)
    await setCurrentOrg(res.right?.orgId ?? '')
  }

  const getOrgDocumentLinks = async () => {
    setDocumentLinksLoading(true)
    setDocumentLinksError(undefined)
    const res = await getDocumentLinks()
    setDocumentLinksLoading(false)
    if (res.isLeft()) {
      setDocumentLinksError(res.left?.message)
    }
    setDocumentLinks(res.right ?? [])
  }

  const load = async () => {
    if (
      process.env.NODE_ENV != 'test' &&
      location.pathname.includes('/correctiveActions')
    ) {
      await checkOrgID()
    }
    loadHomePageData()
    getAllForms()
    getAllCategoryForms()
    getRoles()
    getOrgDocumentLinks()
    // if not provided by public org provider
    if (h.length == 0) {
      getHierarchies()
    }
    if (slh?.length == 0) {
      getSubLocationsHierarchies()
    }
  }
  const findForm = (formId: string | undefined): IForm | undefined => {
    if (!formId) return undefined
    let form = forms.find((form) => form.id == formId)
    if (form) return form
    form = categoryForms.find((form) => form.id == formId)
    return form
  }

  useEffect(() => {
    if (!orgId && process.env.NODE_ENV != 'test') {
      navigate(ROUTE_ORG_SELECTION)
    }
    // don't need to check/reload org if contractor
    if (isContractor) {
      setHierarchiesLoading(false)
      setFormsLoading(false)
    } else {
      if (!publicOrgProviderLoaded) return
      load()
    }
  }, [publicOrgProviderLoaded])
  return (
    <OrganizationContext.Provider
      value={{
        formUpdated,
        findForm,
        homeFormLinks,
        org,
        forms,
        formsLoading,
        formsError,
        getAllForms,
        searchForms,
        orgProviderLoading,
        orgProviderError,
        reload: load,
        reloadForms: getAllForms,
        // reloadHierarchies: getHierarchies,
        reloadHierarchies: reloadHierarchies,
        getFormById,
        getHierarchyName,
        isContractor,
        person,
        allProjects: ap,
        allLevels: al,
        templates: TEMPLATES,
        setForms,
        toggleFormFavorite,
        formLinks,
        isDemoAccount,
        userHasSingleOrg,
        showDrawer,
        setShowDrawer,
        isUserInOrg,
        roles,
        rolesLoading,
        rolesError,
        categoryForms,
        categoryFormsLoading,
        categoryFormsError,
        setCategoryForms,
        trades,
        titles,
        hierarchies: h.length == 0 ? hierarchies : h,
        hierarchiesLoading: hl || hierarchiesLoading,
        hierarchiesError: he || hierarchiesError,
        subLocationHierarchies: slh?.length == 0 ? subLocationHierarchies : slh,
        subLocationHierarchiesLoading: sll || subLocationHierarchiesLoading,
        subLocationHierarchiesError: slhe || subLocationHierarchiesError,
        flags,
        setFlags,
        formData,
        documentLinks,
        documentLinksError,
        documentLinksLoading,
        resetProfileTileCallback,
        profileTile,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}
export default OrganizationProvider
