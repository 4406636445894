import React from 'react'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { ColorNames, FontNames, TextMappings, ColorMappings } from '../../Theme'
import { SxProps } from '@mui/material'

export interface ETypographyProps extends TypographyProps {
  font: FontNames
  color: ColorNames
  children: React.ReactNode
  sx?: SxProps
}

/**
 * A custom typography component with font and color settings. Forcing the use of our theme.
 */
export const ETypography: React.FC<ETypographyProps> = ({
  children,
  font,
  color,
  sx,
  ...rest
}) => {
  const fontStyle = TextMappings[font]
  const fontColor = ColorMappings[color]
  return (
    <Typography
      sx={{ ...fontStyle, color: fontColor, ...sx }}
      component='p'
      {...rest}
    >
      {children}
    </Typography>
  )
}

// Create sub components for different typography components.
// where the font and color are already set.
export interface ESubTypographyProps {
  font?: FontNames
  color?: ColorNames
  children: React.ReactNode
  sx?: SxProps
}

/**
 * A custom paragraph component with default font and color settings.
 * this would be the main p component but the styles can be overwritten.
 */

export const EParagraph: React.FC<ESubTypographyProps> = ({
  children,
  font = 'XLR',
  color = 'gray950',
  sx,
}) => {
  return (
    <ETypography font={font} color={color} sx={{ ...sx }}>
      {children}
    </ETypography>
  )
}

// More Commonly used components can be added here.
// Would also create a separate file for Header.s
