import React, { useState } from 'react'
import { CorrectiveActionsPageViewModelProps } from './CorrectiveActionsViewModel'
import { EdifyButton } from '../../components/buttons'
import { Box } from '@mui/material'

import { EdifySelect } from '../../components/form'
import {
  contractorsSearch,
  formsSearch,
  searchLevels,
  searchProjects,
} from '../../../domain/domain'
import EdifyDateRange from '../../components/form/EdifyDateRange/EdifyDateRange'
import { ECorrectiveActionStatus } from '../../../domain/interfaces/ICorrectiveAction'
import EdifySearchDropdown, {
  ISearchResult,
} from '../../components/form/EdifySearch/EdifySearchDropdown'
import EdifyMenuItem from '../../components/form/EdifyMenuItem'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { searchForms } from '../../../data/data'
import { globalSearch } from '../../../domain/usecases/utils/UtilsUsecasses'

interface ICorrectiveActionPageFilterProps {
  vm: CorrectiveActionsPageViewModelProps
}

// TODO match with ENUM state, need to clear with BE folk
const caStatusOptions = [
  { value: 'all', name: 'All Status' },
  { value: 'resolved', name: 'Closed' },
  { value: 'unresolved', name: 'In Progress' },
  { value: 'under_review', name: 'Under Review' },
]

const caTradeOptions = [
  { value: 'all', name: 'All Trades' },
  { value: 'Plumbing', name: 'Plumbing' },
  { value: 'Laborer', name: 'Laborer' },
  { value: 'Electrical', name: 'Electrical' },
  { value: 'Ironworker', name: 'Ironworker' },
  { value: 'Carpenter / Framing', name: 'Carpenter / Framing' },
  { value: 'Mason', name: 'Mason' },
  { value: 'Heavy Equipment Operator', name: 'Heavy Equipment Operator' },
  { value: 'Exterior', name: 'Exterior' },
  { value: 'Housekeeping', name: 'Housekeeping' },
  { value: 'Architecture', name: 'Architecture' },
]
const caPriorityOptions = [
  { value: 'all', name: 'All Priorities' },
  { value: 'immediately', name: 'Critical' },
  { value: 'tonight', name: 'Low' },
]

const CorrectiveActionFilter: React.FC<ICorrectiveActionPageFilterProps> = ({
  vm,
}) => {
  const { query } = vm.queryHelper
  const { status, dateRange, trade, priority } = query
  const { isContractor, getHierarchyName } = useOrganizationProvider()
  const [showFilters, setShowFilter] = useState(false)

  // EVENT HANDLER FOR PROJECT SEARCH picked
  const levelChanged = (searchItems: ISearchResult[]) => {
    // cleared search
    if (searchItems.length == 0) {
      const newQuery = { ...query }
      delete newQuery.level
      vm.fetchCorrectiveActions(0, newQuery)
      return
    }
    vm.fetchCorrectiveActions(0, { ...query, level: searchItems[0].id })
  }

  // EVENT HANDLER FOR PROJECT SEARCH picked
  const projectChanged = (searchItems: ISearchResult[]) => {
    // cleared search
    if (searchItems.length == 0) {
      const newQuery = { ...query }
      delete newQuery.project
      vm.fetchCorrectiveActions(0, newQuery)
      return
    }
    vm.fetchCorrectiveActions(0, { ...query, project: searchItems[0].id })
  }

  const contractorChanged = (searchItems: ISearchResult[]) => {
    // cleared search
    if (searchItems.length == 0) {
      const newQuery = { ...query }
      // check key
      delete newQuery.contractor
      vm.fetchCorrectiveActions(0, newQuery)
      return
    }
    vm.fetchCorrectiveActions(0, { ...query, contractor: searchItems[0].id })
  }

  const userChanged = (searchItems: ISearchResult[]) => {
    // cleared search
    if (searchItems.length == 0) {
      const newQuery = { ...query }
      // check key
      delete newQuery.user
      vm.fetchCorrectiveActions(0, newQuery)
      return
    }
    vm.fetchCorrectiveActions(0, { ...query, user: searchItems[0].id })
  }

  const formChanged = (searchItems: ISearchResult[]) => {
    // cleared search
    if (searchItems.length == 0) {
      const newQuery = { ...query }
      delete newQuery.form
      vm.fetchCorrectiveActions(0, newQuery)
      return
    }
    vm.fetchCorrectiveActions(0, { ...query, form: searchItems[0].id })
  }

  // EVENT HANDLER FOR STATUS SEARCH
  const statusChanged = (e: any) => {
    vm.fetchCorrectiveActions(0, {
      ...query,
      status: e.target.value as ECorrectiveActionStatus,
    })
  }

  const tradeChanged = (e: any) => {
    vm.fetchCorrectiveActions(0, {
      ...query,
      trade: e.target.value as ECorrectiveActionStatus,
    })
  }
  const priorityChanged = (e: any) => {
    vm.fetchCorrectiveActions(0, {
      ...query,
      priority: e.target.value as ECorrectiveActionStatus,
    })
  }

  // EVENT HANDLER FOR DATE RANGE
  const dateChanged = (dateRange: string) => {
    vm.fetchCorrectiveActions(0, {
      ...query,
      dateRange,
    })
  }
  const handleGlobalClear = async () => {
    setShowFilter(false)
    await vm.clearFilters(true)  
    setShowFilter(true)
  }

  return (
    <>
      <Box
        data-testid={'CorrectiveActionsPage'}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '24px',
          alignItems: 'center',
        }}
      >
        <Box style={{ display: 'flex' }} sx={{ marginRight: '13px' }}>
          {/* {!isContractor && (
            <EdifySearchDropdown
              placeholder='ID (Coming Soon)'
              sx={{ marginRight: '16px' }}
              searchFunction={globalSearch}
              nameKey='id'
              globalSearchKey='corrective_actions'
              globalSearchPayload={{
                entities: ['corrective_actions'],
                properties: ['id'],
                returns: ['id'],
              }}
              onSelect={projectChanged}
              width={190}
            />
          )} */}
          <EdifyDateRange
            width={195}
            dateRange={dateRange || ''}
            onChange={dateChanged}
          />
        </Box>
        <EdifyButton
          onClick={() => setShowFilter(!showFilters)}
          title={showFilters ? 'Hide Filters' : 'More Filters'}
          primary
          noBackground
        />
        <EdifyButton
          // onClick={() => vm.clearFilters(true)}
          onClick={handleGlobalClear}
          title='Clear Filters'
          primary
          noBackground
        />
      </Box>
      {showFilters && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            // margin: '24px 0',
            marginBottom: '24px',
            flexDirection: 'column',
            // gap: '20px',
            alignItems: 'flex-start',
          }}
        >
          <>
            <Box style={{ display: 'flex', gap: '16px' }}>
              <EdifySearchDropdown
                placeholder='Forms'
                // sx={{ marginRight: '16px' }}
                searchFunction={formsSearch}
                onSelect={formChanged}
                width={193}
                // width={'100%'}
              />

              <EdifySearchDropdown
                placeholder={getHierarchyName(1)}
                sx={{ flex: 1 }}
                searchFunction={searchLevels}
                onSelect={levelChanged}
                width={193}
                // width={'100%'}
              />
              <EdifySearchDropdown
                placeholder={getHierarchyName(0)}
                sx={{ flex: 1 }}
                searchFunction={searchProjects}
                onSelect={projectChanged}
                width={193}
                // width={'100%'}
              />

              <EdifySearchDropdown
                data-testid='Contractor'
                placeholder='Trade'
                searchFunction={contractorsSearch}
                nameKey='name'
                width={195}
                limit={18}
                onSelect={contractorChanged}
                sx={{ marginBottom: '24px' }}
              />
              {/* <EdifySearchDropdown
                data-testid='PointOfContact'
                disabled
                placeholder='Manager(Coming Soon)'
                searchFunction={usersSearch}
                nameKey='fullName'
                width={228}
                limit={18}
                onSelect={contractorChanged}
                sx={{ marginBottom: '24px' }}
              /> */}
            </Box>
            <Box style={{ display: 'flex' }}>
              <EdifySelect
                width={195}
                sx={{ marginRight: '16px' }}
                dropDownItems={caPriorityOptions}
                onChange={priorityChanged}
                renderMenuItem={(item) => {
                  return (
                    <EdifyMenuItem key={item.value} value={item.value}>
                      {item.name}
                    </EdifyMenuItem>
                  )
                }}
                value={priority || 'all'}
              />
              {/* <EdifySelect
                width={190}
                sx={{ marginRight: '16px' }}
                dropDownItems={caTradeOptions}
                onChange={tradeChanged}
                renderMenuItem={(item) => {
                  return (
                    <EdifyMenuItem key={item.value} value={item.value}>
                      {item.name}
                    </EdifyMenuItem>
                  )
                }}
                value={trade || 'all'}
              /> */}
              <EdifySelect
                width={190}
                // sx={{ marginRight: '16px' }}
                dropDownItems={caStatusOptions}
                onChange={statusChanged}
                renderMenuItem={(item) => {
                  return (
                    <EdifyMenuItem key={item.value} value={item.value}>
                      {item.name}
                    </EdifyMenuItem>
                  )
                }}
                value={status || 'all'}
              />
            </Box>
          </>
        </Box>
      )}
    </>
  )
}

export default CorrectiveActionFilter
