import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/page/PageContainer'
import {
  SubmissionPageViewModel,
  SubmissionPageViewModelProps,
  useSubmissionPageViewModel,
} from './SubmissionViewModel'
import { Button, Typography } from '@mui/material'

import { Outlet, useLocation, useParams } from 'react-router-dom'

import { updateFormComponentsWithOrgFields } from '../../components/panels/form-renderer/CustomFormioComponents'
import { AppColors, AppFonts } from '../../Theme'
import { formatTimestampMDYYYYathmmaz, getTimeZone } from '../../../core/core'
import ErrorContainer from '../../components/error/ErrorContainer'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { ROUTE_FORMS } from '../forms/FormsPage'
import { ROUTE_SUBMISSIONS } from '../submissions/SubmissionsPage'
import SubmissionModal from './SubmissionModal'

export const Submission: React.FC = () => {
  return (
    <SubmissionPageViewModel>
      <_Page />
    </SubmissionPageViewModel>
  )
}

export const _Page: React.FC = () => {
  const vm: SubmissionPageViewModelProps = useSubmissionPageViewModel()
  const { hierarchies } = useOrganizationProvider()
  const {formCategory} = useParams()
  const location = useLocation()
  const { pathname } = location

  // const [isFinishedLoadingNames, setIsFinishedLoadingNames] =
  //   useState<boolean>(true)

  const getOrgSpecificElements = async () => {
    if (hierarchies == undefined) {
      //do not change the form if we cannot get the hierarchy names. will use what is already in the form
      return
    }
    //find level 1 in hierarchy names
    // const level1FieldName = hierarchyNamesData.find(
    const level1FieldName = hierarchies.find(
      (element: { level: number; name: string }) => element.level == 1,
    )
    //find level 0 / project in hierarchy names
    const projectFieldName = hierarchies.find(
      (element: { level: number; name: string }) => element.level == 0,
    )

    updateFormComponentsWithOrgFields(
      vm.form,
      (level1FieldName && level1FieldName.name) || 'Level 1',
      (projectFieldName && projectFieldName.name) || 'Project',
    )
    // setIsFinishedLoadingNames(true)
    vm.setIsDataChanged(false)
  }

  useEffect(() => {
    if (vm.form) {
      getOrgSpecificElements()
    }
  }, [vm.form])

  
  const renderRightItems = () => {
    if (vm.error || vm.submissionError) return
    if (!pathname.includes('/new') && !pathname.includes('/edit')) {
      const timezone = getTimeZone()

      return (
        <Typography
          sx={{
            ...AppFonts.headlineXSDesktopSemibold,
            color: AppColors.gray25,
          }}
        >
          {`Submitted at ${formatTimestampMDYYYYathmmaz(
            vm.submission?.createdAt,
          )} ${timezone}`}
        </Typography>
      )
    }
  }
  const renderBreadCrumbs = () => {
    let title = 'View'
    let path = `/submission/${vm.submission?.id}`
    const formTitle = vm.form?.title
    if (pathname.includes('/new')) {
      title = 'New'
      path = '/submission/new'
    }
    if (pathname.includes('/edit')){
      title = 'Update'
      path += '/edit'
    }
    
    const breadCrumbs = [
      { title: 'All Forms', to: `${ROUTE_FORMS}/all` },
      { title: formTitle, to: `/${formCategory}${ROUTE_SUBMISSIONS}/${vm.form?.id}` },
      { title: title, to: `/${formCategory}${ROUTE_SUBMISSIONS}/${vm.form?.id}${path}` },
    ]

    return breadCrumbs
  }

  const getPageTitle = () => {
    if (vm.error) return 'Error Occurred Loading Form'
    const title = vm.form?.title
    if (pathname.includes('/new')) return `New ${title}`
    if (pathname.includes('/edit')) return `Update ${title}`
    return title
  }

  return (
    <PageContainer
      title={getPageTitle()}
      renderRightItems={renderRightItems}
      breadCrumbs={renderBreadCrumbs()}
      mainPageError={vm.error || vm.submissionError}
      mainPageLoading={vm.loading}
    >
      {vm.formSubmissionError && (
        <ErrorContainer sx={{ marginBottom: '24px' }}>
          {vm.formSubmissionError}
        </ErrorContainer>
      )}
      <Outlet />
    </PageContainer>
  )
}
