import React, { useContext, useEffect, useState } from 'react'

import { IUser } from '../../../domain/interfaces/IUser'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTE_USERS } from '../users/UsersPage'
import {
  hideConfirmationDialog,
  showConfirmationDialog,
} from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import {
  adminCreateUser,
  adminDeleteUsers,
  adminEditUser,
  adminGetRoles,
  getCurrentUser,
  getSpecificUser,
} from '../../../domain/domain'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import { IRole } from '../../../domain/interfaces/IRole'
import { Either, Failure } from '../../../core/core'
import { logout } from '../../../data/data'

export interface userParams {
  id?: string
  fullName?: string
  email?: string
  role: string
  selectedProjects: string[]
  selectedLevels: string[]
  phone?: string | undefined
  pointOfContactId: string | undefined | null
}

export interface UserPageViewModelProps {
  getUser: () => void
  deleteUser: () => void
  updateUser: (user: userParams) => void
  createUser: (user: userParams) => void
  user: IUser | undefined
  userLoading: boolean
  userError: string | undefined
  formError: string | undefined
  roles: IRole[] | undefined
}

const UserPageContext = React.createContext<UserPageViewModelProps | null>(null)

export function useUserPageViewModel(): UserPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(UserPageContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const UserPageViewModel: React.FC<Props> = ({ children }) => {
  const { userId } = useParams()
  const navigate = useNavigate()
  const [user, setUser] = useState<IUser | undefined>(undefined)
  const [userLoading, setUserLoading] = useState<boolean>(false)
  const [userError, setUserError] = useState<string | undefined>(undefined)
  const [formError, setFormError] = useState<string | undefined>(undefined)
  const [roles, setRoles] = useState<IRole[] | undefined>(undefined)
  const [rolesLoading, setRolesLoading] = useState<boolean>(false)
  const [rolesError, setRolesError] = useState<string | undefined>(undefined)

  const getUser = async () => {
    setUserLoading(true)
    setUserError(undefined)
    const res = await getSpecificUser(userId as string)
    setUserLoading(false)
    if (res.isLeft()) {
      setUserError(res.left?.message ?? 'Error loading user.')
      return
    }
    setUser(res.right as IUser)
  }

  //CREATE USER
  const createUser = async ({
    fullName,
    email,
    role,
    selectedProjects,
    selectedLevels,
    phone,
    pointOfContactId
  }: userParams) => {
    setFormError(undefined)

    const result = await adminCreateUser(
      fullName ??'',
      email ?? '',
      [role],
      (selectedProjects = selectedProjects ? selectedProjects : []),
      (selectedLevels = selectedLevels ? selectedLevels : []),
      phone === '' ? undefined : phone,
      pointOfContactId
    )

    if (result.isLeft()) {
      setFormError(result.left?.message)
    } else {
      SuccessToast({ title: `User: ${fullName} Created and Invitation Sent` })
      navigate(ROUTE_USERS)
    }
  }

  //Update USER, maybe combine with create user
  const updateUser = async ({
    id,
    fullName,
    email,
    role,
    selectedProjects,
    selectedLevels,
    phone,
    pointOfContactId 
  }: userParams) => {
    setFormError(undefined)
    const result = await adminEditUser(
      id!,
      fullName ?? '',
      email ?? '',
      [role],
      selectedProjects,
      selectedLevels,
      phone == '' ? undefined : phone,
      pointOfContactId
    )

    if (result.isLeft()) {
      setFormError(result.left?.message)
    } else {
      SuccessToast({ title:  'User Updated' })
      // if(userId == getCurrentUser()?.id){
      //   window.location.reload()
      // }
    }
  }

  const deleteUser = async () => {
    const currentUser = getCurrentUser()
    showConfirmationDialog({
      title: 'Confirm Delete',
      message:
        'Are you sure you want to delete this user from the database? This action cannot be undone.',
      cancelText: 'Cancel',
      confirmText: 'Delete',
      onCancel: function (): void {
        hideConfirmationDialog()
      },
      onConfirm: async () => {
        const results = await adminDeleteUsers([userId!])
        if (results.isLeft()) {
          ErrorToast({
            title: 'Error removing user.',
          })
          return
        }

        SuccessToast({
          title: 'User has been deleted successfully.',
        })
        // user deletes self
        if (userId == currentUser?.id) {
          logout()
          navigate('/')
          return
        }
        navigate(`${ROUTE_USERS}`)
        hideConfirmationDialog()
      },
    })
  }

  const getRoles = async () => {
    setRolesLoading(true)
    const res: Either<Failure, IRole[]> = await adminGetRoles()
    if (res.isRight()) {
      setRoles(res.right as IRole[])
    } else {
      setRolesError('Error getting roles')
    }
    setRolesLoading(false)
  }
  useEffect(() => {
    getRoles()
    if (userId) {
      getUser()
    }
  }, [userId])

  return (
    <UserPageContext.Provider
      value={{
        getUser,
        user,
        userLoading,
        userError,
        deleteUser,
        formError,
        createUser,
        updateUser,
        roles,
      }}
    >
      {children}
    </UserPageContext.Provider>
  )
}
