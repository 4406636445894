import React from 'react'

import { FormsPageViewModelProps } from './FormsPageViewModel'
import { formsSearch } from '../../../domain/domain'
import EdifySearchDropdown, {
  ISearchResult,
} from '../../components/form/EdifySearch/EdifySearchDropdown'
import FilterContainer from '../../components/page/FilterContainer'
import { Box } from '@mui/material'
// import { EdifyButton } from '../../components/buttons'
// import FilterButtonGroup from '../../components/buttons/filter-button-group/FilterButtonGroup'

interface IFormsFilterProps {
  vm: FormsPageViewModelProps
  buttonTitle?: string
  canCreate?: boolean
}

// const FILTER_OPTIONS = ['All', 'Fav', 'Complete', 'Canceled']

const FormsFilter: React.FC<IFormsFilterProps> = ({
  vm,
  buttonTitle,
  canCreate,
}) => {
  const { query } = vm.queryHelper
  
  // TODOS: setup down the road.
  // const navigate = useNavigate()
  // const [activeButton, setActiveButton] = useState<string>('All')

  // const handleFilterButtonClicked = (button: string) => {
  //   const newFormsQueryField = { ...query, state: button.toLocaleLowerCase() }
  //   vm.fetchForms(0, newFormsQueryField)
  // }

  const handleSearchSelected = (searchItems: ISearchResult[]) => {
    if (searchItems.length == 0) {
      vm.fetchForms(query.skip, query)
      return
    }
    vm.onFormSearch(searchItems[0])
  }

  return (
    <>
            
      <FilterContainer data-testid={'FormsFilter'} sx={{marginTop:'0px'}}>
        {/* <FilterButtonGroup
          onButtonClicked={handleFilterButtonClicked}
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          buttons={[...FILTER_OPTIONS]}
        /> */}
        {/* Place holder for future options */}
        <Box></Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <EdifySearchDropdown
            placeholder='Search Title'
            searchFunction={(searchTerm: string) => formsSearch(searchTerm)}
            nameKey='title'
       

            onSelect={handleSearchSelected}
            width={200}
          />
        </Box>
      </FilterContainer>
    </>
  )
}

export default FormsFilter
