import { Either, Failure, Left } from '../../../core/core'
import {

  anonGetOneForm,
  anonGetOneSubmission,
  anonSubmissionCreate,
  createFormioForm,
  deleteFormHeader,
  deleteSubmissionById,
  formFiltersGet,
  formsGetFromQuery,
  getAllForms,
  getAllFormsByLoginAccountId,
  getAllSubmissions,
  getAllSubmissionsByLoginAccountId,
  getAllSubmissionsWithQuery,
  getAllSubmissionsWithQueryObject,
  getOneForm,
  getPDFZipFile,
  getSubmissionById,
  getSubmissionPDFLink,
  postFormFilter,
  postFormHeader,
  putAdminFormUpdate,
  putSubmission,
  searchForms,
  searchSubmissions,
  sendForm,
  submissionCreate,
} from '../../../data/data'
import { getCurrentUser } from '../../domain'

import { IForm } from '../../interfaces/IForm'
import { ICreateForm, ICreateFormHeader, IFormFilter, IFormHeader } from '../../interfaces/IFormHeader'
import { IFormList } from '../../interfaces/IFormList'
import {
  ICreateSubmission,
  ISubmission,
  ISubmissionList,
  ISubmissionPDFLink,
} from '../../interfaces/ISubmission'

export async function getForms(
  skip: number,
  limit?: number,
  hasCategory = false
): Promise<Either<Failure, IFormList>> {

  return await getAllForms(skip, limit, hasCategory)
}

export async function getFormsFromQuery(
  query: string
): Promise<Either<Failure, IFormList>> {
  return await formsGetFromQuery(query)
}

/**
 * @deprecated Use {@link getForms}.
 */
export async function getFormsByLoginAccountId(
  skip: number,
  limit?: number,
): Promise<Either<Failure, IFormList>> {
  const user = getCurrentUser()

  if (!user) {
    return new Left(new Failure('No logged-in user'))
  }

  return await getAllFormsByLoginAccountId(user.id, skip, limit)
}

export async function formsSearch(
  formName: string,
): Promise<Either<Failure, IFormList>> {
  return await searchForms(formName)
}

export async function getSubmissions(
  formId: string,
  skip: number,
  limit?: number,
): Promise<Either<Failure, ISubmissionList>> {
  return await getAllSubmissions(formId, skip, limit)
}

export interface SubmissionQueryField {
  limit?: number,
  drafts?: boolean,
  owned?: boolean,
  status?: string,
  project?: string
  dateRange?: string

}
export async function getSubmissionsWithQuery(
  formId: string,
  SubmissionQueryField: string
): Promise<Either<Failure, ISubmissionList>> {
  return await getAllSubmissionsWithQuery(formId, SubmissionQueryField)
}

export async function getSubmissionsWithQueryObject(
  formId: string,
  SubmissionQueryObject: any
): Promise<Either<Failure, ISubmissionList>> {
  return await getAllSubmissionsWithQueryObject(formId, SubmissionQueryObject)
}

/**
 * @deprecated Use {@link getSubmissions}.
 */
export async function getSubmissionsByLoginAccountId(
  formId: string,
  skip: number,
  limit?: number,
): Promise<Either<Failure, ISubmissionList>> {
  const user = getCurrentUser()

  if (!user) {
    return new Left(new Failure('No logged-in user'))
  }

  return await getAllSubmissionsByLoginAccountId(formId, user.id, skip, limit)
}

export async function submissionsSearch(
  submission: string,
  skip: number,
  limit?: number,
): Promise<Either<Failure, ISubmissionList>> {
  return await searchSubmissions(submission, skip, limit)
}

export async function createSubmission(
  data: ICreateSubmission,
): Promise<Either<Failure, ISubmission>> {
  return await submissionCreate(data)
}

export async function createAnonSubmission(
  data: ICreateSubmission,
  orgId: string
): Promise<Either<Failure, ISubmission>> {
  return await anonSubmissionCreate(data, orgId)
}

export async function updateSubmission(
  submissionId: string,
  data: ICreateSubmission,
): Promise<Either<Failure, ISubmission>> {
  const payload = { data: data.data, correctiveActions: data.correctiveActions, state: data.state }
  return await putSubmission(submissionId, data)
}

export async function getSubmittedFormById(
  submissionId: string,
): Promise<Either<Failure, ISubmission>> {
  return await getSubmissionById(submissionId)
}

export async function deleteSubmittedFormById(
  submissionId: string,
): Promise<Either<Failure, ISubmission>> {
  return await deleteSubmissionById(submissionId)
}

export async function formSendByEmail(
  personEmail?: string,
  formId?: string,
): Promise<Either<Failure, boolean>> {
  const user = getCurrentUser()

  if (!user) {
    return new Left(new Failure('No logged-in user'))
  }
  return await sendForm({ personEmail }, user.id, formId)
}

export async function formSendByPhone(
  personPhone?: string,
  formId?: string,
): Promise<Either<Failure, boolean>> {
  const user = getCurrentUser()

  if (!user) {
    return new Left(new Failure('No logged-in user'))
  }
  return await sendForm({ personPhone }, user.id, formId)
}

export async function generateSubmissionPDFLink(
  submissionId: string,
): Promise<Either<Failure, ISubmissionPDFLink>> {
  return await getSubmissionPDFLink(submissionId)
}

export async function getForm(
  id: string
): Promise<Either<Failure, IForm>> {
  return await getOneForm(id)
}

export async function anonGetForm(
  id: string,
  orgId: string
): Promise<Either<Failure, IForm>> {
  return await anonGetOneForm(id, orgId)
}

export async function generatePDFZipFile(
  ids: string,
): Promise<Either<Failure, Blob>> {
  return await getPDFZipFile(ids)
}

export async function AdminFormUpdate(
  formId: string,
  data: Record<string, any>,
): Promise<Either<Failure, IForm>> {
  return await putAdminFormUpdate(formId, data)
}

export async function AdminCreateFormHeader(
  data: ICreateFormHeader
): Promise<Either<Failure, IFormHeader>> {
  return await postFormHeader(data)
}

export async function AdminCreateFormFilter(
  data: IFormFilter
): Promise<Either<Failure, IFormFilter>> {
  return await postFormFilter(data)
}
export async function getFormFilters(
  formId: string,
): Promise<Either<Failure, IFormFilter[]>> {
  return await formFiltersGet(formId)
}

export async function AdminCreateFormioForm(
  data: ICreateForm
): Promise<Either<Failure, IForm>> {
  return await createFormioForm(data)
}

export async function AdminDeleteFormHeader(
  formHeaderId: string,
): Promise<Either<Failure, IFormHeader>> {
  const res: Either<Failure, IFormHeader> = await deleteFormHeader(formHeaderId)
  return res
}

export async function anonGetSubmission(
  orgId: string,
  formId: string,
  submissionId: string
): Promise<Either<Failure, ISubmission>> {
  return await anonGetOneSubmission(orgId, formId, submissionId)
}