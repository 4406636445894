import React from 'react'
import PageContainer from '../../components/page/PageContainer'
import { useNavigate, useParams } from 'react-router-dom'
import { EdifyButton } from '../../components/buttons'
import { Box } from '@mui/material'
import LocationForm from './LocationForm'
import LocationCSVUpload from './LocationCSVUpload'
import {
  ProjectPageViewModel,
  useProjectPageViewModel,
} from '../project/ProjectPageViewModel'

function _NewLocation() {
  const navigate = useNavigate()
  const { projectId } = useParams()
  const { projectName, projectLoading } = useProjectPageViewModel()
  const [singleCreate, setSingleCreate] = React.useState<boolean>(true)
  const [locationCreated, setLocationCreated] = React.useState<any>(false)

  const getBreadCrumbs = () => {
    return [
      { title: 'Organization', to: '' },
      { title: 'Projects', to: '/projects' },
      { title: projectName, to: `/projects/${projectId}` },
      { title: 'New Location', to: `/projects/${projectId}/locations/new` },
    ]
  }
  if (projectLoading) {
    return (
      <PageContainer
        title=' Add Location/s'
        breadCrumbs={getBreadCrumbs()}
        mainPageLoading={true}
      >
        <p>loading Project</p>
      </PageContainer>
    )
  }
  const subtitle = locationCreated ? '' : `How would you like to add locations for ${projectName}?` 
  return (
    <PageContainer
      title=' Add Location/s'
      breadCrumbs={getBreadCrumbs()}
      mainPageLoading={projectLoading}
      subtitle={subtitle}
    >
      {!locationCreated && (
        <Box sx={{ marginBottom: '24px', gap: 24 }}>
          <EdifyButton
            title='Add One'
            secondary={!singleCreate}
            onClick={() => setSingleCreate(true)}
          />
          <EdifyButton
            buttonStyle={{ marginLeft: '12px' }}
            title='Location CSV Upload'
            secondary={singleCreate}
            onClick={() => setSingleCreate(false)}
          />
          <EdifyButton
            noBackground
            buttonStyle={{ marginLeft: '12px' }}
            title='Skip Add Location'
            onClick={() => navigate(`/projects/${projectId}`)}
          />
        </Box>
      )}
      {singleCreate && projectId ? (
        <LocationForm
          projectId={projectId}
          setLocationCreated={setLocationCreated}
        />
      ) : (
        <LocationCSVUpload projectId={projectId} />
      )}
      <Box>
        <EdifyButton 
          noBackground
          title={`Back to ${projectName}`}
          onClick={() => navigate(`/projects/${projectId}`)}
        />
      </Box>
    </PageContainer>
  )
}

const NewLocation = () => {
  const { projectId } = useParams()
  return (
    <ProjectPageViewModel projectIdProp={projectId}>
      <_NewLocation />
    </ProjectPageViewModel>
  )
}
export default NewLocation
