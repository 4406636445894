import React, { useEffect, useRef, useState } from 'react'

import PageContainer from '../../components/page/PageContainer'
import {
  DashboardPageViewModel,
  IDashboardPageViewModelProps,
  useDashboardPageViewModel,
} from './DashboardPageViewModel'

import { AppBorders } from '../../Theme'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import DemoPage from '../home/DemoPage'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CORRECTIVE_ACTIONS } from '../corrective-actions/CorrectiveActionsPage'

export const ROUTE_DASHBOARD = '/dashboard'

const _DashboardPage: React.FC = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [iframeSrc, setIframeSrc] = useState<string | undefined>(undefined)
  const [height, setHeight] = useState<string | number>('75vh')

  const vm: IDashboardPageViewModelProps = useDashboardPageViewModel()
  const orgVm = useOrganizationProvider()
  const navigate = useNavigate()

  useEffect(() => {
    if(orgVm.isContractor){
      navigate(ROUTE_CORRECTIVE_ACTIONS)
    }
    if (!vm.tokens) return
    const formUrl = `https://public.domo.com/embed/pages/${vm.tokens.embedId}`

    // Create the iframe source HTML
    const iframeSourceHTML = `
    <html>
      <body>
        <form id='form' action='${formUrl}' method='post'>
          <input type='hidden' name='embedToken' value='${vm.tokens.embedToken}' />
        </form>
        <script>
        document.getElementById('form').submit()
        </script>
      </body>
    </html>
    `

    // Set the iframe source to the generated HTML content
    setIframeSrc(
      `data:text/html;charset=utf-8,${encodeURIComponent(iframeSourceHTML)}`,
    )
  }, [vm.tokens])

  const handleMessage = (event: any) => {
    if (event.data.params && event.data.params.height) {
      setHeight(parseInt(event.data.params.height) + 'px')
      iframeRef.current!.style.height = event.data.params.height + 3 + 'px'
      iframeRef.current!.style.overflowY = 'hidden'
    }
  }

  useEffect(() => {
    if (orgVm.isDemoAccount) return
    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  if (orgVm.isDemoAccount || vm.error) return <DemoPage page='dashboard' />
  return (
    <PageContainer
      subtitle={vm.loading ? '': 'Take a look at your progress!'}
      title={vm.loading ? 'Loading' : vm.title}
      reload={vm.getTokens}
      mainPageLoading={vm.loading}
    >
      <InnerPageContainer
        sx={{
          position: 'relative',
          height: { height },
          background: 'white',
        }}
        innerPageLoading={!vm.tokens}
      >
        <iframe
          style={{
            padding: '-3px 16px',
            background: 'white',
            ...AppBorders.primary,
          }}
          ref={iframeRef}
          src={iframeSrc}
          data-testid='iframe'
          className='my-iframe'
          height='auto'
          width='100%'
          allowFullScreen
        />
      </InnerPageContainer>
    </PageContainer>
  )
}

export const DashboardPage = () => (
  <DashboardPageViewModel>
    <_DashboardPage />
  </DashboardPageViewModel>
)
