// UserManagementForm.tsx
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Input, SxProps } from '@mui/material'
import {
  EdifyFieldLabel,
  EdifyFormField,
  EdifySelect,
} from '../../components/form'
import EdifySearchDropdown, {
  ISearchResult,
} from '../../components/form/EdifySearch/EdifySearchDropdown'
import FormErrorText from '../../components/form/FormErrorText'
import { useUserPageViewModel } from './UserPageViewModel'
import { IRole } from '../../../domain/interfaces/IRole'
import { formatSnakeCase } from '../../../core/utils/text-formaters/TextFormatters'
import {
  usersSearch,
  searchLevels,
  searchProjects,
} from '../../../domain/domain'
import EdifyMenuItem from '../../components/form/EdifyMenuItem'
import { EdifyButton } from '../../components/buttons'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'

type UserInputs = {
  role: string
}

interface IUserManagementForm {
  sx?: SxProps
  showName?: boolean
}
export const UserManagementForm: React.FC<IUserManagementForm> = ({
  sx,
  showName,
}) => {
  const vm = useUserPageViewModel()
  const [projects, setProjects] = useState<ISearchResult[]>([])
  const [levels, setLevels] = useState<ISearchResult[]>([])
  const [pointOfContact, setPointOfContact] = useState<
    ISearchResult | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserInputs>({
    mode: 'onSubmit',
  })

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true)
    const formData = {
      role: data.role,
      selectedProjects: projects.map((p) => p.id),
      selectedLevels: levels.map((l) => l.id),
      pointOfContactId: pointOfContact?.id,
      email: vm.user?.email,
      phone: vm.user?.phone,
      fullName: vm.user?.fullName,
      id: vm.user?.id,
    }

    await vm.updateUser(formData)
    setIsLoading(false)
  })

  const projectSelected = (projects: ISearchResult[]) => {
    setProjects(projects)
  }

  const levelSelected = (levels: ISearchResult[]) => {
    setLevels(levels)
  }

  const contactSelected = (contacts: ISearchResult[]) => {
    console.log(contacts)
    setPointOfContact(contacts[0])
  }

  const setInitPointOfContact = () => {
    if (vm.user && vm.user.pointOfContactId) {
      return [
        {
          id: vm.user?.pointOfContactId,
          name: vm.user?.pointOfContactName ?? '',
        },
      ]
    } else return []
  }

  return (
    <InnerPageContainer headerTitle='User Settings' sx={{ ...sx }}>
      <form onSubmit={onSubmit}>
        {showName && (
          <Box style={{ display: 'flex', flex: 1 }}>
            <EdifyFormField  sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel disabled label='NAME' />
              <Input
                disableUnderline
                disabled
                defaultValue={vm.user?.fullName}
                className='form-control'
              />
          
            </EdifyFormField>
          </Box>
        )}
        <Box>
          <EdifyFormField>
            <EdifyFieldLabel required label='SELECT ROLE' />
            <EdifySelect
              width={483}
              defaultValue={vm.user?.roles[0].id}
              dropDownItems={vm.roles ?? []}
              {...register('role', {
                required: 'Role is required',
                value: vm.user?.roles[0].id,
              })}
              renderMenuItem={(item: IRole) => (
                <EdifyMenuItem key={item.id} value={item.id}>
                  {formatSnakeCase(item.name)}
                </EdifyMenuItem>
              )}
            />
            {errors.role && (
              <FormErrorText>{errors.role.message}</FormErrorText>
            )}
          </EdifyFormField>

          <EdifyFormField>
            <EdifyFieldLabel label='Point Of Contact' />
            <EdifySearchDropdown
              pillListWidth={'100%'}
              initialSelectedItems={setInitPointOfContact()}
              searchFunction={usersSearch}
              width={483}
              onSelect={contactSelected}
              nameKey='fullName'
              limit={16}
            />
          </EdifyFormField>

          <EdifyFormField>
            <EdifyFieldLabel label='SELECT LEVELS' />
            <EdifySearchDropdown
              multiSelect
              pillListWidth={'100%'}
              width={484}
              onSelect={levelSelected}
              searchFunction={searchLevels}
              initialSelectedItems={vm.user?.levels}
            />
          </EdifyFormField>

          <EdifyFormField>
            <EdifyFieldLabel label='SELECT PROJECTS' />
            <EdifySearchDropdown
              multiSelect
              pillListWidth={'100%'}
              width={484}
              onSelect={projectSelected}
              searchFunction={searchProjects}
              initialSelectedItems={vm.user?.projects}
            />
          </EdifyFormField>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            <EdifyButton
              disabled={isLoading}
              type='submit'
              primary
              title='Save Changes'
            />
          </Box>
        </Box>
      </form>
    </InnerPageContainer>
  )
}

export default UserManagementForm
