import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Input } from '@mui/material'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { EdifyButton } from '../../components/buttons'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTE_PROJECTS } from '../projects/ProjectsPage'
import { SuccessToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import {
  getLocationTemplateCSV,
  uploadLocationsCSVFile,
} from '../../../domain/domain'
import { AppColors } from '../../Theme'
import { EdifyFieldLabel, EdifyFormField } from '../../components/form'
import { ETypography } from '../../components/fonts/ETypography'
import FormErrorText from '../../components/form/FormErrorText'
import { set } from 'date-fns'
import { GridCloseIcon } from '@mui/x-data-grid'

interface FileUploadProps {
  loading: boolean
  handleUpload: (file: File) => void
  error?: string
  setError?: (error: string | undefined) => void
}

export const FileUpload: React.FC<FileUploadProps> = ({
  loading,
  handleUpload,
  error,
  setError,
}) => {
  const navigate = useNavigate()
  const [file, setFile] = useState<File | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files[0]) {
      setFile(files[0])
    }
  }

  const handleButtonClick = () => {
    // Programmatically click the hidden file input
    if (fileInputRef.current) {
      fileInputRef.current.click()
    } else {
      // Handle error
    }
  }
  const uploadClicked = async () => {
    if (file) {
      setError && setError(undefined)
      handleUpload(file)
    }
  }
  const clearFile = (e: any) => {
    e.stopPropagation()
    setFile(null)
    setError && setError(undefined)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        onClick={handleButtonClick}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          flexDirection: 'column',
          border: `1px solid ${AppColors.neutral600}`,
          borderRadius: '4px',
          height: '100px',
        }}
      >
        <input
          style={{
            display: 'none',
          }}
          ref={fileInputRef}
          type='file'
          accept='.csv'
          onChange={handleFileChange}
        />
        {file && (
          <ETypography font='MR' color='gray600' sx={{ textAlign: 'center' }}>
            {file.name}
            <GridCloseIcon
              onClick={clearFile}
              sx={{
                fontSize: '16px',
                color: AppColors.gray600,
                marginLeft: '10px',
                cursor: 'pointer',
              }}
            />
          </ETypography>
        )}
        {!file && (
          <ETypography font='MR' color='gray600' sx={{ textAlign: 'center' }}>
            Click to add a CSV file
          </ETypography>
        )}
      </Box>
      {error && (
        <FormErrorText
          sx={{
            width: 'auto',
            marginTop: '8px',
          }}
        >
          {error}
        </FormErrorText>
      )}
      {file && (
        <EdifyButton
          disabled={loading}
          noBackground
          title='Upload File'
          onClick={uploadClicked}
        />
      )}
    </Box>
  )
}

interface LocationCSVUploadProps {
  projectId?: string
  loadLocations?: () => void
}

const LocationCSVUpload: React.FC<LocationCSVUploadProps> = ({
  projectId,
  loadLocations,
}) => {
  const navigate = useNavigate()
  const [loadingCSV, setLoadingCSV] = useState(false)
  const [csvString, setCSVString] = useState('')
  const [error, setError] = useState<string | undefined>('')

  const handleUpload = async (file: File) => {
    if (file) {
      const res = await uploadLocationsCSVFile(file, projectId!)
      if (res.right) {
        SuccessToast({ title: 'Locations Uploaded Successfully' })
        if (loadLocations) {
          loadLocations()
        }
        navigate(`${ROUTE_PROJECTS}/${projectId}`)
        return
      }
      setError(res.left?.message ?? 'Failed to upload CSV file')
    }
  }

  const loadCSV = async () => {
    setLoadingCSV(true)
    const res = await getLocationTemplateCSV()
    setLoadingCSV(false)
    if (res.right) {
      setCSVString(res.right)
      return
    }
    setError(res.left?.message ?? 'Failed to load CSV Template')
  }

  const handleDownload = () => {
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'locations.csv') // Choose the file name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link) // Clean up after downloading
  }
  useEffect(() => {
    loadCSV()
  }, [])

  return (
    <InnerPageContainer
      headerTitle='Upload Location CSV'
      renderRightItems={() => (
        <EdifyButton
          noBackground
          title='Download Location CSV Template'
          onClick={handleDownload}
        />
      )}
    >
      <Grid container spacing={'24px'}>
        <Grid item sm={12} md={6}>
          <EdifyFormField>
            <EdifyFieldLabel label='Upload CSV File' />
            <FileUpload
              handleUpload={handleUpload}
              loading={loadingCSV}
              error={error}
              setError={setError}
            />
          </EdifyFormField>
        </Grid>
        <Grid item sm={12} md={6}>
          <EdifyFormField>
            <EdifyFieldLabel label='CSV Format' />
            <Input
              sx={{
                border: `1px solid ${AppColors.neutral600}`,
                borderRadius: '4px',
                width: '100%',
                padding: '2px 8px',
              }}
              disableUnderline
              disabled
              multiline
              rows={4}
              value={`
Name,Lat,Long,Address,
Address1 Name,1.234,-1.123, 123 street city state zip code,
Address2 Name,10.234,-10.123, 456 street city state zip code,
              `}
            />
          </EdifyFormField>
        </Grid>
      </Grid>
    </InnerPageContainer>
  )
}

export default LocationCSVUpload
