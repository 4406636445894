import React, { useEffect } from 'react'

import PageContainer from '../../components/page/PageContainer'

import { useParams } from 'react-router-dom'

import { ROUTE_FORMS } from '../forms/FormsPage'
import { Box, Grid } from '@mui/material'

import FormSettingsForm from './FormSettingsForm'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { ETypography } from '../../components/fonts/ETypography'
import { dateToMMMDYYYY } from '../../../core/core'
import { FormRoleSetting } from '../roles/Roles'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import CategoryForm from './CategoryForm'
import {
  SubmissionsPageViewModel,
  SubmissionsPageViewModelProps,
  useSubmissionsPageViewModel,
} from '../submissions/SubmissionsViewModel'
import DynamicHeadersSection from './DynamicHeadersSection'
import AdditionalForms from './AdditionalForms'

export const ROUTE_SUBMISSIONS = '/submissions'
export const ROUTE_CORRECTIVE_ACTIONS = '/correctiveActions'

export const FormSettingsPage: React.FC = () => {
  return (
    <SubmissionsPageViewModel>
      <_Page />
    </SubmissionsPageViewModel>
  )
}

export const _Page: React.FC = () => {
  const vm: SubmissionsPageViewModelProps = useSubmissionsPageViewModel()
  const { formCategory } = useParams()
  const { roles, setForms, reloadForms } = useOrganizationProvider()

  // if (vm.formError) {
  //   return (
  //     <PageContainer title='Error Occurred'>
  //       <EdifyButton
  //         data-testid='FormErrorButton'
  //         noBackground
  //         title='Reload'
  //         onClick={vm.reloadPage}
  //       />
  //     </PageContainer>
  //   )
  // }

  const getTitle = () => {
    return vm.form.title || 'FormSettings'
  }

  const title = getTitle()
  const { name, formioId, createdAt, updatedAt, id } = vm.form
  const [loaded, setLoaded] = React.useState<boolean>(false)

  useEffect(() => {
    setLoaded(true)
    return () => {
      if (loaded) {
        reloadForms()
      }
    }
  }, [loaded])

  return (
    <PageContainer
      title={title}
      breadCrumbs={[
        { title: 'All Forms', to: `${ROUTE_FORMS}/all` },
        {
          title: title,
          to: `/${formCategory}${ROUTE_SUBMISSIONS}/${vm.form?.id}`,
        },
        {
          title: 'Settings',
          to: `/${formCategory}${ROUTE_SUBMISSIONS}/${vm.form?.id}/settings`,
        },
      ]}
    >
      <InnerPageContainer sx={{ padding: '24px', marginBottom: '24px' }}>
        <Grid container>
          <SettingsInfoBlock
            header='Form Title'
            value={title}
            description='web/mobile'
          />
          <SettingsInfoBlock
            header='Form Name'
            value={name}
            description='formio'
          />
          <SettingsInfoBlock header='ID' value={id} description='DB' />

          <SettingsInfoBlock header='Formio ID' value={formioId ?? ''} />
          <SettingsInfoBlock
            header='Created'
            value={dateToMMMDYYYY(createdAt ?? '')}
          />
          <SettingsInfoBlock
            header='Last Update'
            value={dateToMMMDYYYY(updatedAt ?? '')}
          />
          <SettingsInfoBlock
            header='Formio Link'
            link={`https://wcf-form.edifyai.com/#/project/638fd1e99b746783c123fe9d/form/${formioId}/edit`}
            value={'View Form'}
          />
        </Grid>
      </InnerPageContainer>
      <FormSettingsForm />
      <CategoryForm />
      <AdditionalForms form={vm.form} />
      <FormRoleSetting setForms={setForms} form={vm.form} roles={roles ?? []} />
      <DynamicHeadersSection form={vm.form} reloadForm={vm.reloadForm} />
    </PageContainer>
  )
}

interface InfoBlockProps {
  header: string
  value: string
  link?: string
  description?: string
}
export const SettingsInfoBlock: React.FC<InfoBlockProps> = ({
  header,
  value,
  description,
  link,
}) => {
  return (
    <Grid item sm={12} md={6} lg={4} gap={'12px'}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          margin: '12px',
        }}
      >
        <ETypography font='XSM' color='gray50'>
          {header}
          {description && <span>{` (${description})`}</span>}
        </ETypography>
        {!link && (
          <ETypography font='SS' color='gray950'>
            {value}
          </ETypography>
        )}
        {link && (
          <a href={link} target='_blank' rel='noreferrer'>
            {value}
          </a>
        )}
      </Box>
    </Grid>
  )
}
