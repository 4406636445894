import React, { useContext, useEffect, useState } from 'react'
import { Either, Failure } from '../../../core/core'

import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import { IOrg, IOrgList } from '../../../domain/interfaces/IOrg'
import {
  getAllOrgs,
  getCurrentPerson,
  getCurrentUser,
} from '../../../domain/domain'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'

import { normalizeAdminOrgsForGrid } from '../../components/datagrid/gridData'
import useQuery from '../../../core/hooks/useQuery'
import {
  hideConfirmationDialog,
  showConfirmationDialog,
} from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'

//This will need to be dynamic hardcoding for now
interface AdminOrgRowProps {
  id: string
  name: string
  projects: string[] | undefined
  lastLogin: string | undefined
  role: string | undefined
}

export interface AdminOrgsPageViewModelProps {
  adminOrgs: IOrg[] | undefined
  normalizeAdminOrgsForDataGrid: () => AdminOrgRowProps[] | []
  error?: string
  clearError?: () => void
  loadMore?: (paginationModel: GridPaginationModel) => void
  sortSubmissions?: () => void
  sort?: string
  skip: number
  totalCount?: number
  reload: () => void
  isLoading: boolean
  isLoadMore: boolean
  isSearching: boolean
  editAdminOrg?: IOrg
  setEditAdminOrg: (adminOrg: IOrg | undefined) => void
  open: boolean
  closeDialog: () => void
  openDialog: () => void
  onSubmit: () => void
  // deleteAdminOrgs: (ids: string[]) => void
  formError: string | undefined
  setFormError: (error: string | undefined) => void
  sortClickedHandler: (sorter: GridSortModel) => void
  clearFilters: (reload: boolean) => void
  queryHelper: Record<string, any>
  fetchAdminOrgs: (fetchSkip: number, query: Record<string, any>) => void
}

const AdminOrgsPageContext =
  React.createContext<AdminOrgsPageViewModelProps | null>(null)

export function useAdminOrgsPageViewModel(): AdminOrgsPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(AdminOrgsPageContext)!
}
interface Props {
  children: React.ReactElement | React.ReactElement[]
}
export const DEFAULT_LIMIT = 20

export const AdminOrgsPageViewModel: React.FC<Props> = ({ children }) => {
  const person = getCurrentPerson()

  const queryHelper = useQuery({ skip: 0, limit: DEFAULT_LIMIT })
  const { defaultQuery, reset, getQueryStringFromQuery, setNew, query } =
    queryHelper

  const [isLoading, setIsLoading] = useState({
    initialLoad: false,
    loadMore: false,
    search: false,
  })
  const [skip, setSkip] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [error, setError] = useState<string | undefined>(undefined)
  const [adminOrgs, setAdminOrgs] = useState<IOrg[] | undefined>(undefined)
  const [open, setOpen] = useState<boolean>(false)
  const [editAdminOrg, setEditAdminOrg] = useState<IOrg | undefined>(undefined)
  const [formError, setFormError] = useState<string | undefined>(undefined)

  const clearFilters = async (reload = false) => {
    reset()
    if (reload) {
      fetchAdminOrgs(0, defaultQuery)
    }
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const openDialog = () => {
    setOpen(true)
  }
  const onSubmit = () => {
    // Not sure if this is being used
  }

  const fetchAdminOrgs = async (
    fetchSkip: number,
    query: Record<string, any>,
  ) => {
    setIsLoading((oldState) => {
      return { ...oldState, loadMore: true }
    })
    setError(undefined)

    // load submissions or corrective actions
    const newQuery = { ...query, skip: fetchSkip }
    // TODO WHEN PAGINATION SEARCH ADD TO BE
    // const res: Either<Failure, IOrgList> = await getAllAdminOrgs(
    //   getQueryStringFromQuery(newQuery),
    // )
    const res: Either<Failure, IOrgList> = await getAllOrgs()
    setIsLoading((oldState) => {
      return { ...oldState, initialLoad: false, loadMore: false }
    })
    if (res.isRight()) {
      // setTotalCount(res.right?.total_count || 0)
      const orgs = res.right?.data as IOrg[]
      // setAdminOrgs(res.right?.data as IOrg[])
      const orgsWithUserRole = orgs.map((org) => {
        const user = person!.users.find((u) => {
          return u.orgId == org.id
        })
        return { ...org, userOrgRole: user ? user.roles[0].name : '-' }
      })
      setAdminOrgs(orgsWithUserRole)
      // setSkip(fetchSkip)
    } else {
      setError(res.left?.message)
    }
    // setNew(newQuery)
  }

  const loadMore = (paginationModel: GridPaginationModel) => {
    const fetchSkip = paginationModel.page * paginationModel.pageSize
    fetchAdminOrgs(fetchSkip, query)
  }

  const getAdminOrgs = async () => {
    setIsLoading((oldState) => {
      return { ...oldState, initialLoad: true }
    })
    setSkip(0)
    setAdminOrgs([])

    fetchAdminOrgs(0, query)
  }

  const normalizeAdminOrgsForDataGrid: any = () => {
    return normalizeAdminOrgsForGrid(adminOrgs, person?.orgs)
  }

  //DELETE
  // const deleteAdminOrgs = async (ids: string[]) => {
  //   const confirmMessage = `${ids.length} ${
  //     ids.length == 1 ? 'adminOrg' : 'adminOrgs'
  //   }`
  //   showConfirmationDialog({
  //     title: 'Confirm Delete',
  //     message: `Are you sure you want to delete ${confirmMessage} from the database? This action cannot be undone.`,
  //     cancelText: 'Cancel',
  //     confirmText: 'Delete',
  //     onCancel: function (): void {
  //       hideConfirmationDialog()
  //     },
  //     onConfirm: async () => {
  //       const results = await adminDeleteAdminOrgs(ids)
  //       // something went wrong with api call
  //       if (results.isLeft()) {
  //         // backend error
  //         ErrorToast({
  //           title: `Failed to delete ${confirmMessage} from the database`,
  //         })
  //       }
  //       if (results.right) {
  //         SuccessToast({
  //           title: `Successfully deleted ${confirmMessage} from the database`,
  //         })
  //         getAdminOrgs()
  //       }
  //       setEditAdminOrg(undefined)
  //       hideConfirmationDialog()
  //       closeDialog()
  //     },
  //   })
  // }

  const sortClickedHandler = (sorter: GridSortModel) => {
    const { field, sort } = sorter[0]
    fetchAdminOrgs(0, {
      ...query,
      skip: 0,
      sortBy: field,
      sortOrder: sort?.toUpperCase(),
    })
  }

  useEffect(() => {
    setError(undefined)
    getAdminOrgs()
  }, [])

  const onClearError = () => {
    //not sure what do here
    setError(undefined)
  }

  return (
    <AdminOrgsPageContext.Provider
      value={{
        adminOrgs,
        normalizeAdminOrgsForDataGrid,
        isLoading: isLoading.initialLoad,
        isLoadMore: isLoading.loadMore,
        isSearching: isLoading.search,
        loadMore,
        skip,
        error,
        clearError: onClearError,
        reload: getAdminOrgs,
        totalCount,
        editAdminOrg,
        setEditAdminOrg,
        open,
        closeDialog,
        openDialog,
        onSubmit,
        // deleteAdminOrgs,
        formError,
        setFormError,
        sortClickedHandler,
        queryHelper,
        clearFilters,
        fetchAdminOrgs,
      }}
    >
      {children}
    </AdminOrgsPageContext.Provider>
  )
}
export default AdminOrgsPageContext
