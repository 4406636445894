import React, { useState } from 'react'

import { useI18nProvider } from '../../../../providers/InternationalizationProvider'
import { Check } from '@mui/icons-material'
import globalI18n, { setTempLanguage } from '../../../../i18n'
import {
  removeFromLocalStorage,
  saveToLocalStorage,
} from '../../../../data/data'
import LanguageIcon from '@mui/icons-material/Language'
import { AppColors, AppHovers } from '../../../Theme'
import { getCurrentPerson, updatePerson } from '../../../../domain/domain'
import { SuccessToast } from '../../../../core/utils/toast-notifications/ToastNotifications'
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material'

import ISO6391 from 'iso-639-1'
import { showLangConfirmationV2Message } from '../../dialogs/confirmation-dialog/ConfirmationDialogV2'


interface Props {
  anonymous?: boolean
}

export const LanguageSearch: React.FC<Props> = ({ anonymous = false }) => {
  const {
    languages,
    languagesLoading,
    orgI18nLoaded,
    fetchAndSetTranslations,
  } = useI18nProvider()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [selectedOption, setSelectedOption] = useState(globalI18n.language)
  const [updatingPersonLanguage, setUpdatingPersonLanguage] =
    useState<boolean>(false)

  const darkMode = !anonymous

  const updatePersonLanguage = async (language: string) => {
    setUpdatingPersonLanguage(true)
    const person = getCurrentPerson()
    if (!person) {
      setUpdatingPersonLanguage(false)
      return
    }
    const res = await updatePerson({ language }, person.id)
    if (res.isLeft() || !res.right) {
      //What to do here?
      setUpdatingPersonLanguage(false)
      return
    }
    removeFromLocalStorage('person')
    // overwrite the fields of person with the newly updated ones
    saveToLocalStorage('person', JSON.stringify({ ...person, ...res.right }))
    setUpdatingPersonLanguage(false)
    SuccessToast({ title: 'Profile language has been updated successfully.' })
  }


  const updatePersonTempLanguage = async (language: string) => {
    setUpdatingPersonLanguage(true)
    const person = getCurrentPerson()
    setUpdatingPersonLanguage(false)
    if (person) {
      person.language = language
      removeFromLocalStorage('person')
      // overwrite the fields of person with the newly updated ones
      saveToLocalStorage('person', JSON.stringify(person))
      setUpdatingPersonLanguage(false)
    }
    SuccessToast({ title: 'Profile language has been updated successfully.' })
  }

  const handleChange = async (lang: any) => {

    showLangConfirmationV2Message(
      'You can change your language preference back at any time. this text is for testing',
      'Would you like to save English as your primary language?',
      'Save',
      'Save Once',
      () => { saveLanguagePermnant(lang) },
      () => { saveLanguageTemprory(lang) }
    )
    handleClose()
  }
  const saveLanguagePermnant = async (lang: any) => {
    if (!orgI18nLoaded || loading) return
    setSelectedOption(lang)
    globalI18n.changeLanguage(lang)
    fetchAndSetTranslations()
    if (!anonymous) {
      await updatePersonLanguage(lang)
    }
    setTempLanguage(false)
  }

  const saveLanguageTemprory = async (lang: any) => {
    if (!orgI18nLoaded || loading) return
    setSelectedOption(lang)
    globalI18n.changeLanguage(lang)
    fetchAndSetTranslations()
    if (!anonymous) {
      await updatePersonTempLanguage(lang)
    }
    setTempLanguage(true)
  }

  if (!languages) return null
  const loading = updatingPersonLanguage || languagesLoading
  const color = darkMode ? AppColors.baseWhite : AppColors.gray600
  const backgroundColor = darkMode ? AppColors.gray600 : AppColors.baseWhite
  const hover = darkMode ? { ...AppHovers.dark } : { ...AppHovers.primary }
  return (
    <>
      <IconButton
        sx={{
          color,
          // minWidth: '100px',
          border: darkMode ? 'none' : `1px solid ${AppColors.neutral600}`,
          backgroundColor: backgroundColor,
          '&:hover': hover,
          borderRadius: '5px',
          marginRight: anonymous ? '0px' : '24px',
          padding: '10px 16px',
        }}
        onClick={handleClick}
      >
        {loading && (
          <CircularProgress
            size='1rem'
            sx={{
              marginRight: '8px',
              color: color,
            }}
          />
        )}

        {!loading && (
          <LanguageIcon sx={{ color, marginRight: '6px', fontSize: '20px' }} />
        )}

        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '15px',
            lineHeight: '22px',
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'block' },
          }}
        >
          {loading ? 'Loading...' : ISO6391.getNativeName(selectedOption)}
        </Typography>
      </IconButton>
      <Menu
        id='language-menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '.MuiMenu-paper ': {
            marginTop: '6px',
            background: backgroundColor,
            border: 'none',
            minWidth: '115px',
          },
        }}
      >
        <MenuList>
          {languages.map((l: any) => {
            return (
              <MenuItem
                key={l.key}
                value={l.value}
                selected={l.key == selectedOption}
                sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  '&:hover': hover,
                  '.MuiMenuItem-root.Mui-selected:hover': hover,
                  '&.Mui-selected': {
                    backgroundColor: darkMode ? 'black' : AppColors.neutral600,
                  },
                  color: color,
                }}
                onClick={() => handleChange(l.key)}
              >
                <Box>
                  <span>{l.value}</span>
                </Box>
                {l.key == selectedOption && (
                  <Box
                    sx={{
                      justifyContent: 'space-between',
                      marginLeft: '24px',
                    }}
                  >
                    <Check
                      sx={{
                        color: color,
                      }}
                    />
                  </Box>
                )}
              </MenuItem>
            )
          })}
        </MenuList>
      </Menu>
    </>
  )
}
