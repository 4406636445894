import { useState } from 'react'
// TODO Might want to user generics here to make the return type more specific

/** @typedef IQuery The type for a generic object that can have any string key, and values of any type. */

/**
 * A custom hook for managing state as a query object and creating a query string.
 *
 * @param {IQuery} initQuery - An object that will be used as the initial state for the query.
 * @returns {object} An object containing methods to interact with the query state.
 */
const useQuery = (initQuery: Record<string, any>) => {
  const [query, setQuery] = useState(initQuery)
  const defaultQuery = initQuery

  /**
   * Returns the value of the query state associated with a given key.
   *
   * @param {string} key - The key to get the value of from the query state.
   * @returns {any} The value from the query state associated with the key.
   */
  const get = (key: string) => {
    return query[key]
  }

  /**
   * Sets the value of a given key in the query state.
   *
   * @param {string} key - The key to set the value of in the query state.
   * @param {any} value - The value to set for the key in the query state.
   */
  const set = (key: string, value: any) => {
    setQuery((prevQuery) => ({ ...prevQuery, [key]: value }))
  }

  const setNew = (query: Record<string, any>) => {
    setQuery(query)
  }

  const remove = (key: string) => {
    setQuery((prevQuery) => {
      const { [key]: value, ...remainingQuery } = prevQuery
      return remainingQuery
    })
  }

  const reset = () => {
    setQuery(defaultQuery)
  }

  /**
   * Transforms the query state into a query string.
   *
   * @returns {string} The query state transformed into a query string.
   */
  const getQueryString = () => {
    let queryString = ''
    Object.keys(query).forEach((key) => {
      if (query[key] !== '' && query[key] !== 'false' ) {
        queryString += `&${key}=${query[key]}`
      }
    })
    return queryString
  }

  const getQueryStringFromQuery = (obj: Record<string, any>) => {
    let queryString = ''
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== '' && obj[key] !== 'false' ) {
        queryString += `&${key}=${obj[key]}`
      }
    })
    return queryString
  }

  return {
    get,
    set,
    getQueryString,
    reset,
    setNew,
    query,
    remove,
    defaultQuery,
    getQueryStringFromQuery
  }
}

export default useQuery
