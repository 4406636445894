import { Either, Failure } from '../../../core/core'
import {
  createUser,
  deleteUser,
  deleteUsers,
  editUser,
  getUsers,
  searchUsers,
  usersClientSearch,
  getUserById,
  anonGetOneUser
} from '../../../data/repositories/users/UsersRepository'
import { IUser, IUserList, IUsersDeleted } from '../../interfaces/IUser'

export async function getAllUsers(queryString: string): Promise<Either<Failure, IUserList>> {
  return await getUsers(queryString)
}

export async function getSpecificUser(id: string): Promise<Either<Failure, IUser>> {
  return await getUserById(id)
}

export async function clientSearch(
  skip: number,
  limit?: number,
  key?: string,
): Promise<Either<Failure, IUserList>> {
  return await usersClientSearch(skip, limit, key)
}

export async function adminCreateUser(
  name: string,
  email: string,
  roleIds: string[],
  projectIds: string[],
  levelIds: string[],
  phone?: string,
  pointOfContactId?: string | null,
): Promise<Either<Failure, IUser>> {
  return await createUser(email, name, roleIds, projectIds, levelIds, phone, pointOfContactId)
}

export async function adminEditUser(
  userId: string,
  name: string,
  email: string,
  roleIds: string[],
  projectIds: string[],
  levelIds: string[],
  phone?: string,
  pointOfContactId?: string | null,
): Promise<Either<Failure, IUser>> {
  return await editUser(userId, email, name, roleIds, projectIds, levelIds, phone, pointOfContactId)
}

export async function adminDeleteUser(
  userId: string,
): Promise<Either<Failure, IUser>> {
  return await deleteUser(userId)
}

export async function adminDeleteUsers(
  userIds: string[],
): Promise<Either<Failure, IUsersDeleted>> {
  return await deleteUsers(userIds)
}

export async function usersSearch(
  skip: number,
  limit?: number,
  key?: string,
): Promise<Either<Failure, IUserList>> {
  return await searchUsers(skip, limit, key)
}

export async function anonGetUser(
  orgId: string,
  userID: string,
): Promise<Either<Failure, IUser>> {
  return await anonGetOneUser(orgId, userID)
}