import React from 'react'
import PageContainer from '../../components/page/PageContainer'
import { Box } from '@mui/material'

import {
  ContractorPageViewModel,
  ContractorPageViewModelProps,
  useContractorPageViewModel,
} from './ContractorPageViewModel'
import { ROUTE_CONTRACTORS } from '../contractors/ContractorsPage'

import { EdifyReadFormField } from '../../components/form/shared/EdifyReadFormField'
import ContractorsForm from './ContractorsForm'
import { phoneInputFormatter } from '../../../core/utils/input-formatters/InputFormatters'

import ContactsList from '../contacts/ContactsList'
import {
  ContactsViewModel,
  ContactsViewModelProps,
  useContactsViewModel,
} from '../contacts/ContactsViewModel'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'
import { useNavigate, useParams } from 'react-router-dom'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import PageHeader from '../../components/page/PageHeader'
import { EdifyButton } from '../../components/buttons'
import { AppColors } from '../../Theme'
import { TrashIcon } from '../../../core/components/icons/TrashIcon'
import { ProfileHeader } from '../../components/profile/ProfileHeader'

const _ContractorPage: React.FC<IContractorPageProps> = ({ isEdit, id }) => {
  const vm: ContractorPageViewModelProps = useContractorPageViewModel()
  const contactsVM: ContactsViewModelProps = useContactsViewModel()

  const navigate = useNavigate()
  const canEdit = usePermission(ERole.ContractorEdit)
  const { contractorId } = useParams()

  const showEditButton = () => {
    // if (currentUserId == vm.user?.id) return true
    return canEdit
  }

  const getTitle = () => {
    if (vm.contractor) {
      return (
        <PageHeader title={vm.contractor.name ?? ''} />
        // <ProfileHeaderV2
        //   fullName={vm.user.fullName}
        //   imageURL={vm.user.imageURL}
        // />
      )
    }

    return 'Loading...'
  }
  const handleEditClicked = () => {
    navigate(`${ROUTE_CONTRACTORS}/${vm.contractor?.id}/edit`)
  }
  return (
    <PageContainer
      // title={getTitle()}
      breadCrumbSX={{ marginBottom: '0px' }}
      // renderRightItems={() => {
      //   if (isEdit) {
      //     return (
      //       <Box>
      //         <EdifyButton
      //           sx={{ '&:hover': { color: AppColors.danger600 } }}
      //           data-testid='DeleteSubButton'
      //           noBackground
      //           alertInverse
      //           onClick={vm.deleteContractor}
      //           icon={<TrashIcon />}
      //           title='Remove'
      //         />
      //       </Box>
      //     )
      //   }

      //   return (
      //     <Box sx={{ display: 'flex' }}>
      //       <EdifyButton
      //         hide={!showEditButton()}
      //         noBackground
      //         title={'Edit'}
      //         onClick={() =>
      //           navigate(`${ROUTE_CONTRACTORS}/${vm.contractor?.id}/edit`)
      //         }
      //       />
      //     </Box>
      //   )
      // }}
      breadCrumbs={[
        {
          title: 'Contractors',
          to: `${ROUTE_CONTRACTORS}`,
        },
        {
          title: vm.contractor?.name as string,
          to: `${ROUTE_CONTRACTORS}/${vm.contractor?.id}`,
        },
        ...(isEdit
          ? [
            {
              title: 'Edit',
              to: `${ROUTE_CONTRACTORS}/${vm.contractor?.id}/edit`,
            },
          ]
          : []),
      ]}
    >
      {isEdit ? (
        <>
          <InnerPageContainer
            innerPageError={vm.contractorError}
            innerPageLoading={vm.contractorLoading}
            sx={{  padding: '0', paddingBottom: '24px' }}
          >
            <ProfileHeader
              canEdit={canEdit}
              profile={vm.contractor}
              deleteProfile={vm.deleteContractor}
              isEdit={true}
              // setIsEdit={setIsEdit}
              handleClick={handleEditClicked}
              fullName={vm.contractor?.name}
            
            />
          </InnerPageContainer>
          <ContractorsForm
            contractor={vm.contractor}
            id={contractorId}
            onCancel={() => navigate(-1)}
            sx={{ border: 'none' }}
          />
        </>
      ) : (
        <>
          <InnerPageContainer
            innerPageError={vm.contractorError}
            innerPageLoading={vm.contractorLoading}
            sx={{ marginBottom: '24px', padding: '0', paddingBottom: '24px' }}
          >
            <ProfileHeader
              canEdit={canEdit}
              profile={vm.contractor}
              deleteProfile={vm.deleteContractor}
              // setIsEdit={setIsEdit}
              handleClick={handleEditClicked}
              fullName={vm.contractor?.name}
            />
            <Box sx={{ width: '100%', padding: '0px 24px' }}>
              <Box style={{ display: 'flex', flex: 1 }}>
                <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'CONTRACTOR ID'}
                  value={vm.contractor?.identifierField ?? 'NA'}
                />
                <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'TRADE'}
                  value={vm.contractor?.trade ?? 'NA'}
                />
              </Box>
            </Box>
            <Box sx={{ width: '100%', padding: '0px 24px' }}>
              <Box style={{ display: 'flex', flex: 1 }}>
                <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'PRIMARY POINT OF CONTACT'}
                  value={vm.contractor?.pointOfContactName ?? 'NA'}
                />
              </Box>
            </Box>
            <Box sx={{ width: '100%', padding: '0px 24px' }}>
              <Box style={{ display: 'flex', flex: 1 }}>
                <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'EMAIL'}
                  value={vm.contractor?.pointOfContactEmail ?? 'NA'}
                />
                <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'PHONE'}
                  value={
                    vm.contractor?.pointOfContactPhone
                      ? phoneInputFormatter(vm.contractor?.pointOfContactPhone)
                      : 'NA'
                  }
                />
              </Box>
            </Box>
          </InnerPageContainer>

          <ContactsList vm={contactsVM} contractorName={vm.contractor?.name} />
        </>
      )}
    </PageContainer>
  )
}
interface IContractorPageProps {
  isEdit?: boolean
  id?: string
}
export const ContractorPageTemp: React.FC<IContractorPageProps> = (props) => {
  const { contractorId } = useParams()
  return (
    <ContractorPageViewModel>
      <ContactsViewModel contractorId={contractorId ?? ''}>
        <_ContractorPage {...props} id={contractorId} />
      </ContactsViewModel>
    </ContractorPageViewModel>
  )
}
