import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  SxProps,
  TextareaAutosize,
} from '@mui/material'
import {
  EdifyFieldLabel,
  EdifyFormField,
  EdifySelect,
} from '../../components/form'
import { EdifyButton } from '../../components/buttons'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import EdifySearchDropdown, {
  ISearchResult,
} from '../../components/form/EdifySearch/EdifySearchDropdown'
import {
  adminCreateGoal,
  getCurrentUser,
  getGoalById,
  searchLevels,
  searchProjects,
  // updateGoal,
  usersSearch,
} from '../../../domain/domain'
import { FieldErrors, Resolver, useForm } from 'react-hook-form'
import FormErrorText from '../../components/form/FormErrorText'
import { searchForms, searchUsers } from '../../../data/data'
import FormSection from '../../components/form/Containers/FormSection'
import EdifyDatePicker from '../../components/form/EdifyDatePicker'
import dayjs, { Dayjs } from 'dayjs'
import { EdifyButtonGroup } from '../../components/form/EdifyButtonGroup/EdifyButtonGroup'
import {
  EGoalDuration,
  EGoalIncentive,
  EGoalReminder,
  EGoalStatus,
  ETemplate,
  ETrackingPeriod,
  IGoalFormData,
} from '../../../domain/interfaces/IGoal'
import EdifyMenuItem from '../../components/form/EdifyMenuItem'
import { updateGoal } from '../../../data/repositories/goals/GoalsRepository'
import ErrorContainer from '../../components/error/ErrorContainer'
import { AppColors } from '../../Theme'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import {
  hideConfirmationDialog,
  showConfirmationDialog,
} from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import moment from 'moment'
import { IForm } from '../../../domain/interfaces/IForm'
import { AccessAlarm, Add, Remove } from '@mui/icons-material'
import { ETypography } from '../../components/fonts/ETypography'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

//just need validation on name since it is the only required field
interface IGoalInput {
  name: string
  description: string
  trackingPeriod: string
  goalCount: string
}

const durationButtonGroup: string[] = [
  EGoalDuration.WEEKLY,
  EGoalDuration.MONTHLY,
  EGoalDuration.QUARTERLY,
  EGoalDuration.NONE,
]

const reminderButtonGroup: string[] = [
  EGoalReminder.DAILY,
  EGoalReminder.WEEKLY,
  EGoalReminder.ONE_DAY_BEFORE_ENDING,
  EGoalReminder.NO_REMINDER,
]

const incentiveButtonGroup: string[] = [
  EGoalIncentive.BRAGGING_RIGHTS,
  EGoalIncentive.SALARY_BONUS,
  EGoalIncentive.SWAG,
  EGoalIncentive.TEAM_LUNCH,
]

export const ETrackingArray = ['Daily', 'Weekly', 'Monthly', 'Quarterly']

export const GoalsForm = () => {
  // Validation
  const resolver: Resolver<IGoalInput> = async (values) => {
    const errors: FieldErrors<IGoalInput> = {}
    if (!values.name) {
      errors.name = {
        type: 'required',
        message: 'Name is required',
      }
    }
    if (!values.goalCount) {
      errors.goalCount = {
        type: 'required',
        message: 'Submissions is required',
      }
    }
    if (parseInt(values.goalCount) <= 0 || values.goalCount == '0') {
      errors.goalCount = {
        type: 'min',
        message: 'Count must be greater than 0',
      }
    }
    return {
      values,
      errors,
    }
  }
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<IGoalInput>({
    resolver,
    mode: 'onChange',
  })
  const {
    allLevels,
    allProjects,
    templates,
    getHierarchyName,
    forms: formIOforms,
  } = useOrganizationProvider()
  const levelName = getHierarchyName(1, true)
  const projectName = getHierarchyName(0, true)
  const { id } = useParams()

  const user = getCurrentUser()
  const owner = {
    id: user?.id,
    name: user?.fullName,
    unEditable: true,
  } as ISearchResult

  const [startDate, setStartDate] = useState<string | null>(null)
  const [goalStarted, setGoalStarted] = useState<boolean>(id ? true : false)
  const [duration, setDuration] = useState<string>(EGoalDuration.MONTHLY)
  const [incentive, setIncentive] = useState<string>(
    EGoalIncentive.BRAGGING_RIGHTS,
  )
  const [watchersReminder, setWatchersReminder] = useState<string>(
    EGoalReminder.DAILY,
  )
  const [participantsReminder, setParticipantsReminder] = useState<string>(
    EGoalReminder.DAILY,
  )
  const [selectedTemplate, setSelectTemplate] =
    useState<string>('Form Completion')
  const getDurationMessage = () => {
    //xxx
    const count = getValues('goalCount')
    const action =
      selectedTemplate == 'Form Completion'
        ? 'form submissions'
        : 'daily logins'
    let tp
    let dur
    switch (trackingPeriod) {
      case ETrackingPeriod.DAILY:
        tp = 'day'
        break
      case ETrackingPeriod.WEEKLY:
        tp = 'week'
        break
      case ETrackingPeriod.MONTHLY:
        tp = 'month'
        break
      default:
        tp = 'quarter'
        break
    }
    switch (duration) {
      case EGoalDuration.WEEKLY:
        dur = 'for 1 week'
        break
      case EGoalDuration.MONTHLY:
        dur = 'for 1 month'
        break
      case EGoalDuration.QUARTERLY:
        dur = 'for 1 quarter'
        break
      default:
        dur = 'indefinitely'
        break
    }
    // const templateName = tem
    return `This goal & control challenges its participants to complete ${count} ${action} per ${tp} ${dur}.`
  }

  const [watchers, setWatchers] = useState<ISearchResult[]>([])
  const [forms, setForms] = useState<ISearchResult[]>([])
  const [formID, setFormID] = useState<string | null>(null)
  const [goalLoading, setGoalLoading] = useState<boolean>(false)
  const [goalError, setGoalError] = useState<string | undefined>(undefined)
  const [submissionError, setSubmissionError] = useState<string | undefined>()
  const [submissionLoading, setSubmissionLoading] = useState<boolean>(false)
  const [projects, setProjects] = useState<ISearchResult[]>([])
  const [levels, setLevels] = useState<ISearchResult[]>([])
  const [users, setUsers] = useState<ISearchResult[]>([])
  const [participantType, setParticipantType] = useState<string>('Users')
  // need to figure out how to set this react hook form
  const [initTrackingPeriod, setInitTrackingPeriod] = useState<string>('Weekly')
  const [trackingPeriod, setTrackingPeriod] = useState<string>('Weekly')
  const [formError, setFormError] = useState<string | null>(null)
  const [durantionMessage, setDurationMessage] = useState<string | null>(
    getDurationMessage(),
  )

  const navigate = useNavigate()

  const handleIncrement = () => {
    const value = getValues('goalCount')
    const val = parseInt(value) + 1
    setValue('goalCount', val + '')
    setDurationMessage(getDurationMessage())
  }

  const handleDecrement = () => {
    const value = getValues('goalCount')
    const val = parseInt(value) - 1
    if (val > 0) setValue('goalCount', val + '')
    setDurationMessage(getDurationMessage())
  }

  //  TODO: get actual IDS
  // or have BE go by name
  const getTemplateId = () => {
    const template = templates.find((t) => t.name == selectedTemplate)
    if (!template || !template.id) {
      return '8722b3a8-6df2-49f8-a33f-fcf1e5d65d78'
    }
    return template.id
  }

  /**
   * Handles the form submission when the form is valid.
   * will run resolver validations before submitting
   * @param {Object} data - The form data submitted by the user.
   * @returns {void}
   */
  const onSubmit = handleSubmit((data: IGoalInput) => {
    // react-hook-form will check for errors here and return
    // need to check form if form goal
    const selectedTemplateId = getTemplateId()
    // is form
    if (
      selectedTemplateId == 'bf099928-50b0-4be3-8c95-e319a49081cb' &&
      !formID
    ) {
      setFormError('Form is required')
      ErrorToast({ title: 'Form is required' })
      return
    }

    const formData: IGoalFormData = {
      ...data,
      templateId: selectedTemplateId,
      formId: formID ?? null,
      goalCount: parseInt(data.goalCount),
      duration,
      startDate: startDate ?? '',
      incentive,
      watchersReminder,
      trackingPeriod,
      participantsReminder,
      state: EGoalStatus.ACTIVE,
      watcherIds: watchers.map((w) => w.id),
      userParticipantIds: users.map((u) => u.id),
      divisionParticipantIds: levels.map((l) => l.id),
      projectParticipantIds: projects.map((p) => p.id),
    }
    if (id) handleUpdate(formData, id)
    else handleCreate(formData)
  })

  const handleCreate = async (goalFormData: IGoalFormData) => {
    setSubmissionLoading(true)
    setSubmissionError(undefined)
    const res = await adminCreateGoal(goalFormData)
    setSubmissionLoading(false)
    if (res.isLeft()) {
      setSubmissionError(
        res.left?.message ?? 'A unknown error occurred, please try again later',
      )
      ErrorToast({ title: 'Error adding goal' })
      return
    }
    if (res.isRight()) {
      SuccessToast({ title: 'Successfully Created New Goal' })
      navigate(-1)
    }
  }

  const handleUpdate = async (goalFormData: IGoalFormData, id: string) => {
    setSubmissionLoading(true)
    setSubmissionError(undefined)
    const res = await updateGoal(goalFormData, id)
    setSubmissionLoading(false)
    if (res.isLeft()) {
      setSubmissionError(
        res.left?.message ??
          'A unknown error occurred, please try again later.',
      )
      ErrorToast({ title: 'Error updating changes.' })
      return
    }
    if (res.isRight()) {
      SuccessToast({ title: 'Successfully updated goal.' })
      navigate(-1)
    }
  }

  const formSelected = (forms: ISearchResult[]) => {
    // form selection was cleared
    if (!forms[0]) {
      setFormID(null)
      setFormError('Form is required')
      return
    }
    setFormError(null)
    setFormID(forms[0].id)
  }
  const watcherSelected = (watchers: ISearchResult[]) => {
    setWatchers(watchers)
  }

  const levelSelected = (levels: ISearchResult[]) => {
    setLevels(levels)
  }
  const projectSelected = (projects: ISearchResult[]) => {
    setProjects(projects)
  }
  const userSelected = (users: ISearchResult[]) => {
    setUsers(users)
  }

  // Get Info for Goal Edit
  const getGoal = async () => {
    setGoalError(undefined)
    setGoalLoading(true)
    const res = await getGoalById(id!)
    setGoalLoading(false)
    if (res.isLeft() || !res.right) {
      setGoalError('Error loading form.')
      return
    }
    const {
      name,
      description,
      trackingPeriod,
      goalCount,
      watchersReminder,
      participantsReminder,
      incentive,
      duration,
      formId,
      startDate,
      templateId,
      state,
      watchers: initWatchers,
      users: initUsers,
      levels: initLevels,
      projects: initProjects,
    } = res.right

    reset({
      name,
      description,
      // trackingPeriod,
      goalCount: goalCount + '',
    })
    setTrackingPeriod(trackingPeriod)
    setInitTrackingPeriod(trackingPeriod)
    setWatchersReminder(watchersReminder)
    setParticipantsReminder(participantsReminder)
    setParticipantsReminder(participantsReminder)
    setIncentive(incentive)
    setDuration(duration)
    setFormID(formId ?? null)
    setStartDate(startDate)

    if (state == 'scheduled') {
      setGoalStarted(false)
    }
    setWatchers(
      initWatchers.map((w) => {
        const unEditable = user!.id === w.id
        return { id: w.id, name: w.fullName, unEditable }
      }),
    )
    setLevels(
      initLevels.map((l) => {
        return { id: l.id, name: l.name }
      }),
    )
    setUsers(
      initUsers.map((u) => {
        return { id: u.id, name: u.fullName }
      }),
    )
    setProjects(
      initProjects.map((p) => {
        return { id: p.id, name: p.name }
      }),
    )
    const initialParticipantType = initLevels.length
      ? `${levelName}`
      : initProjects.length
        ? `${projectName}`
        : 'Users'
    setParticipantType(initialParticipantType)

    // form edit set logic
    const initSelectedTemplate = templates.find(
      (t) => t.id == templateId,
    ) as ETemplate
    setSelectTemplate(initSelectedTemplate.name)
    if (formId) {
      const form = formIOforms.find((f) => f.id == formId) as IForm
      setForms([{ id: formId ?? '', name: form?.name ?? '' }])
    }
  }

  const startDateChanged = (date: dayjs.Dayjs | null) => {
    const dateString = date?.format('MM/DD/YYYY') ?? null
    setStartDate(dateString)
  }
  const participantTypeClicked = (selectedParticipantType: string) => {
    // same button clicked
    if (selectedParticipantType == participantType) return
    //  new selection but have selected option
    // gives option to cancel
    if (levels.length || projects.length || users.length) {
      showConfirmationDialog({
        title: 'Warning',
        message:
          'You have already made selections. Participants can only be added by one group. Do you want to clear your selections?',
        cancelText: 'Cancel',
        confirmText: 'Continue',
        onCancel: function (): void {
          hideConfirmationDialog()
          return
        },
        onConfirm: async () => {
          setLevels([])
          setUsers([])
          setProjects([])
          setParticipantType(selectedParticipantType)
        },
      })
      return
    }
    // no selection participantType changed
    setParticipantType(selectedParticipantType)
  }

  const trakingGreaterThanDuration = (
    selectedDuration: string,
    selectedTrackingPeriod: string,
  ) => {
    const tpMonthly = selectedTrackingPeriod == ETrackingPeriod.MONTHLY
    const tpQuartely = selectedTrackingPeriod == ETrackingPeriod.QUARTERLY

    const weekly = selectedDuration == EGoalDuration.WEEKLY
    const monthly = selectedDuration == EGoalDuration.MONTHLY

    const bad1 = (weekly && tpMonthly) || (weekly && tpQuartely)
    const bad2 = monthly && tpQuartely

    return bad1 || bad2
  }

  const durationChanged = (selectedDuration: string) => {
    // const trackingPeriod = getValues('trackingPeriod')
    if (trakingGreaterThanDuration(selectedDuration, trackingPeriod)) {
      showConfirmationDialog({
        oneButton: true,
        title: 'Invalid Selection',
        message: 'Tracking period cannot be greater than the duration.',
        confirmText: 'Continue',
        cancelText: 'Continue',
        onCancel: function (): void {
          hideConfirmationDialog()
        },
        onConfirm: async () => {
          hideConfirmationDialog()
        },
      })
      return
    }

    setDuration(selectedDuration)
    setDurationMessage(getDurationMessage())
  }
  const trackingPeriodChanged = (event: any) => {
    const trackingPeriod = event.target.value
    if (trakingGreaterThanDuration(duration, trackingPeriod)) {
      showConfirmationDialog({
        oneButton: true,
        title: 'Invalid Selection',
        message: 'Tracking period cannot be greater than the duration.',
        confirmText: 'Continue',
        cancelText: 'Continue',
        onCancel: function (): void {
          hideConfirmationDialog()
        },
        onConfirm: async () => {
          hideConfirmationDialog()
        },
      })
      return
    }
    setTrackingPeriod(trackingPeriod)
    setDurationMessage(getDurationMessage())
  }

  useEffect(() => {
    if (id) {
      getGoal()
    } else {
      setWatchers([{ ...owner, unEditable: true }])
      setInitTrackingPeriod('Weekly')
      setStartDate(moment().format('YYYY-MM-DD'))
      setTrackingPeriod('Weekly')
      reset({
        // trackingPeriod: 'Weekly',
        goalCount: '5',
      })
    }
  }, [])
  const getInitialWatchers = () => {
    if (id) {
      return watchers
    }
    return [{ ...owner, unEditable: true }]
  }

  return (
    <>
      {goalStarted && (
        <PrimaryBanner
          sx={{ marginTop: '0px', marginBottom: '24px' }}
          message={
            'Note: This goal is in progress and some fields cannot be changed.'
          }
        />
      )}
      <InnerPageContainer
        innerPageLoading={goalLoading}
        innerPageError={goalError}
        sx={{ padding: '0', border: 'none', background: 'none' }}
      >
        {submissionError && (
          <ErrorContainer
            error={'Error'}
            textStyle={{ color: AppColors.gray700 }}
            sx={{ marginBottom: '24px' }}
          >
            {submissionError}
          </ErrorContainer>
        )}

        <form onSubmit={onSubmit} className='dont_style_formio'>
          {/* Goal & Control Details */}
          <FormSection title='Goal & Control Details'>
            <EdifyFormField>
              <EdifyFieldLabel label={'Name'} required />
              <Input
                autoFocus
                data-testid='GoalName'
                sx={{ width: '100%' }}
                disableUnderline
                placeholder={'Goal & Control Name'}
                {...register('name')}
                className={`form-control ${errors?.name ? 'error' : ''}`}
              />
              {errors?.name && (
                <FormErrorText>{errors.name.message}</FormErrorText>
              )}
            </EdifyFormField>
            <EdifyFormField>
              <EdifyFieldLabel label='DESCRIPTION' />
              <TextareaAutosize
                {...register('description', { required: true })}
                data-testid='Description'
                placeholder='What is the goal & control?'
                className={'form-control'}
                minRows={3}
              />
            </EdifyFormField>
          </FormSection>
          {/* Template */}
          <FormSection title='Template'>
            <EdifyFormField>
              <EdifyButtonGroup
                disabled={goalStarted}
                selectedButton={selectedTemplate}
                setSelectedButton={setSelectTemplate}
                buttonGroup={templates}
              />
            </EdifyFormField>
            {selectedTemplate == 'Form Completion' && (
              <EdifyFormField>
                <EdifyFieldLabel label='SELECT A FORM' required />
                <EdifySearchDropdown
                  disabled={goalStarted}
                  data-testid='FormsSearch'
                  pillListWidth={'100%'}
                  width={512}
                  onSelect={formSelected}
                  nameKey='title'
                  searchFunction={searchForms}
                  initialSelectedItems={forms}
                  validationError={formError}
                />
              </EdifyFormField>
            )}
          </FormSection>
          {/* Schedule */}
          <FormSection title='Schedule'>
            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label='CHOOSE A START DATE' />
              <EdifyDatePicker
                disabled={goalStarted}
                date={startDate}
                setDate={startDateChanged}
                sx={{ maxWidth: '512px' }}
              />
            </EdifyFormField>

            {/* <Grid container spacing={2} gap='16px'> */}
            <Grid container spacing={0} sx={{ p: 0, m: 0 }} gap='16px'>
              <Grid item m={6} xs={6} sx={{ p: 0, m: 0 }}>
                <EdifyFormField sx={{ flex: 1 }}>
                  <EdifyFieldLabel label='HOW MANY SUBMISSIONS' />
                  <Input
                    disabled={goalStarted}
                    disableUnderline
                    className={'form-control no-spinners center'}
                    type='number'
                    sx={{ padding: '16px 16px' }}
                    inputProps={{
                      inputProps: { min: 1 },
                    }}
                    {...register('goalCount')}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          disabled={goalStarted}
                          edge='end'
                          onClick={handleIncrement}
                        >
                          <Add
                            sx={{
                              color: goalStarted
                                ? AppColors.neutral950
                                : AppColors.gray950,
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                    startAdornment={
                      <InputAdornment position='start'>
                        <IconButton
                          disabled={goalStarted}
                          onClick={handleDecrement}
                          edge='start'
                        >
                          <Remove
                            sx={{
                              color: goalStarted
                                ? AppColors.neutral950
                                : AppColors.gray950,
                            }}
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors?.goalCount && (
                    <FormErrorText>{errors.goalCount.message}</FormErrorText>
                  )}
                </EdifyFormField>
              </Grid>
              <Grid item m={6} xs={6} sx={{ p: 0, m: 0 }}>
                <EdifyFormField>
                  <EdifyFieldLabel label='PER TRACKING PERIOD' />

                  <EdifySelect
                    disabled={goalStarted}
                    startAdornment={
                      <InputAdornment position='start'>
                        <IconButton onClick={handleDecrement} edge='start'>
                          <AccessAlarm sx={{ color: AppColors.gray25 }} />
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder='Select'
                    defaultValue={initTrackingPeriod}
                    value={trackingPeriod}
                    onChange={trackingPeriodChanged}
                    sx={{ marginRight: '24px' }}
                    width={'100%'}
                    dropDownItems={ETrackingArray}
                    renderMenuItem={(item: string) => (
                      <EdifyMenuItem key={item} value={item}>
                        {item}
                      </EdifyMenuItem>
                    )}
                  />
                  {errors?.trackingPeriod && (
                    <FormErrorText>
                      {errors.trackingPeriod.message}
                    </FormErrorText>
                  )}
                </EdifyFormField>
              </Grid>
            </Grid>

            <EdifyFormField>
              <EdifyFieldLabel label='GOAL AND CONTROL DURATION' />
              <EdifyButtonGroup
                disabled={goalStarted}
                selectedButton={duration}
                setSelectedButton={durationChanged}
                buttonGroup={durationButtonGroup}
              />
            </EdifyFormField>
            <PrimaryBanner message={getDurationMessage()} />
          </FormSection>

          {/* Reminders */}
          <FormSection title='Reminders'>
            <EdifyFormField>
              <EdifyFieldLabel
                label='Add Watcher'
                tooltipText='Watchers are involved members of the Goal & Control but are not participating in the challenge.'
              />
              <EdifySearchDropdown
                disabled={goalStarted}
                data-testid='WatcherSearch'
                pillListWidth={'100%'}
                width={512}
                multiSelect={true}
                nameKey='fullName'
                limit={8}
                onSelect={watcherSelected}
                searchFunction={searchUsers}
                initialSelectedItems={getInitialWatchers()}
                // initialSelectedItems={watchers}
              />
            </EdifyFormField>

            <EdifyFormField>
              <EdifyFieldLabel label='WATCHER REMINDERS' />

              <EdifyButtonGroup
                selectedButton={watchersReminder}
                setSelectedButton={setWatchersReminder}
                buttonGroup={reminderButtonGroup}
              />
            </EdifyFormField>

            <EdifyFormField>
              <EdifyFieldLabel label='PARTICIPANT REMINDERS' />
              <EdifyButtonGroup
                selectedButton={participantsReminder}
                setSelectedButton={setParticipantsReminder}
                buttonGroup={reminderButtonGroup}
              />
            </EdifyFormField>
          </FormSection>
          <FormSection title='Award and Recognition'>
            <EdifyFormField>
              <EdifyFieldLabel label='INCENTIVE' />
              <EdifyButtonGroup
                // disabled={goalStarted}
                // disable for now
                disabled={true}
                selectedButton={incentive}
                setSelectedButton={setIncentive}
                buttonGroup={incentiveButtonGroup}
              />
            </EdifyFormField>
            <ETypography font='SM' color='gray50' sx={{ marginTop: '24px' }}>
              * Goal & Control winners will show up on your Homepage when the
              goal has reached it’s end date and time.
            </ETypography>
          </FormSection>
          {/* PARTICIPANT */}
          <FormSection
            title='Add Participants'
            tooltipText='Participants are the users who take part in the Goal & Control challenge. Their participation is what gets tracked and they will be the ones who receive the benefits of successful completion.'
          >
            <EdifyFormField>
              <EdifyFieldLabel label='SELECT GROUPING' />
              <EdifyButtonGroup
                disabled={goalStarted}
                selectedButton={participantType}
                setSelectedButton={participantTypeClicked}
                buttonGroup={[`${levelName}`, `${projectName}`, 'Users']}
              />
            </EdifyFormField>

            {participantType == `${levelName}` && (
              <EdifyFormField>
                <EdifyFieldLabel label={`ADD ${levelName}`} />
                <EdifySearchDropdown
                  disabled={goalStarted}
                  data-testid='LevelsSearch'
                  defaultItems={allLevels.map((p) => {
                    return { id: p.id, name: p.name }
                  })}
                  multiSelect
                  pillListWidth={'100%'}
                  width={484}
                  onSelect={levelSelected}
                  searchFunction={searchLevels}
                  initialSelectedItems={levels}
                />
              </EdifyFormField>
            )}
            {participantType == `${projectName}` && (
              <EdifyFormField>
                <EdifyFieldLabel label={`ADD ${projectName}`} />
                <EdifySearchDropdown
                  disabled={goalStarted}
                  data-testid='LevelSearch'
                  defaultItems={allProjects.map((p) => {
                    return { id: p.id, name: p.name }
                  })}
                  pillListWidth={'100%'}
                  width={512}
                  multiSelect={true}
                  onSelect={projectSelected}
                  searchFunction={searchProjects}
                  initialSelectedItems={projects}
                />
              </EdifyFormField>
            )}
            {participantType == 'Users' && (
              <EdifyFormField>
                <EdifyFieldLabel label={'ADD USERS'} />
                <EdifySearchDropdown
                  disabled={goalStarted}
                  data-testid='LevelSearch'
                  pillListWidth={'100%'}
                  width={512}
                  multiSelect={true}
                  nameKey='fullName'
                  limit={8}
                  onSelect={userSelected}
                  searchFunction={usersSearch}
                  initialSelectedItems={users}
                />
              </EdifyFormField>
            )}
          </FormSection>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px',
            }}
          >
            <EdifyButton
              noBackground
              title='Cancel'
              onClick={() => navigate(-1)}
            />
            <Box>
              <EdifyButton
                type='submit'
                disabled={submissionLoading || Object.keys(errors).length > 0}
                data-testid={'SubmissionButton'}
                primary
                title={id ? 'Update Goal' : 'Create Goal'}
              />
            </Box>
          </Box>
        </form>
      </InnerPageContainer>
    </>
  )
}

// TODO make this in to a compoent
interface IBannerProps {
  message: string
  sx?: SxProps
  type?: 'primary' | 'warn' | 'error'
}
export const PrimaryBanner: React.FC<IBannerProps> = ({
  message,
  sx,
  type = 'primary',
}) => {
  const borderColors = {
    primary: AppColors.primary600,
    warn: AppColors.warning600,
    error: AppColors.d600,
  }
  const backgroundColors = {
    primary: AppColors.primary200,
    warn: AppColors.warning200,
    error: AppColors.d200,
  }

  return (
    <Box
      sx={{
        background: backgroundColors[type],
        padding: '8px 16px',
        border: `1px solid ${borderColors[type]}`,
        borderRadius: '10px',
        marginTop: '24px',

        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      <ErrorOutlineIcon   sx={{color:borderColors[type]}} />
      <ETypography color='gray50' font='SR' sx={{ flex: 1, marginLeft: '8px' }}>
        {message}
      </ETypography>
    </Box>
  )
}
