// ED-1386 https://edifyai.atlassian.net/browse/ED-1386
import React, { useContext, useEffect, useState } from 'react'
import useQuery from '../../../core/hooks/useQuery'

import {
  IGoalList,
  IGoalListItem,
} from '../../../domain/interfaces/IGoal'
import { showConfirmationDeleteMessage } from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import { Either, Failure } from '../../../core/core'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import {
  adminDeleteGoals,
  getAdminGoals,
  getGoalById,
} from '../../../domain/domain'
import { useParams } from 'react-router-dom'
import { ISearchResult } from '../../components/form/EdifySearch/EdifySearchDropdown'
import { DEFAULT_LIMIT } from '../../components/datagrid/EdifyDataGrid'


export interface GoalsPageViewModelProps {
  queryHelper: Record<string, any>
  // goals: IGoal[]
  goals: IGoalListItem[]
  isLoading: boolean
  totalCount: number
  goalsError: string | undefined
  error: string | undefined
  getGoals: () => void
  showDeleteConfirmation: (ids: string[]) => void
  loadMore: (paginationModel: GridPaginationModel) => void
  fetchGoals: (fetchSkip: number, query: Record<string, any>) => void
  sortClickedHandler: (sorter: GridSortModel) => void
  onGoalSearch: (result: ISearchResult) => void
}

const GoalPageContext = React.createContext<GoalsPageViewModelProps | null>(
  null,
)

export function useGoalsPageViewModel(): GoalsPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(GoalPageContext)!
}
interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const GoalsPageViewModel: React.FC<Props> = ({ children }) => {
  const { id } = useParams()

  const queryHelper = useQuery({
    ...(id ? { goalId: id } : {}),
    skip: 0,
    limit: DEFAULT_LIMIT,
  })
  const { query, getQueryStringFromQuery, setNew } = queryHelper
  const [goals, setGoals] = useState<IGoalListItem[]>([])
  const [goalsError, setGoalsError] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(0)

  const getGoals = async () => {
    setGoalsError(undefined)
    setIsLoading(true)
    setGoals([])
    fetchGoals(0, query)
  }

  const fetchGoals = async (fetchSkip: number, query: Record<string, any>) => {
    setGoalsError(undefined)
    setIsLoading(true)

    const newQuery = { ...query, skip: fetchSkip }

    const res: Either<Failure, IGoalList> = await getAdminGoals(
      getQueryStringFromQuery(newQuery),
    )
    setIsLoading(false)
    if (res.isLeft()) {
      setGoalsError(res.left?.message)
      return
    }
    setTotalCount(res.right?.total_count || 0)
    setGoals(res.right?.data as IGoalListItem[])
    setNew(newQuery)
  }

  const loadMore = (paginationModel: GridPaginationModel) => {
    const fetchSkip = paginationModel.page * paginationModel.pageSize
    fetchGoals(fetchSkip, query)
  }

  const deleteSelectedGoals = async (ids: string[]) => {

    const res = await adminDeleteGoals(ids)
    const goalString = ids.length == 1 ? 'Goal' : 'Goals'
    const goalStringLower = ids.length == 1 ? 'goal' : 'goals'
    if (res.isRight()) {
      SuccessToast({ title: `${goalString} removed successfully.` })
      fetchGoals(query.skip, query)
    } else {
      ErrorToast({ title: `Error removing ${goalStringLower}.` })
    }
  }

  const showDeleteConfirmation = async (ids: string[]) => {
    const confirmMessage = ids.length > 1 ? `${ids.length} goals` : '1 goal'

    showConfirmationDeleteMessage(confirmMessage, () =>
      deleteSelectedGoals(ids),
    )
  }

  const sortClickedHandler = (sorter: GridSortModel) => {
    const { field, sort } = sorter[0]
    const newQuery = {
      ...query,
      skip: 0,
      sortBy: field,
      sortOrder: sort?.toUpperCase(),
    }
    fetchGoals(0, newQuery)
  }

  // This might need to be changed (UX)..
  const onGoalSearch = async (goalSearchResult: ISearchResult) => {
    // check to see if it is already in the page
    const matchedGoal = goals.find((p) => p.id == goalSearchResult.id)
    if (matchedGoal) {
      setGoals([matchedGoal])
      setTotalCount(1)
      return
    }
    // if not grab from API
    if (!matchedGoal) {
      setIsLoading(true)
      setGoalsError(undefined)
      setIsLoading(false)
      const res = await getGoalById(goalSearchResult.id)
      if (res.right) {
        setGoals([res.right as IGoalListItem])
        setTotalCount(1)
      } else {
        ErrorToast({ title: 'Could not find goal' })
      }
    }
  }

  useEffect(() => {
    getGoals()
  }, [])

  return (
    <GoalPageContext.Provider
      value={{
        queryHelper,
        goals,
        isLoading,
        goalsError,
        fetchGoals,
        getGoals: getGoals,
        showDeleteConfirmation,
        loadMore,
        totalCount,
        sortClickedHandler,
        onGoalSearch,
        error: goalsError,
      }}
    >
      {children}
    </GoalPageContext.Provider>
  )
}
export default GoalPageContext
