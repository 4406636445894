import { Either, Failure } from '../../../core/core'
import { IUser, IUserList, IUsersDeleted } from '../../../domain/interfaces/IUser'
import { httpDelete, httpDeleteWithPayload, httpGet, httpGetAnon, httpPost, httpPut } from '../../data'

export async function getUsers(
  queryString: string,
): Promise<Either<Failure, IUserList>> {
  const res: Either<Failure, IUserList> = await httpGet(
    `/users/v2?${queryString}`,
    false,
  )

  return res
}

export async function getUserById(
  id: string,
): Promise<Either<Failure, IUser>> {
  const res: Either<Failure, IUser> = await httpGet(
    `/users/${id}`,
    true,
  )

  return res
}

export async function usersClientSearch(
  skip: number,
  limit?: number,
  key?: string,
): Promise<Either<Failure, IUserList>> {
  const res: Either<Failure, IUserList> = await httpGet(
    `/users/client/search?key=${key}&skip=${skip}&limit=${limit ?? 5}`,
    false,
  )

  return res
}

export async function createUser(
  email: string,
  name: string,
  roleIds: string[],
  projectIds: string[],
  levelIds: string[],
  phone?: string,
  pointOfContactId?: string | null,
): Promise<Either<Failure, IUser>> {
  const res: Either<Failure, IUser> = await httpPost(
    '/users',
    {
      email,
      fullName: name,
      roleIds,
      projectIds,
      levelIds,
      phone,
      pointOfContactId 
    },
    undefined, //no additional headers
    false, // direct data parsing is false
  )

  return res
}

export async function editUser(
  userId: string,
  email: string,
  name: string,

  roleIds: string[],
  projectIds: string[],
  levelIds: string[],
  phone?: string,
  pointOfContactId?: string | null,
): Promise<Either<Failure, IUser>> {
  const res: Either<Failure, IUser> = await httpPut(
    `/users/${userId}`,
    {
      email,
      fullName: name,
      roleIds,
      projectIds,
      levelIds,
      phone,
      pointOfContactId 
    },
    false, // direct data parsing is false
  )

  return res
}

export async function deleteUser(
  userId: string,
): Promise<Either<Failure, IUser>> {
  const res: Either<Failure, IUser> = await httpDelete(
    `/users/${userId}`,
    undefined, //no additional headers
  )

  return res
}

export async function deleteUsers(
  userIds: string[],
): Promise<Either<Failure, IUsersDeleted>> {
  const res: Either<Failure, IUsersDeleted> = await httpDeleteWithPayload(
    '/users', { userIds }
  )
  return res
}

export async function searchUsers(
  skip: number,
  limit?: number,
  key?: string,
): Promise<Either<Failure, IUserList>> {
  const urlStr = `/users/search?key=${key}&skip=${skip}&limit=${limit ?? 8}`
  const res: Either<Failure, IUserList> = await httpGet(urlStr
    ,
    false,
  )
  return res
}

export async function anonGetOneUser(
  orgId: string,
  userId: string,
): Promise<Either<Failure, IUser>> {
  const res: Either<Failure, IUser> = await httpGetAnon(
    `/anonymous/users/${userId}`,
    { orgId },
    true,
  )
  return res
}
