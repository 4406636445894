import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/page/PageContainer'
import { Box } from '@mui/material'

import {
  ProjectsPageViewModel,
  ProjectsPageViewModelProps,
  useProjectsPageViewModel,
} from '../projects/ProjectsViewModel'
import ProjectsDataGrid from '../projects/ProjectsDataGrid'
import { normalizeProjectsForDataGrid } from '../../components/datagrid/gridData/projects'
import {
  UserPageViewModel,
  UserPageViewModelProps,
  useUserPageViewModel,
} from './UserPageViewModel'
import { dateToMMMDYYYY } from '../../../core/core'
import { ROUTE_USERS } from '../users/UsersPage'
import { EdifyReadFormField } from '../../components/form/shared/EdifyReadFormField'

import { phoneInputFormatter } from '../../../core/utils/input-formatters/InputFormatters'
import {
  LevelsPageViewModel,
  LevelsPageViewModelProps,
  useLevelsPageViewModel,
} from '../levels/LevelsViewModel'
import LevelsDataGrid from '../levels/LevelsDataGrid'
import { normalizeLevelsForDataGrid } from '../../components/datagrid/gridData'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'

import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'
import CertificatesDataGrid from '../certificates/CertificatesDataGrid'
import {
  CertificatesPageViewModel,
  useCertificatesPageViewModel,
} from '../certificates/CertificatesViewModel'
import { showQRCodeMessage } from '../../components/dialogs/qr-code/QRCodeDialog'

import { useNavigate, useParams } from 'react-router-dom'
import { getCurrentPerson, getCurrentUser } from '../../../domain/domain'
import PageHeader from '../../components/page/PageHeader'
import { EdifyButton } from '../../components/buttons'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import UserPersonForm from './UserPersonForm'
import UserManagementForm from './UserManagementForm'

import { TrashIcon } from '../../../core/components/icons/TrashIcon'
import { AppColors } from '../../Theme'

interface IUserPageProps {
  isEdit: boolean
}
import QrCodeIcon from '@mui/icons-material/QrCode'
import { formatSnakeCase } from '../../../core/utils/text-formaters/TextFormatters'
import OrgReset from './OrgReset'
import { ProfileHeaderV2 } from '../../components/profile/ProfileHeaderV2'
import ProfileContainer from '../../components/profile/ProfileContainer'
import { ProfileHeader } from '../../components/profile/ProfileHeader'
import { ETrackingArray } from '../goal/GoalForm'
import { ETypography } from '../../components/fonts/ETypography'
import NotificationsReadOnly from '../../components/forms/PersonProfileForm/NotifcactionReadOnly'

const _UserPage: React.FC<IUserPageProps> = ({ isEdit }) => {
  const vm: UserPageViewModelProps = useUserPageViewModel()
  const projectVM: ProjectsPageViewModelProps = useProjectsPageViewModel()
  const { org } = useOrganizationProvider()
  const levelVM: LevelsPageViewModelProps = useLevelsPageViewModel()
  const certificateVM = useCertificatesPageViewModel()
  const { getHierarchyName } = useOrganizationProvider()
  const [count, setCount] = useState(-1)
  const roles = vm.user?.roles ? vm.user.roles[0] : { name: 'No Role' }
  const canEdit = usePermission(ERole.UserEdit)

  const currentUserId = getCurrentUser()?.id ?? ''
  const [userName, setUserName] = useState('Loading...')
  const navigate = useNavigate()

  const shareTrainingCertifications = org?.isUserQrCodeEnabled ?? false

  const isProfileView = currentUserId == vm.user?.id
  const [uploadImage, setUploadImage] = useState<string | null>(null)

  const showQRCode = () => {
    const url = `${window.location.origin}/anonymous/users/${
      vm.user?.id
    }?orgId=${org!.id}`
    showQRCodeMessage(url, 'user')
  }

  const getProjectRows = () => {
    return projectVM.projects
      ? normalizeProjectsForDataGrid(projectVM.projects)
      : []
  }

  // TODO should get levels from levels view model in case
  // of pagination, BE needs to be updated
  const getLevelRows = () => {
    if (!vm.user) return []
    if (count == -1) {
      setCount(vm.user.levels ? vm.user.levels.length : 0)
    }
    return vm.user.levels ? normalizeLevelsForDataGrid(vm.user.levels) : []
  }
  const person = getCurrentPerson()
  const getTitle = () => {
    if (vm.user) {
      const fullName = isProfileView ? person?.fullName : vm.user?.fullName
      const imageURL = isProfileView ? person?.imageURL : vm.user?.imageURL
      return (
        <>
          <ProfileHeaderV2
            fullName={fullName}
            imageURL={imageURL}
            canEdit={isProfileView && isEdit}
            imageUploaded={(url) => setUploadImage(url)}
          />
        </>
      )
    }

    return 'Loading...'
  }
  const showEditButton = () => {
    if (currentUserId == vm.user?.id) return true
    return canEdit
  }
  useEffect(() => {
    setUserName(vm.user?.fullName ?? '')
  }, [vm.user])
  const renderViewMode = () => {
    return (
      <>
        <InnerPageContainer
          sx={{
            padding: '0px',
          }}
          innerPageError={vm.userError}
          innerPageLoading={vm.userLoading}
        >
          <ProfileHeader
            canEdit={showEditButton()}
            canDelete={canEdit}
            profile={vm.user}
            imageURL={vm.user?.imageURL}
            deleteProfile={vm.deleteUser}
            isEdit={isEdit}
            // setIsEdit={() => 1}
            handleClick={() => navigate(`${ROUTE_USERS}/${vm.user?.id}/edit`)}
            fullName={vm.user?.fullName}
            role={vm.user?.roles[0].name}
          />
          <InnerPageContainer
            sx={{ width: '100%', padding: '0px 24px', border: 'none' }}
          >
            <Box style={{ display: 'flex', flex: 1 }}>
              <EdifyReadFormField
                sx={{ flex: 1 }}
                label={'PHONE'}
                value={
                  vm.user?.phone ? phoneInputFormatter(vm.user?.phone) : 'NA'
                }
              />
              <EdifyReadFormField
                sx={{ flex: 1 }}
                label={'EMAIL'}
                value={vm.user?.email ?? 'NA'}
              />
            </Box>
            <Box>
              <Box style={{ display: 'flex', flex: 1 }}>
                <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'ORGANIZATION'}
                  value={vm.user?.org?.name ?? 'None'}
                />
                <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'DATE ADDED'}
                  value={
                    vm.user?.createdAt ? dateToMMMDYYYY(vm.user.createdAt) : ''
                  }
                />
              </Box>
            </Box>
            <Box sx={{ width: '100%', paddingBottom: '24px' }}>
              <Box style={{ display: 'flex', flex: 1 }}>
                <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'POINT OF CONTACT'}
                  value={vm.user?.pointOfContactName ?? 'NA'}
                />

                {/* <EdifyReadFormField
                  sx={{ flex: 1 }}
                  label={'Role'}
                  value={formatSnakeCase(vm.user?.roles[0].name ?? 'NA')}
                /> */}
              </Box>
            </Box>
          </InnerPageContainer>
        </InnerPageContainer>

        {isProfileView && (
          <>
            <Box sx={{ marginBottom: '24px' }} />
            <InnerPageContainer>
              <NotificationsReadOnly
                smsChecked={person?.smsNotifEnabled ?? false}
                emailChecked={person?.emailNotifEnabled ?? false}
              />
            </InnerPageContainer>
          </>
        )}

        <Box sx={{ marginBottom: '24px' }} />
        <CertificatesDataGrid
          vm={certificateVM}
          title={'Training and Certificates'}
          checkboxSelection={true}
        />
        <Box sx={{ marginBottom: '24px' }} />

        <ProjectsDataGrid
          vm={projectVM}
          error={projectVM.error}
          title={getHierarchyName(0)}
          rows={getProjectRows()}
          checkboxSelection={false}
        />
        <Box sx={{ marginBottom: '24px' }} />
        <LevelsDataGrid
          vm={levelVM}
          error={levelVM.levelsError}
          title={getHierarchyName(1)}
          rows={getLevelRows()}
          checkboxSelection={false}
          totalCount={count}
        />
        <OrgReset />
      </>
    )
  }
  const renderForms = () => {
    if (isProfileView) {
      return (
        <>
          <InnerPageContainer sx={{ background: 'white', padding: '0' }}>
            <ProfileHeader
              canEdit={canEdit}
              profile={vm.user}
              imageURL={vm.user?.imageURL}
              deleteProfile={vm.deleteUser}
              isEdit={isEdit}
              // setIsEdit={() => 1}
              handleClick={() => navigate(`${ROUTE_USERS}/${vm.user?.id}/edit`)}
              fullName={vm.user?.fullName}
              role={vm.user?.roles[0].name}
              imageUploaded={(url) => setUploadImage(url)}
              loadedProfile={!vm.userLoading}
            />
            {isProfileView && (
              <UserPersonForm
                sx={{ border: 'none' }}
                setUserName={setUserName}
                uploadImage={uploadImage}
              />
            )}
          </InnerPageContainer>
          {canEdit && <UserManagementForm />}
        </>
      )
    }
    return (
      <>
        <InnerPageContainer sx={{ background: 'white', padding: '0' }}>
          <ProfileHeader
            canEdit={canEdit}
            profile={vm.user}
            imageURL={vm.user?.imageURL}
            deleteProfile={vm.deleteUser}
            isEdit={isEdit}
            // setIsEdit={() => 1}
            handleClick={() => navigate(`${ROUTE_USERS}/${vm.user?.id}/edit`)}
            fullName={vm.user?.fullName}
            role={vm.user?.roles[0].name}
          />

          <UserManagementForm
            sx={{ border: 'none', padding: '24px' }}
            showName
          />
        </InnerPageContainer>
      </>
    )
  }

  return (
    <PageContainer
      // title={getTitle()}
      mainPageLoading={vm.userLoading}
      breadCrumbSX={{ marginBottom: '0px' }}
      topActions={
        shareTrainingCertifications
          ? [
            {
              title: 'Show QR Code',
              callback: showQRCode,
            },
          ]
          : []
      }
      breadCrumbs={[
        { title: 'Organization', to: '' },

        {
          title: 'Users',
          to: `${ROUTE_USERS}`,
        },
        {
          title: userName as string,
          to: `${ROUTE_USERS}/${vm.user?.id}`,
        },
        ...(isEdit
          ? [
            {
              title: 'Edit',
              to: `${ROUTE_USERS}/${vm.user?.id}/edit`,
            },
          ]
          : []),
      ]}
    >
      {isEdit && renderForms()}
      {!isEdit && renderViewMode()}
      {/* <UserPermissions roles={roles} role={roles.name} /> */}
      {isEdit && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '24px',
          }}
        >
          <EdifyButton
            noBackground
            title={'Back'}
            onClick={() => {
              navigate(-1)
            }}
          />
        </Box>
      )}
    </PageContainer>
  )
}

export const UserPageTemp: React.FC<IUserPageProps> = (props) => {
  return (
    <LevelsPageViewModel>
      <UserPageViewModel>
        <CertificatesPageViewModel>
          <ProjectsPageViewModel>
            <_UserPage {...props} />
          </ProjectsPageViewModel>
        </CertificatesPageViewModel>
      </UserPageViewModel>
    </LevelsPageViewModel>
  )
}
