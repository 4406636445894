import React from 'react'
import { SubmissionsPageViewModelProps } from './SubmissionsViewModel'
import { EdifyButton } from '../../components/buttons'
import { Box, CircularProgress, Grid, Input } from '@mui/material'
import FilterButtonGroup from '../../components/buttons/filter-button-group/FilterButtonGroup'
import { SubmissionQueryField } from '../../../domain/domain'
import EdifyDateRange from '../../components/form/EdifyDateRange/EdifyDateRange'
import { MagnifyGlassIcon } from '../../../core/components/icons/MagnifyGlassIcon'
import { EdifySelect } from '../../components/form'
import { AppFonts } from '../../Theme'
import EdifyMenuItem from '../../components/form/EdifyMenuItem'
import { normalizeDynamicFiltersForTableView } from '../../../core/utils/formio-formatters/FormIOFormatter'
import { IFilter } from '../../../domain/interfaces/IFormComponent'
import GlobalSearch from '../../components/form/EdifySearch/GlobalSearch'

import { usePublicOrganizationProvider } from '../../../providers/PublicOrganizationProvider'

interface ISubmissionPageFilterProps {
  vm: SubmissionsPageViewModelProps
  showDrafts?: boolean
}

// TODO: make this more dynamic for future button groups
const FILTER_OPTIONS = ['All', 'My Submissions', 'Drafts']

const DynamicSubmissionFilter: React.FC<ISubmissionPageFilterProps> = ({
  vm,
  showDrafts = true,
}) => {
  const { level1Name, level0Name } = usePublicOrganizationProvider()
  const [loaded, setLoaded] = React.useState(true)

  const [filters, setFilters] = React.useState<IFilter[]>([])
  const [showFilters, setShowFilter] = React.useState<boolean>(false)
  const [isGlobalClearing, setIsGlobalClearing] = React.useState<boolean>(false)
  const { query, set } = vm.queryHelper

  const checkReplace = (label: string) => {
    if (label === '{{data.dataSource.level1Name}}') {
      return level1Name
    }
    if (label === '{{data.dataSource.projectName}}') {
      return level0Name
    }
  }

  // TODO: make this more dynamic for future button groups
  const handleFilterButtonClicked = (button: string) => {
    const newSubmissionQueryField: SubmissionQueryField = { ...query }
    delete newSubmissionQueryField.owned
    delete newSubmissionQueryField.drafts
    switch (button) {
      case FILTER_OPTIONS[1]:
        newSubmissionQueryField.owned = true
        break
      case FILTER_OPTIONS[2]:
        newSubmissionQueryField.drafts = true
        break
    }
    vm.fetchSubmissions(0, newSubmissionQueryField)
  }

  const onGlobalSearchSelect = (selectedId: string, selectedValue: string) => {
    const newQuery = { ...query }
    delete newQuery.searchKey
    vm.fetchSubmissions(0, {
      ...newQuery,
      filterId: selectedId,
      filterValue: selectedValue,
    })
  }

  const onGlobalSearchKey = (key: string) => {
    vm.fetchSubmissions(0, {
      ...query,
      searchKey: key,
    })
  }

  // for the X in the global search
  const onGlobalSearchClear = async () => {
    const newQuery = { ...query }
    delete newQuery.searchKey
    delete newQuery.filterId
    delete newQuery.filterValue
    await vm.fetchSubmissions(0, {
      ...newQuery,
    })
  }

  const onGlobalFilterClear = (filterId: string) => {
    const newFilters = filters.filter(
      (filter) => filter.formFilterId !== filterId,
    )
    vm.fetchSubmissions(0, {
      ...query,
      // find filter and remove it
      filters: newFilters,
    })
    setFilters(newFilters)
  }

  // EVENT HANDLER FOR BUTTON GROUP
  const getActiveButton = () => {
    if (query['owned']) return FILTER_OPTIONS[1]
    if (query['drafts']) return FILTER_OPTIONS[2]
    return FILTER_OPTIONS[0]
  }

  const updateFilters = (
    keyValue: string,
    id: string,
    type: string,
    label?: string,
  ) => {
    const existingFilterIndex = filters.findIndex(
      (filter) => filter.formFilterId === id,
    )

    if (keyValue) {
      // Update existing filter or add new one
      if (existingFilterIndex !== -1) {
        const updatedFilters = [...filters]
        updatedFilters[existingFilterIndex].keyValue = keyValue
        return updatedFilters
      } else {
        return [
          ...filters,
          { formFilterId: id, keyValue, type, formFilterValue: label },
        ]
      }
    } else {
      // Remove filter if keyValue is empty
      return filters.filter((filter) => filter.formFilterId !== id)
    }
  }

  const handleInputChange = (
    keyValue: string,
    id: string,
    type: string,
    label?: string,
  ) => {
    const newFilters = updateFilters(keyValue, id, type, label)
    setFilters(newFilters)
    vm.fetchSubmissions(0, {
      ...query,
      filters: newFilters,
    })
  }

  const getFilterValue = (id: string) => {
    const value = filters.find((f) => f.formFilterId === id)?.keyValue ?? ''
    return value
  }

  // const handleSelectChange = (value: string, key: string) => {
  //   vm.fetchSubmissions(0, {
  //     ...query,
  //     [key]: value,
  //   })
  // }
  // clear all filters
  const clearFilters = async () => {
    setLoaded(false)
    setFilters([])
    setIsGlobalClearing(true)
    await vm.clearFilters(false)
    setIsGlobalClearing(false)
    const newQuery = { ...query }
    delete newQuery.searchKey
    delete newQuery.filterId
    delete newQuery.filterValue
    delete newQuery.owned
    delete newQuery.drafts
    newQuery.filters = []
    await vm.fetchSubmissions(0, {
      ...newQuery,
    })
    setLoaded(true)
  }
  const normalizedFilters = normalizeDynamicFiltersForTableView(
    vm.form,
    vm.formFilters,
  )
  const hasFilters = normalizedFilters && normalizedFilters.length > 0
  if (vm.isLoading) return <CircularProgress />
  const l1Name = level1Name
  const projectName = level0Name
  return (
    <>
      <Box
        data-testid={'SubmissionsPage'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {/* <pre>{JSON.stringify(query, null, 2)}</pre> */}
        {showDrafts ? (
          <FilterButtonGroup
            onButtonClicked={handleFilterButtonClicked}
            activeButton={getActiveButton()}
            setActiveButton={(value) => set('activeButton', value)}
            buttons={FILTER_OPTIONS}
          />
        ) : (
          <Box></Box>
        )}

        <Box style={{ display: 'flex' }}>
          <GlobalSearch
            globalClear={isGlobalClearing}
            sx={{
              '& .MuiOutlinedInput-root': {
                width: '300px',
                padding: '6px 0px 6px 9px !important',
                paddingRight: '9px !important',
              },
            }}
            entities='users,projects,levels,locations'
            // placeholder={`Author, ${l1Name}, ${projectName}`}
            placeholder={'Search'}
            defaultValue=''
            showBadge
            l1Name={l1Name}
            projectName={projectName}
            onGlobalSearchSelect={onGlobalSearchSelect}
            onGlobalSearchClear={onGlobalSearchClear}
            onGlobalSearchWithText={onGlobalSearchKey}
          />
          {/* <EdifyDateRange dateRange={dateRange || ''} onChange={dateChanged} /> */}
        </Box>
      </Box>

      {!loaded && showFilters && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {loaded && showFilters && (
        <Grid
          container
          spacing='24px'
          sx={{
            padding: '24px 0px 0px 0px',
          }}
        >
          {normalizedFilters &&
            normalizedFilters.map((header: any) => {
              const { type, key, label, values, url, loadData, id } = header
              if (url) {
                return (
                  <Grid item key={key} sm={6} md={4} lg={3}>
                    <GlobalSearch
                      placeholder={checkReplace(url) || label}
                      defaultValue=''
                      entities={url}
                      onGlobalSearchSelect={(selectId: string) =>
                        handleInputChange(selectId, id, type, 'value here')
                      }
                      onGlobalSearchClear={() => onGlobalFilterClear(id)}
                      onGlobalSearchWithText={onGlobalSearchKey}
                    />
                  </Grid>
                )
              }
              if (['date', 'datetime'].includes(type)) {
                return (
                  <Grid item key={key} sm={6} md={4} lg={3}>
                    <EdifyDateRange
                      placeHolder={label}
                      dateRange={getFilterValue(header.id) || ''}
                      onChange={(string) =>
                        handleInputChange(string, header.id, header.type)
                      }
                      width={'auto'}
                    />
                  </Grid>
                )
              }
              if (
                [
                  'textfield',
                  'email',
                  'phoneNumber',
                  'url',
                  'currency',
                  'tags',
                ].includes(type)
              ) {
                return (
                  <Grid item key={key} sm={6} md={4} lg={3}>
                    <Input
                      placeholder={label}
                      sx={{ ...AppFonts.interBase, height: '50px' }}
                      className='form-control'
                      disableUnderline
                      // value={query[header.key] || ''}
                      onChange={(e) =>
                        handleInputChange(
                          e.target.value,
                          header.id,
                          header.type,
                        )
                      }
                      startAdornment={
                        <MagnifyGlassIcon sx={{ marginRight: '6px' }} />
                      }
                    />
                  </Grid>
                )
              }
              if (
                [
                  'checkbox',
                  'selectboxes',
                  'select',
                  'radio',
                  'signature',
                ].includes(type)
              ) {
                return (
                  <Grid item key={header.key} sm={6} md={4} lg={3}>
                    <EdifySelect
                      showPlaceHolder
                      key={header.key}
                      placeholder={header.label}
                      sx={{
                        ...AppFonts.interBase,
                        minWidth: '100%',
                        maxWidth: '100%',
                        textOverflow: 'ellipsis',
                      }}
                      // className='form-control'
                      showClear={
                        filters.find((f) => f.formFilterId === header.id)
                          ? true
                          : false
                      }
                      onClear={() =>
                        handleInputChange('', header.id, header.type)
                      }
                      disableUnderline
                      onChange={(e) =>
                        handleInputChange(
                          e.target.value,
                          header.id,
                          header.type,
                        )
                      }
                      dropDownItems={getDropDownItems(type, label, values)}
                      renderMenuItem={(item) => {
                        return (
                          <EdifyMenuItem key={item.value} value={item.value}>
                            {item.name}
                          </EdifyMenuItem>
                        )
                      }}
                      value={getFilterValue(header.id)}
                    />
                  </Grid>
                )
              }
            })}
        </Grid>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          margin: '12px 0',
        }}
      >
        <EdifyButton
          onClick={() => clearFilters()}
          title='Clear Filters'
          primary
          noBackground
        />

        <EdifyButton
          hide={!hasFilters}
          onClick={() => setShowFilter(!showFilters)}
          title={showFilters ? 'Hide Filters' : 'More Filters'}
          primary
          noBackground
        />
      </Box>
    </>
  )
}

const getDropDownItems = (type: string, label: string, values: any[] = []) => {
  if (type === 'signature') {
    return [
      { value: 'YES', name: 'Signed' },
      { value: 'NO', name: 'Not ' + 'Signed' },
    ]
  }
  if (type === 'checkbox') {
    return [
      { value: '', name: 'All' },
      { value: true, name: 'Checked' },
      { value: false, name: 'Not ' + 'Checked' },
    ]
  }
  if (['selectboxes', 'select', 'radio'].includes(type)) {
    return values.map((value) => {
      return { value: value.value, name: value.label }
    })
  }
  return []
}

export default DynamicSubmissionFilter
