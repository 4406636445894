import React, { useContext, useEffect, useState } from 'react'
import {
  deleteDocument,
  deleteDocuments,
  getDocuments,
} from '../../../domain/usecases/documents/DocumentsUsecases'
import { IDocument } from '../../../domain/interfaces/IDocument'
import { useParams } from 'react-router-dom'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { showConfirmationDeleteMessage } from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import { set } from 'date-fns'
import useQuery from '../../../core/hooks/useQuery'
import { DEFAULT_LIMIT } from '../users/UsersViewModel'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'

export interface IDocumentPageViewModelProps {
  loading: boolean
  error: string | undefined
  documents: IDocument[]
  title: string
  showDeleteConfirmation: (ids: string[]) => void
  loadDocs: () => void
  folderId?: string | undefined
  sortClickedHandler: (sorter: GridSortModel) => void
  totalCount: number
  id?: string
  setSearch: (searchKey: string) => void
  loadMore: (paginationModel: GridPaginationModel) => void
}

const DocumentPageContext =
  React.createContext<IDocumentPageViewModelProps | null>(null)

export function useDocumentPageViewModel(): IDocumentPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(DocumentPageContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const DocumentPageViewModel: React.FC<Props> = ({ children }) => {
  const { id } = useParams()
  const { documentLinks } = useOrganizationProvider()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [documents, setDocuments] = useState<IDocument[]>([])
  const [title, setTitle] = useState<string>('Documents')
  const [folderId, setFolderId] = useState<string | undefined>(undefined)
  const [totalCount, setTotalCount] = useState<number>(0)

  const queryHelper = useQuery({
    ...(id ? { folderId: id } : {}),
    skip: 0,
    limit: DEFAULT_LIMIT,
  })

  const loadMore = (paginationModel: GridPaginationModel) => {
    const fetchSkip = paginationModel.page * paginationModel.pageSize
    getOrgDocuments(fetchSkip, query)
  }

  const { query, getQueryStringFromQuery, setNew, defaultQuery } = queryHelper
  const getOrgDocuments = async (
    fetchSkip: number,
    query: Record<string, any>,
  ) => {
    setLoading(true)
    setError(undefined)

    const newQuery = { ...query, skip: fetchSkip }
    const res = await getDocuments(getQueryStringFromQuery(newQuery))
    setLoading(false)
    if (res.isLeft()) {
      setError(res.left?.message ?? 'Error loading document.')
      return
    }
    setDocuments(res.right?.data ?? [])
    setTotalCount(res.right?.total_count || 0)
    setNew(newQuery)
  }
  const showDeleteConfirmation = async (ids: string[]) => {
    const confirmMessage =
      ids.length > 1 ? `${ids.length} documents` : '1 document'

    showConfirmationDeleteMessage(confirmMessage, async () => {
      const res = await deleteDocuments(ids)
      if (res.isRight()) {
        getOrgDocuments(0, defaultQuery)
        SuccessToast({ title: `Successfully deleted ${confirmMessage}` })
        return
      }
      ErrorToast({ title: 'Failed to delete documents' })
    })
  }

  const sortClickedHandler = (sorter: GridSortModel) => {
    const { field, sort } = sorter[0]
    const newQuery = {
      ...query,
      skip: 0,
      sortBy: field,
      sortOrder: sort?.toUpperCase(),
    }
    getOrgDocuments(0, newQuery)
  }
  const setSearch = (searchKey: string) => {
    const newQuery = {
      ...query,
      skip: 0,
      searchKey,
    }
    getOrgDocuments(0, newQuery)
  }

  const loadDocs = async () => {
    setError(undefined)
    setLoading(true)
    setDocuments([])
    getOrgDocuments(0, query)
  }

  useEffect(() => {
    if (id) {
      const link = documentLinks?.find((d) => d.id == id)
      setTitle(link?.name ?? 'Documents')
      setFolderId(id)
    }
    loadDocs()
  }, [id])

  return (
    <DocumentPageContext.Provider
      value={{
        error,
        loading,
        documents,
        title,
        showDeleteConfirmation,
        loadDocs,
        folderId,
        sortClickedHandler,
        totalCount,
        id,
        setSearch,
        loadMore
      }}
    >
      {children}
    </DocumentPageContext.Provider>
  )
}
