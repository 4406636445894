import React, { useState } from 'react'
import PageContainer from '../../components/page/PageContainer'
import {
  RoleSettingsViewModel,
} from './RoleSettingsViewModel'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { IRole } from '../../../domain/interfaces/IRole'
import { IForm, IRolePermission } from '../../../domain/interfaces/IForm'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'

export const ROUTE_ROLES = '/roles'

export const Roles: React.FC = () => {
  return (
    <RoleSettingsViewModel>
      <_Page />
    </RoleSettingsViewModel>
  )
}

export const _Page: React.FC = () => {
  const { roles } = useOrganizationProvider()

  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  // const canCreate = usePermission(ERole.FormCreate)

  return (
    <PageContainer
      title={'Manage Roles'}
      breadCrumbs={[
        { title: 'Settings', to: '' },
        { title: 'Roles', to: ROUTE_ROLES },
      ]}
    >
      <Box sx={{ width: '100%', marginBottom: '48px', background: 'white' }}>
        <Box
          sx={{ borderBottom: 1, borderColor: 'divider', background: 'white' }}
        >
          <Tabs value={value} onChange={handleChange}>
            {roles?.map((role, i) => (
              <Tab
                key={`role-${role.id}`}
                label={formatSnakeCase(role.name)}
                {...a11yProps(i)}
              />
            ))}
          </Tabs>
        </Box>
        {roles?.map((role, i) => (
          <CustomTabPanel index={i} value={value} key={`role-${role.id}`}>
            <PermissionList roles={role} />
          </CustomTabPanel>
        ))}
      </Box>
    </PageContainer>
  )
}

interface IRoleSettingProps {
  role: IRolePermission
  formId: string
}

const RoleFormSetting: React.FC<IRoleSettingProps> = ({ role, formId }) => {
  return (
    <Box
      sx={{
        border: `1px solid ${AppColors.neutral600}`,
        marginBottom: '12px',
        marginTop: '12px',
        padding: '8px 12px',
        borderRadius: '4px',
      }}
    >
      <ETypography sx={{ marginBottom: '12px' }} font='LSB' color='gray700'>
        {formatSnakeCase(role.roleName)}
      </ETypography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}
      >
        <RadioButtonsGroup
          id={role.id}
          initRoles={{ create: role.create, edit: role.edit, view: role.view }}
          roleId={role.roleId}
          formId={formId}
          groupName='View'
          value={role.view}
          roleName={role.roleName}
          groupKey='view'
          values={[
            { label: 'All', value: 'full' },
            { label: 'Own', value: 'limited' },
            { label: 'None', value: 'false' },
          ]}
        />
        <RadioButtonsGroup
          id={role.id}
          roleId={role.roleId}
          roleName={role.roleName}
          initRoles={{ create: role.create, edit: role.edit, view: role.view }}
          formId={formId}
          groupName='Create'
          value={role.create}
          groupKey='create'
          values={[
            { label: 'Yes', value: 'full' },
            { label: 'No', value: 'false' },
          ]}
        />
        <RadioButtonsGroup
          id={role.id}
          roleId={role.roleId}
          roleName={role.roleName}
          initRoles={{ create: role.create, edit: role.edit, view: role.view }}
          formId={formId}
          groupName='Edit'
          groupKey='edit'
          value={role.edit}
          values={[
            { label: 'All', value: 'full' },
            { label: 'Own', value: 'limited' },
            { label: 'None', value: 'false' },
          ]}
        />
      </Box>
    </Box>
  )
}

interface IFormRoleSettingProps {
  form: IForm
  roles: IRole[]
  setForms: (forms: IForm[]) => void
  roleName?: string
}
export const FormRoleSetting: React.FC<IFormRoleSettingProps> = ({
  form,
  roles,
  setForms,
  roleName,
}) => {
  const sortedRoles = [
    ...form.rolePermissions!.sort((a, b) => {
      return a.roleName > b.roleName ? 1 : -1
    }),
  ]
  if (roleName) {
    const role = form.rolePermissions!.find(
      (r) => r.roleName == roleName,
    ) as IRolePermission

    return (
      <InnerPageContainer headerTitle={form.title}>
        <RoleFormSetting formId={form.id} role={role} />
      </InnerPageContainer>
    )
  }
  return (
    <InnerPageContainer
      headerTitle={'Role Permissions'}
      collapsed
      collapsible
    >
      {sortedRoles.map((r, i) => (
        <RoleFormSetting formId={form.id} key={`${r.id}-${i}`} role={r} />
      ))}
    </InnerPageContainer>
  )
}

import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { Box } from '@mui/material'
import { ETypography } from '../../components/fonts/ETypography'

interface IRadioButtonsGroupProps {
  values: { value: string; label: string }[]
  groupName: string
  groupKey: string
  id: string
  roleId: string
  value: string
  formId: string
  initRoles: { view: string; edit: string; create: string }
  roleName: string
}

const RadioButtonsGroup: React.FC<IRadioButtonsGroupProps> = ({
  values,
  groupName,
  groupKey,
  id,
  formId,
  roleId,
  roleName,
  initRoles,
  value: initValue,
}) => {
  const { formUpdated } = useOrganizationProvider()
  const { setForm } = useSubmissionsPageViewModel()
  const [loading, setLoading] = useState(false)
  const [value1, setValue1] = useState(initValue)
  const handleClick = async (e: any) => {
    let res
    setLoading(true)
    try {
      if (!id) {
        res = await createFormRolePermission({
          roleId: roleId,
          formId: formId,
          ...initRoles,
          [groupKey]: e.target.value,
        })
      } else {
        res = await updateFormRolePermission(id, {
          [groupKey]: e.target.value,
        })
      }
      if (res.isRight()) {
        setValue1(e.target.value)
        // need to update form in state.
        const formRes = await getForm(formId)

        if (formRes.right) {
          // update form in form settings page
          setForm(formRes.right)
          // update forms in organization provider
          formUpdated(formRes.right)
        }
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      ErrorToast({ title: 'Error Updating Role' })
    }
  }
  return (
    <FormControl>
      <ETypography font='MM' color='gray700'>
        {loading ? '...Updating' : groupName}
      </ETypography>
      <RadioGroup
        row
        aria-labelledby='demo-row-radio-buttons-group-label'
        name='row-radio-buttons-group'
      >
        {values.map((v) => (
          <FormControlLabel
            disabled={loading}
            key={v.value}
            {...v}
            control={
              <Radio checked={v.value == value1} onClick={handleClick} />
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import PermissionList from '../../components/permissions/PermissionList'
import { formatSnakeCase } from '../../../core/utils/text-formaters/TextFormatters'
import {
  createFormRolePermission,
  updateFormRolePermission,
} from '../../../data/data'
import { ErrorToast } from '../../../core/utils/toast-notifications/ToastNotifications'
import { AppColors } from '../../Theme'
import { getCurrentOrg, getForm } from '../../../domain/domain'
import { useSubmissionsPageViewModel } from '../submissions/SubmissionsViewModel'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ background: 'white' }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
