import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import {
  Either,
  Failure,
  Failure500,
  FailureAuthentication,
  Left,
  Right,
} from '../../core/core'
import { getFromLocalStorage } from './LocalSource'

declare global {
  interface Window {
    env?: any
  }
}

import { REACT_APP_SERVER_API } from '../../config'



const httpServer: AxiosInstance = axios.create({
  baseURL: REACT_APP_SERVER_API,
})

let onTokenExpired: (() => void) | undefined

export function addOnTokenExpiredListener(callback: () => void) {
  onTokenExpired = callback
}

export function removeOnTokenExpiredListener() {
  onTokenExpired = undefined
}

export function getInitialToken(): string {
  const tokenCookies: string[] = document.cookie
    .split(';')
    .filter((cookie: string) => {
      return cookie.trim().startsWith('token=')
    })
  return tokenCookies.length > 0
    ? tokenCookies[0].trim().substring('token='.length)
    : localStorage.getItem('authToken') ?? ''
}

let authToken: string = getInitialToken()

/**
 * Set authorization token.
 *
 * @param token
 */
export function setAuthToken(token: string) {
  localStorage.setItem('authToken', token)
  authToken = token
  document.cookie = 'token=' + authToken
}

/**
 * Get request headers.
 *
 * @returns Headers.
 */
function getHeaders(additionalHeaders?: object): AxiosRequestConfig {
  const storedActiveOrg = getFromLocalStorage('currentOrgId')
  const org = storedActiveOrg != null ? storedActiveOrg : ''

  return {
    headers: {
      authorization: authToken,
      orgId: org,
      ...additionalHeaders,
    },
  }
}

/**
 * GET request.
 *
 * @param path
 * @param directDataParsing<br />
 *  - true = Data returned will be [res.data.data]. (default)
 *  - false = Date returned will be [res.data], which is the raw data.
 * @returns Data T.
 */
export async function httpGet<T = object>(
  path: string,
  directDataParsing = true,
): Promise<Either<Failure, T>> {
  return httpRequest<T>(
    () => httpServer.get(path, getHeaders()),
    directDataParsing,
  )
}

/**
 * GET request.
 *
 * @param path
 * @param directDataParsing<br />
 *  - true = Data returned will be [res.data.data]. (default)
 *  - false = Date returned will be [res.data], which is the raw data.
 * @returns Data T.
 */
export async function httpGetAnon<T = object>(
  path: string,
  extraHeaders:object,
  directDataParsing = true,
): Promise<Either<Failure, T>> {
  return httpRequest<T>(
    () => httpServer.get(path, getHeaders(extraHeaders)),
    directDataParsing,
  )
}

/**
 * GET request for a full url.
 *
 * @param path
 * @param directDataParsing<br />
 *  - true = Data returned will be [res.data.data]. 
 *  - false = Date returned will be [res.data], which is the raw data.(default)
 * @returns Data T.
 */
export async function httpGetFormIO<IForm>(
  path: string,
  directDataParsing = false,
): Promise<Either<Failure, IForm>> {
  const httpServer: AxiosInstance = axios.create(
    { baseURL: process.env.REACT_APP_FORMIO_PROJECT_URL }
  )
  return httpRequest<IForm>(
    () => httpServer.get(path),
    directDataParsing,
  )
}

/**
 * GET blob request.
 *
 * @param path
 * @param directDataParsing<br />
 *  - true = Data returned will be [res.data.data]. (default)
 *  - false = Date returned will be [res.data], which is the raw data.
 * @returns Data T.
 */
export async function httpGetBlob<T = object>(
  path: string,
  directDataParsing = false,
): Promise<Either<Failure, T>> {
  return httpRequest<T>(
    () => httpServer.get(path, { responseType: 'blob', ...getHeaders() }),
    directDataParsing,
  )
}

/**
 * GET blob request.
 *
 * @param path
 * @param directDataParsing<br />
 *  - true = Data returned will be [res.data.data]. (default)
 *  - false = Date returned will be [res.data], which is the raw data.
 * @returns Data T.
 */
export async function httPostBlob<T = object>(
  path: string,
  data: object,
  header?: object,
  directDataParsing = false,
): Promise<Either<Failure, T>> {
  return httpRequest<T>(
    () => httpServer.post(path, data, { responseType: 'blob', ...getHeaders(header) }),
    directDataParsing,
  )
}

/**
 * POST request.
 *
 * @param path
 * @param data
 * @param directDataParsing<br />
 *  - true = Data returned will be [res.data.data]. (default)
 *  - false = Date returned will be [res.data], which is the raw data.
 * @returns Data T.
 */
export async function httpPost<T = object>(
  path: string,
  data: object,
  header?: object,
  directDataParsing = true,
): Promise<Either<Failure, T>> {
  return httpRequest<T>(
    () => httpServer.post(path, data, getHeaders(header)),
    directDataParsing,
  )
}

/**
 * PUT request.
 *
 * @param path
 * @param data
 * @param directDataParsing<br />
 *  - true = Data returned will be [res.data.data]. (default)
 *  - false = Date returned will be [res.data], which is the raw data.
 * @returns Data T.
 */
export async function httpPut<T = object>(
  path: string,
  data: object,
  directDataParsing = true,
): Promise<Either<Failure, T>> {
  return httpRequest<T>(
    () => httpServer.put(path, data, getHeaders()),
    directDataParsing,
  )
}

/**
 * DELETE request.
 * 
 * @param path
 * @param directDataParsing<br />
 *  - true = Data returned will be [res.data.data]. (default)
 *  - false = Date returned will be [res.data], which is the raw data.
 * @returns Data T.
 */
export async function httpDelete<T = object>(
  path: string,
  directDataParsing = true,
): Promise<Either<Failure, T>> {
  return httpRequest<T>(
    () => httpServer.delete(path, getHeaders()),
    directDataParsing,
  )
}

/**
 * DELETE request  that expects a payload
 *
 * @param path
 * @param data
 * @param directDataParsing<br />
 *  - true = Data returned will be [res.data.data]. (default)
 *  - false = Date returned will be [res.data], which is the raw data.
 * @returns Data T.
 */
export async function httpDeleteWithPayload<T = object>(
  path: string,
  data: object,
  directDataParsing = true,
): Promise<Either<Failure, T>> {
  return httpRequest<T>(
    () => httpServer.delete(path, {
      ...getHeaders(),
      data: data
    }),
    directDataParsing,
  )
}

/**
 * HTTP request handler.
 *
 * @param executor Actual call of HTTP request type should be done by this callback.
 * @returns Data T.
 */
async function httpRequest<T = object>(
  executor: () => Promise<AxiosResponse<{ data: T }, null>>,
  directDataParsing = true,
): Promise<Either<Failure, T>> {
  let either: Either<Failure, T>


  try {
    const res: AxiosResponse<{ data: T }, null> = await executor()

    if (res.headers['authorization']) {
      setAuthToken(res.headers['authorization'])
    }

    either = new Right<Failure, T>(
      directDataParsing ? res.data.data : (res.data as T),
    )

  } catch (error) {
    either = new Left<Failure, T>(errorToFailureHandler(error))

    if (
      either.left?.statusCode &&
      either.left?.statusCode >= 400 &&
      either.left?.statusCode < 500
    ) {
      if (
        // TODO : Uniform status error messages to avoid this
        (either.left.statusCode == 401 &&
          either.left.message == 'Wrong authentication token') ||
        // DO WE WANT 404 to logout?
        // (either.left.statusCode == 404 &&
        //   either.left.message == 'Authentication token expired or missing') 

        (either.left.statusCode == 401 &&
          either.left.message ==
          'Authentication error! Please try again later')
        // || (either.left.statusCode == 404 &&
        //   either.left.message ==
        //   'Authentication error! Please try again later')
      ) {
        onTokenExpired?.()
      }

      // TODO : This is for refresh token
      // const storedUser = localStorage.getItem('user')
      // if (!storedUser) break
      // only refresh token if user is not null

      // const user = JSON.parse(storedUser)
      // await refreshFormIoToken(user?._id)
    }

  }


  return either
}

/**
 * Get Failure object based from error.
 *
 * @param error
 * @returns Failure.
 */
function errorToFailureHandler(error: unknown): Failure {
  let failure: Failure

  if (error instanceof AxiosError) {
    switch (error.response?.status) {
      case 500:
        failure = new Failure500()
        break
      case 401:
        failure = new FailureAuthentication(401)
        break
      case 403:
        failure = new FailureAuthentication(403)
        break
      // case 400:
      //   failure = new FailureAuthentication(400)
      //   break
      // case 404:
      //   failure = new FailureAuthentication(404)
      //   break
      case 440:
        failure = new FailureAuthentication(440)
        break
      // TODO: Handle other status errors.
      default:
        failure = new Failure(
          error.response?.data?.message ?? 'Error',
          error.response?.status,
          error.response?.data?.data?.errors ?? {},
        )
    }
  } else {
    failure = new Failure()
  }

  return failure
}
