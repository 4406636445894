/* eslint-disable quotes */
import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'

import { ROUTE_SUBMISSIONS } from '../submissions/SubmissionsPage'

import { AppColors, AppFonts } from '../../Theme'
import ImageDialog from '../../components/dialogs/ImageDialog'
import { useI18nProvider } from '../../../providers/InternationalizationProvider'
import useFormLoader from '../../../core/hooks/useFormLoader'
import useSubmissionLoader from '../../../core/hooks/useSubmission'
import useFormio, { IUseFormioReturn } from '../../../core/hooks/useFormio'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import PageContainer from '../../components/page/PageContainer'
import { formatTimestampMDYYYYathmmaz, formatTimestampYYYYMMDDhmmaz, getTimeZone } from '../../../core/core'
import { ROUTE_FORMS } from '../forms/FormsPage'

import MojoNewFormRender from './MojoNewFormRender'

import moment from 'moment-timezone'
import i18n from '../../../i18n'

export function getSignStatus(meeting: any) {
  if (!meeting || !meeting.isThisATraining || meeting.isThisATraining == 'no') {
    return { canSign: true, timeRemaining: null }
  }

  // Parse the start time in the local timezone (formio converts the time to local timezone)
  let startDateTime = moment(
    moment(meeting.date).format('YYYY-MM-DD') +
    ' ' +
    moment(meeting.time).format('HH:mm:ss'),
    'YYYY-MM-DD HH:mm:ss',

  )

  // Get the current time in the local timezone
  const currentDateTime = moment()

  // Calculate the end time (8 hours after start)
  let endDateTime = moment(startDateTime).add(8, 'hours')

  //correction for going past midnight. moment.add() did not increment the day
  if (startDateTime.isAfter(endDateTime)) {
    startDateTime = moment(startDateTime).add(1, 'days')
    endDateTime = moment(startDateTime).add(8, 'hours')
  }

  if (currentDateTime.isBefore(startDateTime)) {
    return {
      canSign: false,
      timeRemaining:
        "Signing is currently disabled because the meeting hasn't started yet. It will be available for up to 8 hours after the meeting begins.",
      bannerType: 'primary'
    }
  } else if (currentDateTime.isBetween(startDateTime, endDateTime)) {
    const duration = moment.duration(endDateTime.diff(currentDateTime))
    const hours = Math.floor(duration.asHours())
    const minutes = Math.floor(duration.asMinutes()) % 60

    return {
      canSign: true,
      timeRemaining: `${hours} hours ${minutes} minutes remaining`,
      startDateTime,
      bannerType: 'primary'
    }
  } else {
    const windowMessage = `This form is open for signing from ${formatTimestampYYYYMMDDhmmaz(startDateTime.toLocaleString())} to ${formatTimestampYYYYMMDDhmmaz(endDateTime.toLocaleString())} ${getTimeZone()}`
    return {
      canSign: false,
      // eslint-disable-next-line quotes
      timeRemaining:
        windowMessage,
      // 'The 8-hour signature window for this meeting instance has closed.',
      startDateTime,
      bannerType: 'error'
    }
  }
}

const ViewSubmission: React.FC = () => {
  const [open, setOpen] = React.useState(false)
  const [url, setUrl] = React.useState('')
  const [lastActiveTab, setLastActiveTab] = React.useState<number>(0)
  const [uiLanguage, setUiLanguage] = React.useState('English')
  const [originalLanguage, setOriginalLanguage] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }
  // const canEdit = canEditSubmission(
  //   vm.form,
  //   vm.submission?.userId ?? '-1',
  //   user,
  // )

  ///NEW
  // SUBMISSION
  const getPageTitle = () => {
    if (formLoader.loadFormError) return 'Error Occurred Loading Form'
    const title = form?.title
    if (pathname.includes('/new')) return `New ${title}`
    if (pathname.includes('/edit')) return `Update ${title}`
    return title
  }

  // const renderRightItems = () => {
  //   if (formLoader.loadFormError || submissionLoader.loadSubmissionError) return
  //   if (!pathname.includes('/new') && !pathname.includes('/edit')) {
  //     const timezone = getTimeZone()

  //     return (
  //       <Typography
  //         sx={{
  //           ...AppFonts.headlineXSDesktopSemibold,
  //           color: AppColors.gray25,
  //         }}
  //       >
  //         {`Submitted at ${formatTimestampMDYYYYathmmaz(
  //           submissionLoader.submission?.createdAt,
  //         )} ${timezone}`}
  //       </Typography>
  //     )
  //   }
  // }

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }


  const handleLanguageClick = () => {
    if (submission?.originalData != null && submission.originalLanguageCode != null) {
      if (uiLanguage == 'English') {
        setUiLanguage(submission.originalLanguage ?? '')
        setOriginalLanguage(false)
      } else {
        setUiLanguage('English')
        setOriginalLanguage(true)
      }
    }
  }

  const renderRightItems = () => {
    if (submission?.originalData != null && submission.originalLanguageCode != null) {
      return (
        <div onClick={handleLanguageClick} style={{ cursor: 'pointer' }}>
          <Typography
            sx={{
              ...AppFonts.headlineXXSDesktopSemibold,
              color: AppColors.primary600,
            }}
          >
            View {capitalizeFirstLetter(uiLanguage)} Version
          </Typography>
        </div>
      )
    }
  }

  const renderSubmissionDateText = () => {
    if (formLoader.loadFormError || submissionLoader.loadSubmissionError) return ''
    if (!pathname.includes('/new') && !pathname.includes('/edit')) {
      const timezone = getTimeZone()

      return `Submitted at ${formatTimestampMDYYYYathmmaz(
        submissionLoader.submission?.createdAt,
      )} ${timezone}`
    }
    return ''
  }

  //VIEW SUBMISSION
  const { formCategory } = useParams()
  const location = useLocation()
  const { pathname } = location

  ////Params state
  const { orgI18nLoaded, options } = useI18nProvider()
  let { formId } = useParams()

  const [activeFormId, setActiveFormId] = React.useState<string>(formId ?? '')

  const { id: submissionId, correctiveActionFormId } = useParams()
  const isCorrectiveAction = !formId ? true : false
  if (isCorrectiveAction) {
    formId = correctiveActionFormId
  }
  // const { id: formId } = vm.form
  const { org, hierarchies } = useOrganizationProvider()
  const orgId = org ? org.id : 'ERROR NO ORGID'
  // const submissionId = vm.submission?.id ?? undefined

  const formLoader = useFormLoader(formId!, {
    orgId,
    isAnon: false,
    orgI18nLoaded,
  })
  const submissionLoader = useSubmissionLoader(submissionId)
  const fio: IUseFormioReturn = useFormio(
    formId!,
    submissionLoader.submission,
    {
      form: formLoader.form,
      orgId: orgId,
      isAnon: false,
    },
  )

  const { form } = formLoader
  const { submission } = submissionLoader

  React.useEffect(() => {
    // Only run this effect when the submission object is loaded
    if (submission) {
      if (submission.originalLanguageCode === i18n.language) {
        setUiLanguage('English')
        setOriginalLanguage(true)
      } else {
        setUiLanguage(submission.originalLanguage ?? '')
        setOriginalLanguage(false)
      }


    }
  }, [submission])
  const renderBreadCrumbs = () => {
    let title = 'View'
    let path = `/submission/${submission?.id}`
    if (pathname.includes('/new')) {
      title = 'New'
      path = '/submission/new'
    }
    const breadCrumbs = [
      { title: 'All Forms', to: `${ROUTE_FORMS}/all` },
      {
        title: form?.title ?? 'form',
        to: `/${formCategory}${ROUTE_SUBMISSIONS}/${form?.id}`,
      },
      {
        title: 'View',
        to: `/${formCategory}${ROUTE_SUBMISSIONS}/${form?.id}${path}`,
      },
    ]
    return breadCrumbs
  }
  // TODO hook up to SigneeList when BE done. leave here for now for testing.

  const canSign = () => {
    if (!submission?.data) return false
    const { scheduler } = submission.data
    if (scheduler) {
      return getSignStatus(scheduler)
    }
    return true
  }

  // Reloading page for now on connected forms to fix cache issue
  // useEffect(() => {
  //   Formio.clearCache()
  //   return () => {
  //     localStorage.removeItem('submissionPayload')
  //   }
  // },[])
  return (
    <PageContainer
      title={getPageTitle()}
      renderRightItems={renderRightItems}
      subtitle={renderSubmissionDateText()}
      breadCrumbs={renderBreadCrumbs()}
      // mainPageError={vm.error || vm.submissionError}
      mainPageLoading={
        submissionLoader.submissionLoading || formLoader.formLoading
      }
    >
      <MojoNewFormRender
        mode='VIEW'
        submissionId={undefined}
        orgId={orgId}
        formId={formId!}
        fio={fio}
        formLoader={formLoader}
        submissionLoader={submissionLoader}
        options={options}
        orgI18nLoaded={orgI18nLoaded}
        isAnon={false}
        readOnly={true}
        lastActiveTab={lastActiveTab}
        setLastActiveTab={setLastActiveTab}
        activeFormId={activeFormId}
        setActiveFormId={setActiveFormId}
        originalLanguage={originalLanguage}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '24px',
        }}
      >
        <ImageDialog url={url} open={open} onClose={handleClose} />
      </Box>
    </PageContainer>
  )
}
export default ViewSubmission
