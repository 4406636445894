import React, { useEffect } from 'react'
import { IForm } from '../../../domain/interfaces/IForm'
import { ISubmission } from '../../../domain/interfaces/ISubmission'
import { REACT_APP_FORMIO_PROJECT_URL } from '../../../config'
import { Form } from '@formio/react'
import { CircularProgressLoading } from '../../components/loading-progress/CircularProgressLoading'
import ErrorContainer from '../../components/error/ErrorContainer'
import { IUseFormioReturn } from '../../../core/hooks/useFormio'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { IFormSubmitter } from '../../../core/hooks'
import { ETypography } from '../../components/fonts/ETypography'

export interface IMojoFormioRenderer1Props {
  formId: string
  orgId: string
  form: IForm | undefined
  // submission: ISubmission
  isAnon: boolean
  // isDraft: boolean
  // submissionFormatted: boolean
  submissionId?: string
  options: any
  onFormChange: (data: any, changed: any) => void
  disableSubmit: boolean
  setFormIOForm: React.Dispatch<React.SetStateAction<any>>
  formioForm: any // replace 'any' with the 'formioForm'
  sendSubmission: (isDraft: boolean, onSuccess?: () => void) => Promise<void>
  successFullySubmitted: boolean
  formSubmissionError?: string | undefined
  isSubmitting: boolean
  saveDraft: () => Promise<void>
  saveSubmission: (onSuccess?: () => void) => Promise<void>
  // IFormLoader
  formReadOnly?: IForm | undefined
  loadFormError?: string | undefined
  reloadForm?: () => void
  formLoading: boolean
  // ISubmission Loader
  submission: ISubmission | undefined
  loadSubmissionError: string | undefined
  reloadSubmission: (subId?: string) => void
  submissionLoading: boolean
  submissionFormatted: boolean
  fio?: IUseFormioReturn
  onSubmitSuccess?: () => void
  noContainer?: boolean
  readOnly?: boolean
  originalLanguage?: boolean
  lastActiveTab?: number
  setLastActiveTab?: React.Dispatch<React.SetStateAction<number>>
  mode: 'VIEW' | 'EDIT' | 'CREATE'
  formSubmitter?: IFormSubmitter
  setActiveFormId?: React.Dispatch<React.SetStateAction<string | undefined>>
}

const MojoFormioRenderer1: React.FC<IMojoFormioRenderer1Props> = ({
  form,
  formId,
  formReadOnly,
  submission,
  originalLanguage,
  // orgI18nLoaded,
  options,
  loadFormError,
  formLoading,
  loadSubmissionError,
  submissionLoading,
  noContainer = false,
  readOnly = false,
  ...rest
}) => {
  let imgUploads: NodeListOf<HTMLAnchorElement>
  const { onFormChange, setFormIOForm } = rest
  const addClickListener = () => {
    imgUploads = document.querySelectorAll(
      '.formio-component-file .fileSelector',
    )

    imgUploads.forEach((imgUpload) => {
      imgUpload.addEventListener('click', handleImgUpload)
    })
  }
  const handleImgUpload = (event: any) => {
    if (event.target.tagName == 'A') return
    const fileBrowseLink = document.querySelector(
      '.formio-component-file .fileSelector',
    )

    if (fileBrowseLink && fileBrowseLink.contains(event.target)) {
      // Trigger a click event on the found <a> tag
      const link = fileBrowseLink.querySelector('a')
      if (link) {
        link.click()
      }
    }
  }

  useEffect(() => {
    return () => {
      if (imgUploads) {
        imgUploads.forEach((imgUpload) => {
          imgUpload.removeEventListener('click', handleImgUpload)
        })
      }
    }
  }, [])

  if (formLoading || submissionLoading) return <CircularProgressLoading />

  if (loadFormError) {
    return <ErrorContainer>Error Loading Form: {loadFormError} </ErrorContainer>
  }
  if (loadSubmissionError) {
    if (loadSubmissionError == 'Authentication error! Please try again later') {
      return (
        <ETypography font='MM' color='gray700'>
          You are only allowed to view your own submission
        </ETypography>
      )
    }
    return (
      <ErrorContainer>
        Error Loading Submission: {loadSubmissionError}
      </ErrorContainer>
    )
  }
  if (!form) {
    return <ErrorContainer>No Form </ErrorContainer>
  }
  if (!submission) {
    return <ErrorContainer>No Submission </ErrorContainer>
  }

  const getForm = () => {
    const originalSubmission = { ...submission }
    if (originalLanguage) {
      originalSubmission.data = submission.originalData
    }
    return (
      <>
        <Form
          key={formId}
          url={`${REACT_APP_FORMIO_PROJECT_URL}/${form.path}`}
          form={readOnly ? formReadOnly ?? form : form}
          formReady={setFormIOForm}
          submission={originalLanguage ? originalSubmission : submission}
          onChange={onFormChange}
          options={{ ...options, readOnly: options.readOnly ? true : false }}
          onRender={addClickListener}
        />
      </>
    )
  }

  if (noContainer) {
    return getForm()
  }

  return (
    <>
      <InnerPageContainer headerTitle={form.title} sx={{ padding: '24px' }}>
        {getForm()}
      </InnerPageContainer>
    </>
  )
}

export default MojoFormioRenderer1
