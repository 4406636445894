import { Either, Failure } from '../../../core/core'

import { ICategory, ICategoryList, ICreateCategory, ICreateFormRolePermission, IForm, IRolePermission, IUpdateFormPermission } from '../../../domain/interfaces/IForm'
import { ICreateForm, ICreateFormHeader, IFormFilter, IFormHeader } from '../../../domain/interfaces/IFormHeader'
import { IFormList } from '../../../domain/interfaces/IFormList'
import {
  ICreateSubmission,
  ISubmission,
  ISubmissionList,
  ISubmissionPDFLink,
} from '../../../domain/interfaces/ISubmission'
import { httpDelete, httpGet, httpGetAnon, httpGetBlob, httpPost, httpPut } from '../../data'


const formsBaseUrl = '/forms'
const submissionsBaseUrl = '/submissions'
const formCategoriesURL = '/formCategories'
const formRolePermissions = '/formRolePermissions'


export async function getAllForms(
  skip: number,
  limit?: number,
  hasCategory = false
): Promise<Either<Failure, IFormList>> {
  const url = hasCategory ? `${formsBaseUrl}/?hasCategory=true` : `${formsBaseUrl}?skip=${skip}&limit=${limit ?? 5}`
  const res: Either<Failure, IFormList> = await httpGet(
    url,
    false,
  )
  return res
}

export async function formsGetFromQuery(queryString: string): Promise<Either<Failure, IFormList>> {

  const res: Either<Failure, IFormList> = await httpGet(
    `${formsBaseUrl}?${queryString}`, false
  )
  return res
}

export async function getOneForm(
  id: string,
): Promise<Either<Failure, IForm>> {
  const res: Either<Failure, IForm> = await httpGet(
    `${formsBaseUrl}/${id}`,
    true,
  )
  return res
}
export async function anonGetOneForm(
  id: string,
  orgId: string
): Promise<Either<Failure, IForm>> {
  const res: Either<Failure, IForm> = await httpGetAnon(
    `/anonymous${formsBaseUrl}/${id}`,
    { orgId },
    true,
  )
  return res
}

/**
 * @deprecated Use {@link getAllForms}.
 */
export async function getAllFormsByLoginAccountId(
  id: string,
  skip: number,
  limit?: number,
): Promise<Either<Failure, IFormList>> {
  const res: Either<Failure, IFormList> = await httpGet(
    `/formsByLoginAccountId/${id}?skip=${skip}&limit=${limit ?? 5}`,
    false,
  )
  return res
}

export async function searchForms(
  formName: string,
): Promise<Either<Failure, IFormList>> {
  const res: Either<Failure, IFormList> = await httpGet(
    `${formsBaseUrl}/search?key=${formName}`,
    false,
  )
  return res
}

export async function getAllSubmissions(
  formId: string,
  skip: number,
  limit?: number,
): Promise<Either<Failure, ISubmissionList>> {
  const res: Either<Failure, ISubmissionList> = await httpGet(
    `${submissionsBaseUrl}?formId=${formId}&skip=${skip}&limit=${limit ?? 5}`,
    false,
  )
  return res
}


export async function getAllSubmissionsWithQuery(formId: string,
  queryString: string): Promise<Either<Failure, ISubmissionList>> {

  const res: Either<Failure, ISubmissionList> = await httpGet(
    `${submissionsBaseUrl}?formId=${formId}${queryString}`,
    false,
  )
  return res
}

export async function getAllSubmissionsWithQueryObject(formId: string,
  queryObject: any): Promise<Either<Failure, ISubmissionList>> {

  const res: Either<Failure, ISubmissionList> = await httpPost(
    `${submissionsBaseUrl}/withFilters`,
    { ...queryObject, formId },
    {},
    false,
  )
  return res
}

/**
 * @deprecated Use {@link getAllSubmissions}.
 */
export async function getAllSubmissionsByLoginAccountId(
  formId: string,
  id: string,
  skip: number,
  limit?: number,
): Promise<Either<Failure, ISubmissionList>> {
  const res: Either<Failure, ISubmissionList> = await httpGet(
    `${submissionsBaseUrl}?id=${id}&formId=${formId}&skip=${skip}&limit=${limit ?? 5
    }`,
    false,
  )

  return res
}

export async function searchSubmissions(
  submission: string,
  skip: number,
  limit?: number,
): Promise<Either<Failure, ISubmissionList>> {
  const res: Either<Failure, ISubmissionList> = await httpGet<ISubmissionList>(
    `${submissionsBaseUrl}/search?key=${submission}&skip=${skip}&limit=${limit ?? 5
    }`,
    false,
  )
  return res
}

export async function submissionCreate(
  data: ICreateSubmission,
): Promise<Either<Failure, ISubmission>> {
  const res: Either<Failure, ISubmission> = await httpPost(
    `${submissionsBaseUrl}`,
    data,
  )
  return res
}

export async function anonSubmissionCreate(
  data: ICreateSubmission,
  orgId: string,
): Promise<Either<Failure, ISubmission>> {
  const res: Either<Failure, ISubmission> = await httpPost(
    `/anonymous${submissionsBaseUrl}`,
    data,
    { orgId }
  )
  return res
}

export async function putSubmission(
  submissionId: string,
  data: Record<string, any>,
): Promise<Either<Failure, ISubmission>> {
  const res: Either<Failure, ISubmission> = await httpPut(
    `${submissionsBaseUrl}/${submissionId}`,
    data,
  )
  return res
}

export async function getSubmissionById(
  submissionId: string,
): Promise<Either<Failure, ISubmission>> {
  const res: Either<Failure, ISubmission> = await httpGet(
    `${submissionsBaseUrl}/${submissionId}`,
  )
  return res
}

export async function deleteSubmissionById(
  submissionId: string,
): Promise<Either<Failure, ISubmission>> {
  const res: Either<Failure, ISubmission> = await httpDelete(
    `${submissionsBaseUrl}/${submissionId}`,
  )
  return res
}

export async function sendForm(
  param: {
    personEmail?: string
    personPhone?: string
  },
  userId: string,
  formId?: string,
): Promise<Either<Failure, boolean>> {
  const data = {
    senderId: userId,
    receiver:
      param?.personEmail !== undefined
        ? { email: param?.personEmail }
        : { phone: param?.personPhone },
    formId: formId,
  }

  const res: Either<Failure, boolean> = await httpPost('/sendForm', data)

  return res
}

export async function anonGetOneSubmission(
  orgId: string,
  formId: string,
  subId: string,
): Promise<Either<Failure, ISubmission>> {
  const res: Either<Failure, ISubmission> = await httpGetAnon(
    `/anonymous/submissions?orgId=${orgId}&formId=${formId}&submissionId=${subId}`,
    { orgId },
    true,
  )
  return res
}



export async function getSubmissionPDFLink(
  submissionId: string,
): Promise<Either<Failure, ISubmissionPDFLink>> {
  const res: Either<Failure, ISubmissionPDFLink> = await httpGet(
    `${submissionsBaseUrl}/generatePDFLink/${submissionId}`,
  )
  return res
}


export async function getPDFZipFile(
  ids: string,
): Promise<Either<Failure, Blob>> {
  const res: Either<Failure, Blob> = await httpGetBlob(
    `${submissionsBaseUrl}/generatePDF/V2?ids=${ids}`,
  )
  return res
}

export async function toggleFavoriteForm(
  id: string,
  isFavorite: boolean,
): Promise<Either<Failure, IForm>> {
  let res: Either<Failure, IForm>
  if (isFavorite) {
    res = await httpDelete(
      `${formsBaseUrl}/removeFromFavorites/${id}`,
    )
  } else {
    res = await httpPost(
      `${formsBaseUrl}/addToFavorites/${id}`,
      {},
    )
  }
  return res
}

export async function putAdminFormUpdate(
  formId: string,
  data: Record<string, any>,
): Promise<Either<Failure, IForm>> {
  const res: Either<Failure, IForm> = await httpPut(
    `${formsBaseUrl}/${formId}`,
    data,
  )
  return res
}

export async function postFormHeader(
  data: ICreateFormHeader,
): Promise<Either<Failure, IFormHeader>> {
  const res: Either<Failure, IFormHeader> = await httpPost(
    'formHeaders',
    data,
  )
  return res
}

export async function deleteFormHeader(
  formHeaderId: string,
): Promise<Either<Failure, IFormHeader>> {
  const res: Either<Failure, IFormHeader> = await httpDelete(
    `formHeaders/${formHeaderId}`,
  )
  return res
}


export async function deleteFormFilter(
  formFilterId: string,
): Promise<Either<Failure, IFormFilter>> {
  const res: Either<Failure, IFormFilter> = await httpDelete(
    `formFilters/${formFilterId}`,
  )
  return res
}

export async function createFormioForm(
  data: ICreateForm
): Promise<Either<Failure, IForm>> {
  const res: Either<Failure, IForm> = await httpPost(
    `${formsBaseUrl}`, data
  )
  return res
}


export async function formFiltersGet(
  formId: string,
): Promise<Either<Failure, IFormFilter[]>> {
  const res: Either<Failure, IFormFilter[]> = await httpGet(`formFilters?formId=${formId}`, true)
  return res
}


export async function postFormFilter(
  data: IFormFilter,
): Promise<Either<Failure, IFormFilter>> {
  const res: Either<Failure, IFormFilter> = await httpPost(
    'formFilters',
    data,
  )
  return res
}

// export async function correctiveActionsGet(
//   ids: string,
// ): Promise<Either<Failure, Blob>> {
//   const res: Either<Failure, Blob> = await httpGetBlob(
//     `${submissionsBaseUrl}/generatePDF/?ids=${ids}`,
//   )
//   return res
// }

// Categories
export async function getAllFormCategories(
  skip?: number,
  limit?: number,
): Promise<Either<Failure, ICategoryList>> {
  const res: Either<Failure, ICategoryList> = await httpGet(
    `${formCategoriesURL}?skip=${skip ?? 0}&limit=${limit ?? 100}`,
    false,
  )
  return res
}

export async function createFormCategory(
  data: ICreateCategory
): Promise<Either<Failure, ICategory>> {
  const res: Either<Failure, ICategory> = await httpPost(
    `${formCategoriesURL}`,
    data,
  )
  return res
}

export async function updateFormCategory(
  categoryId: string,
  data: ICreateCategory,
): Promise<Either<Failure, ICategory>> {
  const res: Either<Failure, ICategory> = await httpPut(
    `${formCategoriesURL}/${categoryId}`,
    data,
  )
  return res
}

export async function deleteFormCategory(
  categoryId: string,

): Promise<Either<Failure, ICategory>> {
  const res: Either<Failure, ICategory> = await httpDelete(
    `${formCategoriesURL}/${categoryId}`,
  )
  return res
}

export async function updateFormRolePermission(
  id: string,
  data: IUpdateFormPermission,
): Promise<Either<Failure, IRolePermission>> {
  const res: Either<Failure, IRolePermission> = await httpPut(
    `${formRolePermissions}/${id}`,
    data,
    true
  )
  return res
}

export async function createFormRolePermission(
  data: ICreateFormRolePermission,
): Promise<Either<Failure, IRolePermission>> {
  const res: Either<Failure, IRolePermission> = await httpPost(
    `${formRolePermissions}`,
    data,
  )
  return res
}


