import React from 'react'
import { ESubTypographyProps, ETypography } from '../fonts/ETypography'

const FormErrorText: React.FC<ESubTypographyProps> = ({
  children,
  font = 'SR',
  color = 'danger600',
  sx,
}) => {
  function removeLastCharIfDot(s:any) {
    if(typeof s !== 'string') return s
    if (s.endsWith('.')) {
      return s.slice(0, -1)
    } else {
      return s
    }
  }
  return (
    <ETypography
      data-testid='FormErrorText'
      font={font}
      color={color}
      sx={{
        marginTop: '16px',
        ...sx,
      }}
    >
      {removeLastCharIfDot(children)}!
    </ETypography>
  )
}
export default FormErrorText
