import React from 'react'
import { Avatar, Box, SxProps, Typography } from '@mui/material'
import { AppColors, AppFonts } from '../../Theme'
import { Circle } from '@mui/icons-material'
import { LaunchIcon } from '../../../core/components/icons/LaunchIcon'
import { INotification } from '../../../domain/interfaces/INotification'
import { dateToMMMDYYYYHHMMA } from '../../../core/core'

interface INotificationProps extends INotification {
  notificationClicked: (id: string) => void
}
const NotificationTile: React.FC<INotificationProps> = ({
  id,
  body,
  status,
  type,
  link,
  notificationClicked,
  createdAt,
  orgId,
}) => {
  const read = status === 'read'
  const circleColor = read ? AppColors.neutral600 : AppColors.primary600
  return (
    // TODO: get propr 'unnotified' status once backend is change
    <StyledBox
      primary={status == 'read' || status == 'notified'}
      data-testid={'Notification'}
    >
      <Box sx={leftContainerStyles}>
        <Circle sx={{ color: circleColor }} height={18} width={18} />
        <Box sx={{ display: 'flex', alignItems: 'center', margin: '0 14px', }}>
          {/* TODO: REPLACE WITH NAME WHEN BE UPDATES*/}
          {/* <Avatar
            sx={{
              width: 40,
              height: 40,
              alignItems: 'center',
              margin: '0 14px',
              background: AppColors.i200,
            }}
          >
  
            {orgId?.slice(0, 2)}
          </Avatar> */}
          <Box>
            <Typography
              sx={{ ...AppFonts.textMSemibold, color: AppColors.gray700 }}
            >
              {type}
            </Typography>
            <Typography
              sx={{ ...AppFonts.textSMedium, color: AppColors.gray50 }}
            >
              {`${dateToMMMDYYYYHHMMA(createdAt)}, ${body}`}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* not every notification will have a link so don't show */}
      {link !== '' && (
        <Box
          data-testid={'NotificationButton'}
          onClick={() => notificationClicked(id)}
          sx={{ cursor: 'pointer' }}
        >
          <LaunchIcon />
        </Box>
      )}
    </StyledBox>
  )
}

const leftContainerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

export default NotificationTile

//This could refactored as needed if we find other components that need to be styled like this but for now it's just for the notification component, so leave this here for now since this is the only place it's used

interface StyledBoxProps {
  primary: boolean
  children: React.ReactNode
  sx?: SxProps
}

const StyledBox = ({ primary, children, sx }: StyledBoxProps) => {
  const backgroundColor = primary ? AppColors.baseWhite : AppColors.primary25
  const border = `1px solid ${
    primary ? AppColors.neutral600 : AppColors.primary200
  }`

  return (
    <Box
      data-testid='NotificationTile'
      sx={{
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: '24px',
        padding: '16px',
        backgroundColor,
        border,
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
