//ED-1383 https://edifyai.atlassian.net/browse/ED-1383
import React from 'react'
import {
  OrganizationProviderProps,
  useOrganizationProvider,
} from '../../../providers/OrganizationProvider'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ProjectsPageViewModel,
  ProjectsPageViewModelProps,
  useProjectsPageViewModel,
} from '../projects/ProjectsViewModel'
import { normalizeProjectsForDataGrid } from '../../components/datagrid/gridData/projects'
import PageContainer from '../../components/page/PageContainer'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { Box } from '@mui/material'
import ProjectsFilter from '../projects/ProjectsFilter'
import ProjectsDataGrid from '../projects/ProjectsDataGrid'
import { LevelPageViewModel, useLevelPageViewModel } from './LevelViewModel'
import EdifyIconButton from '../../components/buttons/EdifyIconButton/EdifyIconButton'
import { PinIcon } from '../../../core/components/icons/PinIcon'
import EdifyContactInfo from '../../components/cards/EdifyContactInfo'
import LevelHeader from '../../components/cards/LevelHeader'
import { PinActiveIcon } from '../../../core/components/icons/PinActiveIcon'
import usePermission from '../../../core/hooks/usePermission'
import { ERole } from '../../../domain/interfaces/IRole'

export const _LevelPage = () => {
  const {
    hierarchiesLoading,
    hierarchiesError,
    getHierarchyName,
  }: OrganizationProviderProps = useOrganizationProvider()
  const canEdit = usePermission(ERole.LevelEdit)

  const navigate = useNavigate()
  const { id } = useParams()

  const projectVM: ProjectsPageViewModelProps = useProjectsPageViewModel()
  const vm = useLevelPageViewModel()

  const getRows = () => {
    return projectVM.projects
      ? normalizeProjectsForDataGrid(projectVM.projects)
      : []
  }
  const edit = () => {
    if (vm.level) {
      navigate(`/levels/1/${id}/level/${vm.level.id}/edit`)
    }
  }

  const renderRightItems = () => {
    const favorite = vm.level?.favorited
    return (
      <Box>
        <EdifyIconButton onClick={favorite ? vm.unSetFavorite : vm.setFavorite}>
          {favorite ? <PinActiveIcon /> : <PinIcon />}
        </EdifyIconButton>
      </Box>
    )
  }

  return (
    <PageContainer
      mainPageError={hierarchiesError}
      mainPageLoading={hierarchiesLoading}
      mainPageReload={vm.getLevel}
      breadCrumbs={[
        { title: 'Organization', to: '' },
        { title: getHierarchyName(1, true), to: '/levels/1' },
        { title: vm.levelName, to: `/levels/1/${vm.levelName}s` },
      ]}
      topActions={canEdit ? [
        { title: 'Edit', callback: edit },
        {
          title: 'Delete',
          callback: () => vm.showDeleteConfirmation([vm.level!.id]),
        },
      ] :[]}
    >
      <InnerPageContainer
        innerPageError={vm.levelError}
        innerPageLoading={vm.levelLoading}
        innerPageReload={vm.getLevel}
        sx={{ padding: '24px', marginBottom:'24px' }}
        header={
          <LevelHeader favorite={vm.level?.favorited} inactive={!vm.level?.active} text={vm.levelName} />
        }
        renderRightItems={renderRightItems}
      >
        <EdifyContactInfo
          name={vm.contact?.fullName ?? 'NA'}
          role={vm.contact?.roles && vm.contact?.roles[0].name || 'NA'}
          phone={vm.contact?.phone ?? 'NA'}
          email={vm.contact?.email ?? 'NA'}
        />
      </InnerPageContainer>
      <ProjectsFilter
        vm={projectVM}
        buttonTitle={getHierarchyName(0, false)}
        onButtonClick={() => navigate(`/levels/1/${id}/new`)}
      />
      <ProjectsDataGrid
        levelId={id}
        vm={projectVM}
        error={projectVM.error}
        title={getHierarchyName(0, false)}
        rows={getRows()}
        checkboxSelection={false}
      />
    </PageContainer>
  )
}

export const LevelPage = () => {
  return (
    <LevelPageViewModel>
      <ProjectsPageViewModel>
        <_LevelPage />
      </ProjectsPageViewModel>
    </LevelPageViewModel>
  )
}
