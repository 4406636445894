import React, { useEffect, useState } from 'react'
import { EdifyDataGrid } from '../../components/datagrid/EdifyDataGrid'
import {
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid'
import { ILocation } from '../../../domain/interfaces/ILocation'
import {
  location_columns,
  normalizeLocationsForDataGrid,
} from '../../components/datagrid/gridData/locations'
import { getLocations } from '../../../domain/usecases/locations/LocationsUsecases'
import useQuery from '../../../core/hooks/useQuery'
import { DEFAULT_LIMIT } from '../users/UsersViewModel'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PROJECTS } from '../projects/ProjectsPage'
import BulkDeleteButton from '../../components/buttons/BulkButtons/BulkDeleteButton'
import { showConfirmationDeleteMessage } from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import { useProjectPageViewModel } from '../project/ProjectPageViewModel'

interface ILocationsDataGridProps {
  projectId: string
}

const LocationsDataGrid: React.FC<ILocationsDataGridProps> = ({
  projectId,
}) => {
  const navigate = useNavigate()
  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([])
  const {
    locations,
    loadMoreLocations,
    locationsLoading,
    locationsError,
    lSortClickedHandler,
    totalLocations,
  } = useProjectPageViewModel()

  const handleRowClicked = (params: GridRowParams) => {
    const locationId = params.row.id

    navigate(`${ROUTE_PROJECTS}/${projectId}/locations/${locationId}`, {
      state: {},
    })
  }

  // No bulk delete from BE
  // const removeClicked = async () => {
  //   showConfirmationDeleteMessage(
  //     'the selected locations and there sub locations',
  //     () => {
  //       //Delete the selected locations
  //     },
  //   )
  // }

  // const renderHeaderButtons = () => {
  //   // don't render bulk action items if turned off
  //   // if (!checkboxSelection) return <></>
  //   const noSelections = selectionModel.length === 0
  //   return (
  //     <BulkDeleteButton noSelections={noSelections} onClick={removeClicked} />
  //   )
  // }

  return (
    <>
      <EdifyDataGrid
        data-testid='LocationsDataGrid'
        paginationMode='server'
        title={'Locations'}
        noRowsText={'No locations found.'}
        columns={location_columns}
        rows={normalizeLocationsForDataGrid(locations)}
        error={locationsError}
        rowSelectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        loading={locationsLoading}
        rowCount={totalLocations}
        loadMoreRows={loadMoreLocations}
        // reload={loadMoreLocations}
        sortClickedHandler={lSortClickedHandler}
        checkboxSelection={false}
        handleRowClicked={handleRowClicked}
        // renderHeaderButtons={renderHeaderButtons()}
      />
    </>
  )
}

export default LocationsDataGrid
