// ED-1386 https://edifyai.atlassian.net/browse/ED-1386
import React, { useContext, useEffect, useState } from 'react'
import useQuery from '../../../core/hooks/useQuery'
import { DEFAULT_LIMIT } from '../users/UsersViewModel'

import { IProject, IProjectList } from '../../../domain/interfaces/IProjects'
import { showConfirmationDeleteMessage } from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import { Either, Failure } from '../../../core/core'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import { deleteProjects, getAdminProjects, getProjectById } from '../../../domain/domain'
import { useParams } from 'react-router-dom'
import { ISearchResult } from '../../components/form/EdifySearch/EdifySearchDropdown'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'

export interface ProjectsPageViewModelProps {
  queryHelper: Record<string, any>
  projects: IProject[]
  isLoading: boolean
  isLoadMore: boolean
  totalCount: number
  projectsError: string | undefined
  error: string | undefined
  getProjects: () => void
  showDeleteConfirmation: (ids: string[]) => void
  loadMore: (paginationModel: GridPaginationModel) => void
  fetchProjects: (fetchSkip: number, query: Record<string, any>) => void
  sortClickedHandler: (sorter: GridSortModel) => void
  onProjectSearch: (result: ISearchResult) => void
}

const ProjectPageContext =
  React.createContext<ProjectsPageViewModelProps | null>(null)

export function useProjectsPageViewModel(): ProjectsPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(ProjectPageContext)!
}
interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const ProjectsPageViewModel: React.FC<Props> = ({ children}) => {
  const { id, userId, contactId } = useParams()
  const {getHierarchyName} = useOrganizationProvider()
  // allows to do a subsearch of projects for given id in level show page
  const queryHelper = useQuery({
    ...(id ? { levelId: id } : {}),
    ...(userId ? { userId} : {}),
    ...(contactId ? { contactId} : {}),
    skip: 0,
    limit: DEFAULT_LIMIT,

  })
  const { query, getQueryStringFromQuery, setNew } = queryHelper
  const [projects, setProjects] = useState<IProject[]>([])
  const [projectsError, setProjectsError] = useState<string | undefined>(
    undefined,
  )
  const [isLoading, setIsLoading] = useState({
    initialLoad: false,
    loadMore: false,
    search: false,
  })
  const [totalCount, setTotalCount] = useState<number>(0)

  const getProjects = async () => {
    setProjectsError(undefined)
    setIsLoading((oldState) => {
      return { ...oldState, initialLoad: true }
    })
    setProjects([])
    fetchProjects(0, query)
  }

  const fetchProjects = async (
    fetchSkip: number,
    query: Record<string, any>,
  ) => {
    setIsLoading((oldState) => {
      return { ...oldState, loadMore: true }
    })
    setProjectsError(undefined)

    const newQuery = { ...query, skip: fetchSkip }

    const res: Either<Failure, IProjectList> = await getAdminProjects(
      getQueryStringFromQuery(newQuery),
    )
    setIsLoading((oldState) => {
      return { ...oldState, initialLoad: false, loadMore: false }
    })
    if (res.isRight()) {
      setTotalCount(res.right?.total_count || 0)
      setProjects(res.right?.data as IProject[])
    } else {
      setProjectsError(res.left?.message)
    }
    setNew(newQuery)
  }

  const loadMore = (paginationModel: GridPaginationModel) => {
    const fetchSkip = paginationModel.page * paginationModel.pageSize
    fetchProjects(fetchSkip, query)
  }

  const deleteSelectedProjects = async (ids: string[]) => {
    const res = await deleteProjects(ids)
    const projectString = ids.length == 1 ? getHierarchyName(0) : getHierarchyName(0, true)
    const projectStringLower = projectString.toLocaleLowerCase()
    if (res.isRight()) {
      SuccessToast({ title: `${projectString} removed successfully.` })
      fetchProjects(query.skip, query)
    } else {
      ErrorToast({ title: `Error removing ${projectStringLower}.` })
    }
  }

  const showDeleteConfirmation = async (ids: string[]) => {
    const projectString = ids.length == 1 ? getHierarchyName(0) : getHierarchyName(0, true)
    const projectStringLower = projectString.toLocaleLowerCase()
    const confirmMessage =
      ids.length > 1 ? `${ids.length} ${projectStringLower}` : `1  ${projectStringLower}`

    showConfirmationDeleteMessage(confirmMessage, () =>
      deleteSelectedProjects(ids),
    )
  }

  const sortClickedHandler = (sorter: GridSortModel) => {
    const { field, sort } = sorter[0]
    const newQuery = {
      ...query,
      skip: 0,
      sortBy: field,
      sortOrder: sort?.toUpperCase(),
    }
    fetchProjects(0, newQuery)
  }

  const onProjectSearch = async (projectSearchResult: ISearchResult) => {
    // check to see if it is already in the page
    const matchedProject = projects.find((p) => p.id == projectSearchResult.id)
    if(matchedProject){
      setProjects([matchedProject])
      setTotalCount(1)
      return
    }
    // if not grab from API
    if (!matchedProject){
      setIsLoading((oldState) => {
        return { ...oldState, loadMore: true }
      })
      setProjectsError(undefined)
      setIsLoading((oldState) => {
        return { ...oldState, initialLoad: false, loadMore: false }
      })
      const res = await getProjectById(projectSearchResult.id)
      if(res.right){
        setProjects([res.right])
        setTotalCount(1)
      } else {
        ErrorToast({title:'Could not find project'})
      }
    }
  }

  useEffect(() => {
    getProjects()
  }, [])

  return (
    <ProjectPageContext.Provider
      value={{
        queryHelper,
        projects,
        isLoading: isLoading.initialLoad,
        isLoadMore: isLoading.loadMore,
        projectsError,
        fetchProjects,
        getProjects: getProjects,
        showDeleteConfirmation,
        loadMore,
        totalCount,
        sortClickedHandler,
        onProjectSearch,
        error:projectsError
      }}
    >
      {children}
    </ProjectPageContext.Provider>
  )
}
export default ProjectPageContext
