import React from 'react'
import PageContainer from '../../components/page/PageContainer'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'

import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { LinkCard } from './LinkCard'
import { Box, Grid } from '@mui/material'
import DemoPage from './DemoPage'

export const ROUTE_HOME = '/home'

const HomePage: React.FC = () => {
  const orgVm = useOrganizationProvider()
  if (orgVm.isDemoAccount) return <DemoPage page='home' />

  return (
    <PageContainer
      title={'Welcome back'}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <InnerPageContainer
            sx={{
              backgroundColor: 'transparent',
              border: 'none',
              padding: '0px',
            }}
          >
          </InnerPageContainer>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <InnerPageContainer
            sx={{
              backgroundColor: 'transparent',
              border: 'none',
              padding: '0px',
            }}
            innerPageLoading={!orgVm.homeFormLinks}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              {orgVm.homeFormLinks &&
                orgVm.homeFormLinks.forms.map((link) => {
                  return (
                    <LinkCard
                      sx={{ flex: 1 }}
                      section={'submission'}
                      key={link.id}
                      link={`/submissions/${link.id}/submission/new`}
                      title={link.title}
                      subTitle='Create new submission'
                    />
                  )
                })}
            </Box>
          </InnerPageContainer>
        </Grid>
      </Grid>
    </PageContainer>
  )
}

// TODO MOVE IN

export default HomePage
