import React, { useEffect, useRef, useState } from 'react'
import { DateRangePicker } from 'react-date-range'
import format from 'date-fns/format'
import { addDays } from 'date-fns'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import {
  Box,
  FormControl,
  InputAdornment,
  Popover,
  TextField,
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { AppColors, AppFonts } from '../../../Theme'
import { EdifyButton } from '../../buttons'
import { DateRangeIcon } from '@mui/x-date-pickers'
import ClearIcon from '@mui/icons-material/Clear'

interface IEdifyDateRangeProps {
  dateRange: string
  onChange: (dates: string) => void
  width?: number | string
  placeHolder?: string
}

const EdifyDateRange: React.FC<IEdifyDateRangeProps> = ({
  onChange,
  dateRange,
  width = '220px',
  placeHolder ='Date Range'
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open1 = Boolean(anchorEl)
  const id = open1 ? 'simple-popover' : undefined
  const [range, setRange] = useState<any>([
    {
      // FOr, the date range picker doesn't allow me to net set a value here so I use tomorrow's date to determine if the user has selected a date or not.
      startDate: addDays(new Date(), 1),
      endDate: new Date(),
      key: 'selection',
    },
  ])

  const [open, setOpen] = useState(false)

  const refOne = useRef<HTMLDivElement>(null)

  useEffect(() => {
    document.addEventListener('keydown', hideOnEscape, true)
    document.addEventListener('click', hideOnClickOutside, true)

    return () => {
      document.removeEventListener('keydown', hideOnEscape, true)
      document.removeEventListener('click', hideOnClickOutside, true)
    }
  }, [])

  const hideOnEscape = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setOpen(false)
    }
  }

  const hideOnClickOutside = (e: MouseEvent) => {
    if (refOne.current && !refOne.current.contains(e.target as Node)) {
      setOpen(false)
    }
  }
  const resetRange = (isOpening: boolean) => {
    if (dateRange !== '') {
      const [startDate, endDate] = dateRange.split('-')
      setRange([
        {
          startDate: new Date(startDate),
          endDate: new Date(endDate),
          key: 'selection',
        },
      ])
      return
    }

    onChange('')
    setRange([
      {
        startDate: !isOpening ? addDays(new Date(), 1) : new Date(),
        endDate: !isOpening ? addDays(new Date(), 1) : new Date(),
        key: 'selection',
      },
    ])
  }

  const cancelClicked = () => {
    setOpen(false)
    resetRange(false)
  }

  // TODO: figure out to not use tomorrow logic for clearing the value in text box
  const getDateString = (): string => {
    let dateString = ''
    // Hack: if start date is tomorrow, then the user has not selected a date
    const isTomorrow =
      range[0].startDate.toString() === addDays(new Date(), 1).toString()
    if (isTomorrow) {
      return ''
    }
    if (range[0].startDate && range[0].endDate) {
      dateString = `${format(range[0].startDate, 'MM/dd/yyyy')}-${format(
        range[0].endDate,
        'MM/dd/yyyy',
      )}`
    }
    return dateString
  }

  const selectClicked = () => {
    setOpen(false)
    const dateString = getDateString()
    onChange(dateString)
  }

  const handleClick = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
    resetRange(!open)
  }
  const handleClose = (e: any) => {
    const dateString = getDateString()
    onChange(dateString)

    setOpen(false)
    setAnchorEl(null)
  }

  const handleReset = () => {
    onChange('')
    // This allows for the 'X' in endAdornment to cleat the date
    // and not reopen the date picker
    setTimeout(() => {
      setOpen(false)
    }, 100)
  }

  return (
    <>
      <FormControl
        variant='standard'
        data-testid='DateRangePicker'
        sx={{
          width: width,
          border: `1px solid ${AppColors.neutral600}`,
          borderRadius: '5px',
          padding: '2px 12px',
          backgroundColor: AppColors.baseWhite,
        }}
      >
        <TextField
          variant='standard'
          data-testd='DateRangePickerInput'
          placeholder={placeHolder}
          className=''
          sx={{
            
            ...AppFonts.textMRegular,
            '& .MuiInputBase-input': {
              cursor: 'pointer',
            },
            '& input': {
              padding: '10px 5px 10px 5px',
            },
          }}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position='start'>
                <DateRangeIcon sx={{ color: '#979AA0' }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position='end'
                sx={{
                  color: AppColors.gray25,
                  '&:hover': { cursor: 'pointer' },
                }}
              >
                {dateRange === '' && !open ? (
                  <ExpandLess />
                ) : (
                  <ClearIcon
                    onClick={handleReset}
                    sx={{
                      width: '20px',
                      height: '20px',
                      color: AppColors.neutral950,
                    }}
                  />
                )}
              </InputAdornment>
            ),
          }}
          aria-describedby={id}
          value={dateRange || ''}
          onClick={handleClick}
        />
      </FormControl>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        ref={refOne}
      >
        <Box
          sx={{
            background: AppColors.neutral25,
            border: '1px solid white',
            padding: '24px',
            className: 'e-input',
          }}
        >
          <DateRangePicker
            data-testid='DateRangePicker'
            initialFocusedRange={[0, 0]}
            onChange={(item) => setRange([item.selection])}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={2}
            direction='horizontal'
            // className='calendarElement'
            // maxDate={new Date()}
            color={AppColors.primary600}
          />
        </Box>
        <Box
          style={{
            borderTop: `1px solid ${AppColors.neutral600}`,
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '16px 24px',
          }}
        >
          <EdifyButton
            data-testid='Cancel'
            buttonStyle={{ marginRight: '8px' }}
            secondary
            onClick={cancelClicked}
            title='Close'
          />
          <EdifyButton
            data-testId='Continue'
            onClick={selectClicked}
            title='Continue'
          />
        </Box>
      </Popover>
    </>
  )
}

export default EdifyDateRange
