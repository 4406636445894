import React from 'react'
import { EdifyAppBar } from '../../components/banners/app-bar/EdifyAppBar'
import { useNavigate } from 'react-router-dom'
import PageContainer from '../../components/page/PageContainer'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { Box, Typography } from '@mui/material'
import { EdifyButton } from '../../components/buttons'
import { AppColors, AppFonts } from '../../Theme'
import EastIcon from '@mui/icons-material/East'
import { ROUTE_ORG_SELECTION } from '../org_selection/OrgSelectionPage'
import { CheckLoadingIcon } from '../../../core/components/icons/CheckLoadingIcon'
import { PersonProfileForm } from '../../components/forms/PersonProfileForm/PersonProfileForm'
import { logout } from '../../../data/data'
import UserPersonForm from '../user/UserPersonForm'
import { getCurrentPerson } from '../../../domain/domain'
export const ROUTE_PERSON_PROFILE = '/profile'

interface Props {
  isFromOrg?: boolean
}

export const PersonProfilePage: React.FC<Props> = ({ isFromOrg = false }) => {
  const handleLogout = () => {
    logout()
    navigate('/')
  }
  const navigate = useNavigate()
  if (isFromOrg) {
    return (
      <PageContainer
        title={getCurrentPerson()?.fullName ?? 'Profile'}
        // renderRightItems={() => (
        //   <EdifyButton noBackground onClick={handleLogout} title='Logout' />
        // )}
      >
        {/* <PersonProfileForm isFromOrg /> */}
        <UserPersonForm
          setUserName={(name: string) => {
            console.log(name)
          }}
        />
      </PageContainer>
    )
  }

  return (
    <EdifyAppBar barItems={[]} showDrawer={false}>
      <PageContainer
        title='My Profile'
        renderRightItems={() => (
          <EdifyButton noBackground onClick={handleLogout} title='Logout' />
        )}
      >
        {/* SKIP TO HOME */}
        <InnerPageContainer
          sx={{
            border: `1px solid ${AppColors.primary600}`,
            backgroundColor: AppColors.primary25,
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            padding: '16px 20px',
            marginBottom: '24px',
          }}
        >
          <Box style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            <CheckLoadingIcon />
            <Box sx={{ marginLeft: '16px' }}>
              <Typography
                sx={{
                  ...AppFonts.textMSemibold,
                  color: AppColors.primary600,
                }}
              >
                Almost done!
              </Typography>
              <Typography
                sx={{ ...AppFonts.textSMedium, color: AppColors.gray50 }}
              >
                Finalize your information and you’re good to go.
              </Typography>
            </Box>
          </Box>
          <EdifyButton
            title='Skip to Home'
            onClick={() => navigate(ROUTE_ORG_SELECTION)}
            icon={<EastIcon sx={{ color: AppColors.baseWhite }} />}
            iconRight
          />
        </InnerPageContainer>

        <UserPersonForm
          setUserName={(name: string) => {
            console.log(name)
          }}
        />
      </PageContainer>
    </EdifyAppBar>
  )
}
