import React, { useContext, useEffect, useState } from 'react'
import { Either, Failure } from '../../../core/core'

import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import { IUser, IUserList } from '../../../domain/interfaces/IUser'
import {
  adminDeleteUsers,
  adminGetRoles,
  getAllUsers,
} from '../../../domain/domain'
import { IRole } from '../../../domain/interfaces/IRole'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import {
  hideConfirmationDialog,
  showConfirmationDialog,
} from '../../components/dialogs/confirmation-dialog/ConfirmationDialog'
import { normalizeUsersForGrid } from '../../components/datagrid/gridData'
import useQuery from '../../../core/hooks/useQuery'

//This will need to be dynamic hardcoding for now
interface UserRowProps {
  id: string
  name: string
  projects: string[] | undefined
  lastLogin: string | undefined
  role: string | undefined
}

export interface UsersPageViewModelProps {
  users: IUser[] | undefined
  normalizeUsersForDataGrid: () => UserRowProps[] | []
  error?: string
  clearError?: () => void
  loadMore?: (paginationModel: GridPaginationModel) => void
  sortSubmissions?: () => void
  sort?: string
  skip: number
  totalCount?: number
  reload: () => void
  reloadRoles: () => void
  roles: IRole[] | undefined
  rolesLoading: boolean
  rolesError?: string
  isLoading: boolean
  isLoadMore: boolean
  isSearching: boolean
  editUser?: IUser
  setEditUser: (user: IUser | undefined) => void
  deleteUsers: (ids: string[]) => void
  sortClickedHandler: (sorter: GridSortModel) => void
  clearFilters: (reload: boolean) => void
  queryHelper: Record<string, any>
  fetchUsers: (fetchSkip: number, query: Record<string, any>) => void
}

const UsersPageContext = React.createContext<UsersPageViewModelProps | null>(
  null,
)

export function useUsersPageViewModel(): UsersPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(UsersPageContext)!
}
interface Props {
  children: React.ReactElement | React.ReactElement[]
}
export const DEFAULT_LIMIT = 20

export const UsersPageViewModel: React.FC<Props> = ({ children }) => {
  const queryHelper = useQuery({ skip: 0, limit: DEFAULT_LIMIT })
  const { defaultQuery, reset, getQueryStringFromQuery, setNew, query } =
    queryHelper

  const [isLoading, setIsLoading] = useState({
    initialLoad: false,
    loadMore: false,
    search: false,
  })
  const [skip, setSkip] = useState<number>(0)
  const [totalCount, setTotalCount] = useState<number>(0)
  const [error, setError] = useState<string | undefined>(undefined)
  const [users, setUsers] = useState<IUser[] | undefined>(undefined)
  const [roles, setRoles] = useState<IRole[] | undefined>(undefined)
  const [rolesLoading, setRolesLoading] = useState<boolean>(false)
  const [rolesError, setRolesError] = useState<string | undefined>(undefined)
  const [editUser, setEditUser] = useState<IUser | undefined>(undefined)

  const clearFilters = async (reload = false) => {
    reset()
    if (reload) {
      fetchUsers(0, defaultQuery)
    }
  }


  const fetchUsers = async (fetchSkip: number, query: Record<string, any>) => {
    setIsLoading((oldState) => {
      return { ...oldState, loadMore: true }
    })
    setError(undefined)

    // load submissions or corrective actions
    const newQuery = { ...query, skip: fetchSkip }
    const res: Either<Failure, IUserList> = await getAllUsers(
      getQueryStringFromQuery(newQuery),
    )
    setIsLoading((oldState) => {
      return { ...oldState, initialLoad: false, loadMore: false }
    })
    if (res.isRight()) {
      setTotalCount(res.right?.total_count || 0)
      setUsers(res.right?.data as IUser[])
      setSkip(fetchSkip)
    } else {
      setError(res.left?.message)
    }
    setNew(newQuery)
  }

  const loadMore = (paginationModel: GridPaginationModel) => {
    const fetchSkip = paginationModel.page * paginationModel.pageSize
    fetchUsers(fetchSkip, query)
  }

  const getRoles = async () => {
    setRolesLoading(true)
    const res: Either<Failure, IRole[]> = await adminGetRoles()
    if (res.isRight()) {
      setRoles(res.right)
    } else {
      setRolesError('Error getting roles')
    }
    setRolesLoading(false)
  }

  const getUsers = async () => {
    setIsLoading((oldState) => {
      return { ...oldState, initialLoad: true }
    })
    setSkip(0)
    setUsers([])

    fetchUsers(0, query)
  }

  const normalizeUsersForDataGrid: any = () => {
    return normalizeUsersForGrid(users)
  }

  //DELETE USER
  const deleteUsers = async (ids: string[]) => {
    const confirmMessage = editUser?.fullName ?? `${ids.length} users`
    const userMessage = editUser?.fullName ?? `${ids.length} users`
    showConfirmationDialog({
      title: 'Confirm Delete',
      message: `Are you sure you want to delete ${confirmMessage} from the database? This action cannot be undone.`,
      cancelText: 'Cancel',
      confirmText: 'Delete',
      onCancel: function (): void {
        hideConfirmationDialog()
      },
      onConfirm: async () => {
        // let fakes = ids.map(id=>id)
        const results = await adminDeleteUsers(ids)
        // something went wrong with api call
        if (results.isLeft()) {
          // backend error
          ErrorToast({
            title: `Failed to delete ${userMessage} from the database`,
          })
        }
        // This case should not happen as a user
        // should not show up in the users list
        if (results.right?.badIds && results.right?.badIds.length > 0) {
          ErrorToast({
            title: `You tried to delete ${results.right?.badIds.length} users that should not be on your page. This is a bug on our end`,
          })
          // just reload users if this error occurs it will remove the deleted user
          getUsers()
        }
        if (results.right?.updatedIds) {
          SuccessToast({
            title: `Successfully deleted ${userMessage} from the database`,
          })
          getUsers()
        }
        setEditUser(undefined)
      },
    })
  }

  const sortClickedHandler = (sorter: GridSortModel) => {
    const { field, sort } = sorter[0]
    fetchUsers(0, {
      ...query,
      skip: 0,
      sortBy: field,
      sortOrder: sort?.toUpperCase(),
    })
  }

  useEffect(() => {
    setError(undefined)
    getRoles()
    getUsers()
  }, [])

  const onClearError = () => {
    //not sure what do here
    setError(undefined)
  }

  return (
    <UsersPageContext.Provider
      value={{
        users,
        normalizeUsersForDataGrid,
        isLoading: isLoading.initialLoad,
        isLoadMore: isLoading.loadMore,
        isSearching: isLoading.search,
        loadMore,
        skip,
        error,
        roles,
        rolesLoading,
        rolesError,
        clearError: onClearError,
        reload: getUsers,
        reloadRoles: getRoles,
        totalCount,
        editUser,
        setEditUser,
        deleteUsers,
        sortClickedHandler,
        queryHelper,
        clearFilters,
        fetchUsers,
      }}
    >
      {children}
    </UsersPageContext.Provider>
  )
}
export default UsersPageContext
