import { getCurrentPerson, getCurrentUser } from '../../domain/domain'
import { IRole } from '../../domain/interfaces/IRole'
import { useGlobalProvider } from '../../providers/GlobalProvider'

const usePermission = (role: keyof IRole, byPassGlobalCheck = false) => {
  const currentUser = getCurrentUser()
  const person = getCurrentPerson()
  const { isGlobalUserView } = useGlobalProvider()

  // What should globalRole have permission to do?
  if (!byPassGlobalCheck && isGlobalUserView && person?.globalRole) {
    if (person?.globalRole[role] == 1) {
      return true
    }
  }
  if (!currentUser || !currentUser.roles || !currentUser.roles[0]) {
    return false
  }

  const roles: IRole = currentUser.roles[0]
  return roles[role] == 1 ? true : false
}

export default usePermission
