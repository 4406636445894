import React, { useEffect } from 'react'
import { getAuthToken } from '../../../../domain/domain'
import { useOrganizationProvider } from '../../../../providers/OrganizationProvider'
import { useLocation } from 'react-router-dom'
import { ETypography } from '../../fonts/ETypography'

interface IImageWithTokenProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string
  orgId?: string
  isPDF?: boolean
  setUpdatedSRC?: (src: string) => void
  isLink?: boolean
  fileName?: string
}

type FileDownloadProps = {
  src: string
  fileName: string
  className?: string
  children?: React.ReactNode
}

const addTrailingSlash = (url: string) => {
  return url.replace('/fileUploads?', '/fileUploads/?')
}

const FileDownload: React.FC<FileDownloadProps> = ({
  src,
  fileName,
  className = '',
  children,
}) => {
  return (
    <a
      href={src}
      download={src}
      className={className}
      target='_blank'
      rel='noopener noreferrer'
    >
      {children || fileName}
    </a>
  )
}

const ImageWithToken: React.FC<IImageWithTokenProps> = ({
  src,
  orgId,
  setUpdatedSRC,
  isPDF = false,
  isLink = false,
  fileName,
  ...rest
}) => {
  const token = getAuthToken()
  const org = useOrganizationProvider()

  const location = useLocation()

  if (!orgId) {
    if (org) {
      orgId = org.org?.id
    } else {
      const queryParams = new URLSearchParams(location.search)
      const id = queryParams.get('orgId')
      if (id) {
        orgId = id
      }
    }
  }

  let swappedSrc = src
  if (token) {
    swappedSrc += `&token=${token}`
  }
  if (orgId) {
    swappedSrc += `&orgId=${orgId}`
  }

  useEffect(() => {
    if (setUpdatedSRC) {
      setUpdatedSRC(swappedSrc)
    }
  }, [])

  // need to add something like this so it doesn't download the pdf
  // this needs to happen on the server side
  //+ '&response-content-disposition=inline&response-content-type=application'
  if (isLink)
    return (
      <ETypography font='SM' color='gray700'>
        {fileName ?? 'File'}
      </ETypography>
    )

  if (isPDF) {
    return (
      <iframe
        src={addTrailingSlash(swappedSrc) + '&inline=true'}
        width='100%'
        height='100%'
        title='PDF Viewer'
      />
    )
  }
  return <img src={swappedSrc} {...rest} />
}

export default ImageWithToken
