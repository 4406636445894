import { Box, BoxProps, SxProps } from '@mui/material'
import React from 'react'
import { AppColors } from '../../Theme'
import { ETypography } from '../../components/fonts/ETypography'
import { useNavigate } from 'react-router-dom'

interface ILinkCardProps extends BoxProps {
  section: 'submission' | 'goal' | 'etc'
  title: string
  subTitle: string
  link: string
  linkText?: string
  sx?: SxProps
}
const EdifyCard: React.FC<BoxProps> = ({ children, sx, ...rest }) => {
  return (
    <Box
      sx={{
        border: `1px solid ${AppColors.neutral600}`,
        background: AppColors.baseWhite,
        padding: '16px 20px',
        borderRadius: '10px',
        gap: '16px',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export const LinkCard: React.FC<ILinkCardProps> = ({
  subTitle,
  title,
  link,
  linkText,
  sx,
  ...rest
}) => {
  const navigate = useNavigate()
  const navigateClicked = () => {
    navigate(link)
  }
  return (
    <EdifyCard
      sx={{ cursor: 'pointer', ...sx, flex: 1 }}
      onClick={navigateClicked}
      {...rest}
    >
      <ETypography font={'XSM'} color={'gray50'} sx={{ marginBottom: '8px' }}>
        {subTitle}
      </ETypography>
      <ETypography font={'HMS'} color={'gray700'}>
        {title}
      </ETypography>
    </EdifyCard>
  )
}
