import React, { useState } from 'react'

import { EdifyButton } from '../../components/buttons'
import { Box } from '@mui/material'
import FilterButtonGroup from '../../components/buttons/filter-button-group/FilterButtonGroup'

import { UsersPageViewModelProps } from './UsersViewModel'
import EdifySearchDropdown, { ISearchResult } from '../../components/form/EdifySearch/EdifySearchDropdown'
import { usersSearch } from '../../../domain/domain'

interface IUsersFilterProps {
  vm: UsersPageViewModelProps
}

const UsersFilter: React.FC<IUsersFilterProps> = ({ vm }) => {
  const [activeButton, setActiveButton] = useState<string>('All')
  const { query } = vm.queryHelper
  const handleButtonClicked = (button: string) => {
    // TODO: check when BE implemented
    const newUserQueryField = { ...query, role: button }
    vm.fetchUsers(0, newUserQueryField)
  }
  const contactSelected = (contacts: ISearchResult[]) => {
    // search cleared
    if (contacts.length == 0) {
      const newQuery = { ...query }
      delete newQuery.searchKey
      vm.fetchUsers(0, newQuery)
      return
    }
    vm.fetchUsers(0, { ...query, searchKey: contacts[0].name })
  }

  const filterButtons =
    (!vm.rolesLoading && vm.roles?.map((role: any) => role.name)) || []

  return (
    <>
      <Box
        data-testid={'UsersPage'}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom:'24px',
        }}
      >
        <FilterButtonGroup
          isLoading={vm.rolesLoading}
          error={vm.rolesError}
          reload={vm.reloadRoles}
          onButtonClicked={handleButtonClicked}
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          buttons={['ALL', ...filterButtons]}
        />
        <EdifySearchDropdown
          placeholder='Search Name'
          data-testid='PointOfContact'
          searchFunction={usersSearch}
          width={218}
          limit={8}
          onSelect={contactSelected}
          nameKey='fullName'
        />
      </Box>

      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          margin: '24px 0',
        }}
      >
        <EdifyButton
          onClick={() => vm.clearFilters(true)}
          title='Clear Filters'
          primary
          noBackground
        />
      </Box> */}
    </>
  )
}

export default UsersFilter
