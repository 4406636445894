// State we load on mount and
import React, { useContext, useEffect, useState } from 'react'
import { IHierarchyName } from '../domain/interfaces/IHierarchyName'
import { ILevel, IProject } from '../domain/interfaces/IProjects'
import pluralize from 'pluralize'
import { ISubLocationHierarchyName } from '../domain/interfaces/ILocation'
import {
  getMinimalOrgHierarchyNames,
  getOrgMinimalLevels,
  getOrgMinimalProjects,
  getOrgMinimalSubLocationHierarchyNames,
  getOrgMinimalUsers,
} from '../domain/domain'

import { IMinimalIdName } from '../domain/interfaces/IOrg'
import { IMinimalUser } from '../domain/interfaces/IUser'

export interface PublicOrganizationProviderProps {
  // orgId: string
  hierarchies: IHierarchyName[]
  hierarchiesLoading: boolean
  hierarchiesError: string | undefined
  publicOrgProviderError: () => string | undefined
  reload: () => void
  reloadHierarchies: () => void
  getHierarchyName: (level: 0 | 1, plural?: boolean) => string
  allLevels: ILevel[] | IMinimalIdName[]
  allProjects: IProject[] | IMinimalIdName[]
  subLocationHierarchies: ISubLocationHierarchyName[] | undefined
  subLocationHierarchiesLoading: boolean
  subLocationHierarchiesError: string | undefined
  level1Name: string
  level0Name: string
  setPublicHierarchies: React.Dispatch<React.SetStateAction<IHierarchyName[]>>
  publicOrgProviderLoaded: boolean
  allUsers: IMinimalUser[]
}

const PublicOrganizationContext =
  React.createContext<PublicOrganizationProviderProps | null>(null)

export function usePublicOrganizationProvider(): PublicOrganizationProviderProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(PublicOrganizationContext)!
}
interface Props {
  children: React.ReactElement | React.ReactElement[]
  formId?: string
  orgId?: string
  isContractor?: boolean
}

export const PublicOrganizationProvider: React.FC<Props> = ({
  children,
  isContractor = false,
  formId,
  orgId,
}) => {
  // hierarchies STATE
  const [hierarchies, setHierarchies] = useState<IHierarchyName[]>([])
  const [hierarchiesLoading, setHierarchiesLoading] = useState<boolean>(true)
  const [hierarchiesError, setHierarchiesError] = useState<string | undefined>(
    undefined,
  )
  const [publicOrgProviderLoaded, setPublicOrgProviderLoaded] =
    useState<boolean>(false)

  const [subLocationHierarchies, setSubLocationHierarchies] = useState<
    ISubLocationHierarchyName[]
  >([])
  const [subLocationHierarchiesLoading, setSubLocationHierarchiesLoading] =
    useState<boolean>(true)
  const [subLocationHierarchiesError, setSubLocationHierarchiesError] =
    useState<string | undefined>(undefined)

  const [allLevels, setAllLevels] = useState<IMinimalIdName[]>([])
  const [allProjects, setAllProjects] = useState<IMinimalIdName[]>([])
  const [allUsers, setAllUsers] = useState<IMinimalUser[]>([])

  // hierarchies in reverse order for nav this method
  // makes that less confusing on other none nave pages
  const getHierarchyName = (level: 0 | 1, plural = false) => {
    // handle reload case
    if (hierarchies.length < 1) return ''
    let name = ''
    if (level == 0) {
      name = hierarchies.find((h) => h.level === 0)?.name ?? 'Level'
    }
    if (level == 1) {
      name = hierarchies.find((h) => h.level === 1)?.name ?? 'Project'
    }
    return plural ? pluralize(name) : name
  }

  //TODO: CHECK orgId is always defined
  const oId = orgId ?? 'error'

  // HIERARCHIES LOGIC
  const getHierarchies = async () => {
    setHierarchiesError(undefined)
    setHierarchiesLoading(true)
    const res = await getMinimalOrgHierarchyNames(oId, formId)

    // TEMP FIX FORMIO: Load all projects and levels to find ids for form io, it is breaking many test
    if (process.env.NODE_ENV !== 'test') {
      const projectsRes = await getOrgMinimalProjects(oId, formId)
      // using an empty sting will grab a level1's
      const levelsRes = await getOrgMinimalLevels(oId, formId)
      if (projectsRes.right && levelsRes.right) {
        setAllProjects(projectsRes.right)
        setAllLevels(levelsRes.right)
      }
    }

    setHierarchiesLoading(false)
    if (res.isLeft() || res.right?.data === undefined) {
      setHierarchiesError(res.left?.message ?? 'Error getting hierarchies.')
      return
    }

    if (Array.isArray(res.right.data)) {
      const level1: any = res.right.data.find((l) => l.level == 1) ?? {
        name: 'Level 1',
        level: 1,
      }
      const level0: any = res.right.data.find((l) => l.level == 0) ?? {
        name: 'Level 0',
        level: 0,
      }

      setHierarchies([level1, level0])
      return
    }
    setHierarchies([])
  }

  const getSubLocationsHierarchies = async () => {
    setSubLocationHierarchiesError(undefined)
    setSubLocationHierarchiesLoading(true)

    const res = await getOrgMinimalSubLocationHierarchyNames(oId, formId)
    setSubLocationHierarchiesLoading(false)
    if (res.isRight() && res.right) {
      setSubLocationHierarchies(res.right.sort((a, b) => b.level! - a.level!))
      return
    }

    setSubLocationHierarchiesError(
      res.left?.message ?? 'Error loading sub location hierarchy names',
    )
  }

  const getUsers = async () => {
    const res = await getOrgMinimalUsers(oId, formId)
    if (res.isRight() && res.right) {
      setAllUsers(res.right)
      return
    }
  }

  // Add any other loading state here
  const publicOrgProviderError = () => {
    return hierarchiesError || subLocationHierarchiesError
  }

  const load = async () => {
    setPublicOrgProviderLoaded(false)
    await getHierarchies()
    await getSubLocationsHierarchies()
    // await getUsers()
    setPublicOrgProviderLoaded(true)
  }

  useEffect(() => {
    load()
  }, [])
  return (
    <PublicOrganizationContext.Provider
      value={{
        hierarchies,
        hierarchiesLoading,
        hierarchiesError,
        publicOrgProviderError,
        reload: load,
        reloadHierarchies: getHierarchies,
        getHierarchyName,
        allProjects,
        allLevels,
        subLocationHierarchies,
        subLocationHierarchiesLoading,
        subLocationHierarchiesError,
        level1Name: getHierarchyName(1),
        level0Name: getHierarchyName(0),
        setPublicHierarchies: setHierarchies,
        publicOrgProviderLoaded,
        allUsers,
      }}
    >
      {children}
    </PublicOrganizationContext.Provider>
  )
}
export default PublicOrganizationProvider
