import React, { useContext, useEffect, useState } from 'react'

import { IDashboardTokens } from '../../../domain/interfaces/IDashboard'
import { getCurrentUser } from '../../../domain/domain'
import { getDashboardTokens } from '../../../domain/usecases/dashboards/DashboardsUsecases'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'

export interface IDashboardPageViewModelProps {
  loading: boolean
  error: string | undefined
  tokens: IDashboardTokens | undefined
  title: string
  getTokens: () => void
}

const DashboardPageContext =
  React.createContext<IDashboardPageViewModelProps | null>(null)

export function useDashboardPageViewModel(): IDashboardPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(DashboardPageContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
}



export const DashboardPageViewModel: React.FC<Props> = ({ children }) => {
  const orgVm = useOrganizationProvider()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [tokens, setTokens] = useState<IDashboardTokens | undefined>(undefined)
  const name = orgVm.profileTile.name ?? ''
  const title = name ? `Hey there ${name}` : 'Dashboard'
  const getTokens = async () => {
    if(orgVm.isDemoAccount){
      setLoading(false)
      setTokens(undefined)
      return
    }
    setLoading(true)
    setError(undefined)
    const res = await getDashboardTokens()
    setLoading(false)
    if (res.isLeft()) {
      setError(res.left?.message ?? 'Error loading dashboard.')
      return
    }
    setTokens(res.right)
  }

  useEffect(() => {
    getTokens()
  }, [])

  return (
    <DashboardPageContext.Provider
      value={{
        title,
        error,
        loading,
        tokens,
        getTokens,
      }}
    >
      {children}
    </DashboardPageContext.Provider>
  )
}
