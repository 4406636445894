export class Failure {
  readonly message: string
  readonly statusCode?: number
  readonly errors?: any

  constructor(message?: string, statusCode?: number, errors?: any) {
    this.message = message ?? 'Something went wrong.'
    this.statusCode = statusCode
    this.errors = errors ?? {}
  }
}
