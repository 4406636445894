import React from 'react'
import { CorrectiveActionsPageViewModelProps } from './CorrectiveActionsViewModel'
import { EdifyButton } from '../../components/buttons'
import { Box } from '@mui/material'

import { EdifySelect } from '../../components/form'
import { searchProjects } from '../../../domain/domain'
import EdifyDateRange from '../../components/form/EdifyDateRange/EdifyDateRange'
import { ECorrectiveActionStatus } from '../../../domain/interfaces/ICorrectiveAction'
import EdifySearchDropdown, {
  ISearchResult,
} from '../../components/form/EdifySearch/EdifySearchDropdown'
import EdifyMenuItem from '../../components/form/EdifyMenuItem'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { searchUsers } from '../../../data/data'
import GlobalSearch from '../../components/form/EdifySearch/GlobalSearch'
import { usePublicOrganizationProvider } from '../../../providers/PublicOrganizationProvider'

interface ICorrectiveActionPageFilterProps {
  vm: CorrectiveActionsPageViewModelProps
}

// TODO match with ENUM state, need to clear with BE folk
const caStatusOptions = [
  { value: 'all', name: 'All Corrective Actions' },
  { value: 'resolved', name: 'Closed Corrective Actions' },
  { value: 'unresolved', name: 'Open Corrective Actions' },
  { value: 'under_review', name: 'Under Review Corrective Actions' },
]

const CorrectiveActionFilterOld: React.FC<ICorrectiveActionPageFilterProps> = ({
  vm,
}) => {
  const { query } = vm.queryHelper
  const { status, dateRange } = query
  const { isContractor, getHierarchyName } = useOrganizationProvider()
  const { level1Name, level0Name } = usePublicOrganizationProvider()

  // EVENT HANDLER FOR PROJECT SEARCH picked
  const projectChanged = (searchItems: ISearchResult[]) => {
    // cleared search
    if (searchItems.length == 0) {
      const newQuery = { ...query }
      delete newQuery.project
      vm.fetchCorrectiveActions(0, newQuery)
      return
    }
    vm.fetchCorrectiveActions(0, { ...query, project: searchItems[0].id })
  }

  const responsiblePartyChanged = (searchItems: ISearchResult[]) => {
    // cleared search
    if (searchItems.length == 0) {
      const newQuery = { ...query }
      delete newQuery.responsibleParty
      vm.fetchCorrectiveActions(0, newQuery)
      return
    }
    vm.fetchCorrectiveActions(0, {
      ...query,
      responsibleParty: searchItems[0].id,
    })
  }

  // EVENT HANDLER FOR STATUS SEARCH
  const statusChanged = (e: any) => {
    vm.fetchCorrectiveActions(0, {
      ...query,
      status: e.target.value as ECorrectiveActionStatus,
    })
  }

  // EVENT HANDLER FOR DATE RANGE
  const dateChanged = (dateRange: string) => {
    vm.fetchCorrectiveActions(0, {
      ...query,
      dateRange,
    })
  }

  const onGlobalSearchSelect = (selectedId: string, selectedValue:string) => {
    const newQuery = { ...query }
    delete newQuery.searchKey
    vm.fetchCorrectiveActions(0, {
      ...newQuery,
      filterId: selectedId,
      filterValue: selectedValue
    })
  }
  const onGlobalSearchKey = (key: string) => {
    vm.fetchCorrectiveActions(0, {
      ...query,
      ...(key !== '' && { searchKey: key }),
    })
  }

  // for the X in the global search
  const onGlobalSearchClear = async () => {
    const newQuery = { ...query }
    delete newQuery.searchKey
    delete newQuery.filterId
    delete newQuery.filterValue
    await vm.fetchCorrectiveActions(0, {
      ...newQuery,
    })
  }
  const l1Name = level1Name
  const projectName = level0Name
  const { searchKey, filterValue } = vm.queryHelper.query

  return (
    <>
      <Box
        data-testid={'CorrectiveActionsPage'}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Box style={{ display: 'flex' }}>
          <EdifySelect
            width={260}
            sx={{ marginRight: '16px' }}
            dropDownItems={caStatusOptions}
            onChange={statusChanged}
            renderMenuItem={(item) => {
              return (
                <EdifyMenuItem key={item.value} value={item.value}>
                  {item.name}
                </EdifyMenuItem>
              )
            }}
            value={status || 'all'}
          />
          {!isContractor && (
            <>
              <Box style={{ display: 'flex', height: '52px' }}>
                <GlobalSearch
                  globalClear={vm.isGlobalClearing}
                  showBadge
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      width: '300px',
                      padding: '8px 0px 8px 9px !important',
                      paddingRight: '9px !important',
                      marginRight: '16px',
                    },
                  }}
                  entities='users,projects,levels,locations,contractors'
                  // placeholder={`Author, Contractor, ${l1Name}, ${projectName}`}
                  placeholder='Search'
                  {...(searchKey ? { defaultValue: searchKey } : {})}
                  {...(filterValue ? { defaultValue: filterValue } : {})}
                  l1Name={l1Name}
                  projectName={projectName}
                  onGlobalSearchSelect={onGlobalSearchSelect}
                  onGlobalSearchClear={onGlobalSearchClear}
                  onGlobalSearchWithText={onGlobalSearchKey}
                />
              </Box>
            </>
          )}
          <EdifyDateRange dateRange={dateRange || ''} onChange={dateChanged} />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          margin: '24px 0',
        }}
      >
        <EdifyButton
          onClick={() => vm.clearFilters(true)}
          title='Clear Filters'
          primary
          noBackground
        />
      </Box>
    </>
  )
}

export default CorrectiveActionFilterOld
