import React, { useEffect, useState } from 'react'
import { searchLevels, searchProjects } from '../../../domain/domain'
import { useNavigate, useParams } from 'react-router-dom'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { Box, Input } from '@mui/material'
import { EdifyButton } from '../../components/buttons'
import {
  EdifyFieldLabel,
  EdifyFormField,
  EdifySelect,
} from '../../components/form'

import { Controller, FieldErrors, Resolver, useForm } from 'react-hook-form'
import { phoneInputFormatter } from '../../../core/utils/input-formatters/InputFormatters'
import FormErrorText from '../../components/form/FormErrorText'
import { ETradeArray, IContact } from '../../../domain/interfaces/IContact'
import EdifySearchDropdown, {
  ISearchResult,
} from '../../components/form/EdifySearch/EdifySearchDropdown'
import ProfileContainer from '../../components/profile/ProfileContainer'
import { ProfileHeader } from '../../components/profile/ProfileHeader'

import { useContactPageViewModel, contactParams } from './ContactPageViewModel'
import EdifyMenuItem from '../../components/form/EdifyMenuItem'

import {
  OrganizationProviderProps,
  useOrganizationProvider,
} from '../../../providers/OrganizationProvider'
import { ERole } from '../../../domain/interfaces/IRole'
import usePermission from '../../../core/hooks/usePermission'
import { getContactById } from '../../../data/repositories/contacts/ContactsRepository'

const default_contact: any = {
  id: '',
  email: '',
  phone: '',
  projects: [],
  levels: [],
  trade: '',
}

// react-hook-form state
type ProfileInputs = {
  fullName: string
  email: string
  phone?: string | undefined
  projects?: string[]
  levels?: string[]
  trade?: string
  title?: string
  vendorIdNumber?: string
}
interface ContactProfileFormProps {
  id?: string | undefined
  onCancel?: () => void
  initContact?: IContact | undefined
}

export const ContactsForm: React.FC<ContactProfileFormProps> = ({
  // id,
  onCancel,
}) => {
  const [contact, setContact] = useState<IContact | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [imageURL, setImageURL] = useState<string | null>(null)
  const [projects, setProjects] = useState<ISearchResult[]>([])
  const [levels, setLevels] = useState<ISearchResult[]>([])
  const [loadedProfile, setLoadedProfile] = useState<boolean>(false)
  const [trade, setTrade] = useState<string>()
  const [isResetting, setIsReseting] = useState<boolean>(false)
  const vm = useContactPageViewModel()
  const {
    getHierarchyName,
    allLevels,
    allProjects,
    titles,
  }: OrganizationProviderProps = useOrganizationProvider()
  const { contractorId, contactId } = useParams()
  const id = contactId

  const projectName = getHierarchyName(0, true)
  const levelName = getHierarchyName(1, true)

  const canEdit = usePermission(ERole.ContractorEdit)

  /**
   * Validates the input values for the person's profile form.
   */
  const resolver: Resolver<ProfileInputs> = async (values) => {
    const errors: FieldErrors<ProfileInputs> = {}

    if (!values.fullName) {
      errors.fullName = {
        type: 'required',
        message: 'Name is required',
      }
    }
    if (!values.email) {
      errors.email = {
        type: 'required',
        message: 'Email is required',
      }
    }

    if (values.phone) {
      const phoneNumberPattern = /^\d{3}-\d{3}-\d{4}$/
      if (!phoneNumberPattern.test(values.phone)) {
        errors.phone = {
          type: 'pattern',
          message: 'Invalid phone number',
        }
      }
    }
    return {
      values,
      errors,
    }
  }
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<ProfileInputs>({ resolver, mode: 'onSubmit' })

  /**
   * Handles the form submission when the form is valid.
   * will run resolver validations before submitting
   * @param {Object} data - The form data submitted by the contact.
   * @returns {void}
   */
  const onSubmit = handleSubmit(async (data) => {
    // react-hook-form will check for errors here and return
    // if there are any
    // if we get here validations have passed
    const formData: contactParams = {
      fullName: data.fullName,
      email: data.email,
      phone: data.phone == '' ? undefined : data.phone,
      trade: data.trade ?? '',
      title: data.title ?? '',
      vendorIdNumber: data.vendorIdNumber ?? '',
      projectIds: projects.map((p) => p.id),
      levelIds: levels.map((l) => l.id),
    }
    setLoading(true)
    if (id) {
      vm.updateContact(formData, id)
    } else {
      vm.createContact(formData, contractorId!, clearFields)
    }
    setLoading(false)
  })
  const clearFields = () => {
    setIsReseting(true)
    reset()
    setProjects([])
    setLevels([])

    setTimeout(() => {
      setIsReseting(false)
    }, 100)
  }

  const handlePhoneInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target
    const formattedValue = phoneInputFormatter(value)
    setValue('phone', formattedValue, {
      shouldValidate: value.length > 11 || value.length == 0,
    })
  }

  // Sets Initial values of the form
  const loadContact = async () => {
    setLoadedProfile(false)
    let contactData
    // new Contact
    console.log({ id })
    if (!id) {
      contactData = default_contact
    } else {
      const res = await getContactById(id)
      if (res.right) {
        contactData = res.right
      }

      setProjects(contactData?.projects ?? [])
      setLevels(contactData?.levels ?? [])
      setTrade(contactData?.trade)
    }

    setContact(contactData)
    console.log({ contactData })
    const defaultValues: ProfileInputs = {
      fullName: contactData?.fullName ?? '',
      email: contactData?.email ?? '',
      vendorIdNumber: contactData?.vendorIdNumber ?? '',
      phone:
        contactData && contactData.phone
          ? phoneInputFormatter(contactData.phone)
          : '',
      trade: contactData?.trade ?? '',
      projects: contactData?.projects,
    }
    setProjects(contactData?.projects)
    setLevels(contactData?.levels)
    reset({ ...defaultValues })
    setLoadedProfile(true)
  }

  const projectSelected = (projects: ISearchResult[]) => {
    setProjects(projects)
  }
  const levelSelected = (levels: ISearchResult[]) => {
    setLevels(levels)
  }

  useEffect(() => {
    loadContact()
  }, [])

  const navigate = useNavigate()

  // TODO: ACTUALLY DELETE THE IMAGE FROM THE SERVER
  // const imageUploaded = async (imageURL: string | null) => {
  //   setLoadedContact(false)
  //   setLoading(true)
  //   setServerError(undefined)
  //   const res = await adminEditContact(
  //     {
  //       imageURL: imageURL,
  //     },
  //     Contact!.id,
  //   )
  //   setImageURL(imageURL ?? null)
  //   setLoading(false)
  //   handleUploadResponse(res, false)
  // }
  if (isResetting) return <div>Grabbing Form...</div>

  return (
    <form onSubmit={onSubmit}>
      <InnerPageContainer innerPageLoading={!loadedProfile}>
        {vm.formError && (
          <InnerPageContainer innerPageError={vm.formError}>
            {/* TODO: Add close logic, this is done in another story */}
          </InnerPageContainer>
        )}

        <Box sx={{ width: '100%', padding: '0px 24px' }}>
          <Box style={{ display: 'flex', flex: 1 }}>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel label='FULL NAME' />
              <Input
                data-testid='fullName'
                disableUnderline
                placeholder='Enter name'
                className={`form-control ${errors?.fullName ? 'error' : ''}`}
                {...register('fullName')}
              />
              {errors?.fullName && (
                <FormErrorText>{errors.fullName.message}</FormErrorText>
              )}
            </EdifyFormField>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel label='CONTACT ID' />
              <Input
                data-testid='title'
                // defaultValue={vm.contact?.vendorIdNumber ?? undefined}
                disableUnderline
                placeholder='Enter ID'
                className={`form-control ${
                  errors?.vendorIdNumber ? 'contact id' : ''
                }`}
                {...register('vendorIdNumber')}
              />
              {errors?.vendorIdNumber && (
                <FormErrorText>{errors.vendorIdNumber.message}</FormErrorText>
              )}
            </EdifyFormField>
          </Box>
        </Box>
        <Box sx={{ width: '100%', padding: '0px 24px' }}>
          <Box style={{ display: 'flex', flex: 1 }}>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel label='WORK EMAIL' disabled={id != undefined} />
              <Input
                disabled={id != undefined}
                data-testid='Email'
                disableUnderline
                placeholder='sample@email.com'
                className={'form-control'}
                {...register('email')}
              />
              {errors?.email && (
                <FormErrorText>{errors.email.message}</FormErrorText>
              )}
            </EdifyFormField>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel label='PHONE NUMBER' />
              <Controller
                name='phone'
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    data-testid='PhoneNumber'
                    disableUnderline
                    placeholder='000-000-0000'
                    className={`form-control ${errors?.phone ? 'error' : ''}`}
                    value={field.value}
                    onChange={handlePhoneInputChange}
                  />
                )}
              />
              {errors?.phone && (
                <FormErrorText>{errors.phone.message}</FormErrorText>
              )}
            </EdifyFormField>
          </Box>
          <Box sx={{ width: '100%', padding: '0px' }}>
            <Box style={{ display: 'flex', flex: 1 }}>
              <EdifyFormField>
                <EdifyFieldLabel label='TITLE' />
                <Controller
                  name='title'
                  control={control}
                  defaultValue={contact?.title ?? ''}
                  render={({ field }) => (
                    <EdifySelect
                      {...field}
                      width={464}
                      disabled={titles == undefined || titles.length == 0}
                      placeholder='Select'
                      sx={{ marginRight: '24px', flex: 1 }}
                      dropDownItems={titles ?? []}
                      renderMenuItem={(item: any) => (
                        <EdifyMenuItem key={item} value={item}>
                          {item}
                        </EdifyMenuItem>
                      )}
                    />
                  )}
                />
                {errors?.title && (
                  <FormErrorText>{errors.title.message}</FormErrorText>
                )}
              </EdifyFormField>
            </Box>
          </Box>
          <EdifyFormField sx={{ marginRight: '24px' }}>
            <EdifyFieldLabel label='TRADE' />
            <Controller
              name='trade'
              control={control}
              defaultValue={contact?.trade ?? ''}
              render={({ field }) => (
                <EdifySelect
                  {...field}
                  width={464}
                  placeholder='Select'
                  sx={{ marginRight: '24px', flex: 1 }}
                  dropDownItems={ETradeArray}
                  // {...register('trade')}
                  renderMenuItem={(item: string) => (
                    <EdifyMenuItem key={item} value={item}>
                      {item}
                    </EdifyMenuItem>
                  )}
                />
              )}
            />
            {errors?.trade && (
              <FormErrorText>{errors.trade.message}</FormErrorText>
            )}
          </EdifyFormField>
          <EdifyFormField sx={{ marginRight: '24px' }}>
            <EdifyFieldLabel
              label={`SELECT EXISTING ${levelName.toUpperCase()}`}
            />
            <EdifySearchDropdown
              data-testid='LevelsSearch'
              defaultItems={allLevels.map((p) => {
                return { id: p.id, name: p.name }
              })}
              multiSelect
              pillListWidth={'100%'}
              width={464}
              onSelect={levelSelected}
              searchFunction={searchLevels}
              initialSelectedItems={levels ?? []}
            />
          </EdifyFormField>
          <EdifyFormField sx={{ marginRight: '24px' }}>
            <EdifyFieldLabel
              label={`SELECT EXISTING ${projectName.toUpperCase()}`}
            />
            <EdifySearchDropdown
              data-testid='ProjectSearch'
              defaultItems={allProjects.map((p) => {
                return { id: p.id, name: p.name }
              })}
              multiSelect
              pillListWidth={'100%'}
              width={464}
              onSelect={projectSelected}
              searchFunction={searchProjects}
              initialSelectedItems={projects ?? []}
            />
          </EdifyFormField>
        </Box>
      </InnerPageContainer>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '20px',
        }}
      >
        <EdifyButton
          noBackground
          title='Cancel'
          onClick={onCancel ? onCancel : () => navigate(-1)}
        />
        <Box>
          <EdifyButton
            disabled={Object.keys(errors).length > 0 || loading}
            type='submit'
            data-testid={'SubmissionButton'}
            primary
            title={'Save Changes'}
          />
        </Box>
      </Box>
    </form>
  )
}

export default ContactsForm
