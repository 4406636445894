import { Box, Grid, Input } from '@mui/material'
import React from 'react'
import { EdifyFieldLabel } from '../../form'
import { EdifyFormField } from '../../form/shared/EdifyFormField'
import { EdifyButton } from '../../buttons'
import InnerPageContainer from '../../inner-page-container/InnerPageContainer'
import { EParagraph, ETypography } from '../../fonts/ETypography'
import EdifyDatePicker from '../../form/EdifyDatePicker'
import EdifyAttachmentViewer from '../../form/EdifyImage/EdifyImageViewer'
import { AppColors } from '../../../Theme'
import {
  ECorrectiveActionStatus,
  ICorrectiveActionImage,
} from '../../../../domain/interfaces/ICorrectiveAction'
import {
  ECorrectiveActionViewMode,
  ICorrectiveActionPageViewModelProps,
  useCorrectiveActionPageViewModel,
} from '../../../pages/corrective-action/CorrectiveActionPageViewModel'
import { dateToMMMDYYYY } from '../../../../core/core'
import parse from 'html-react-parser'
import { LevelBadge } from '../../../pages/location/Node1'
import { RightArrowIcon } from '../../../../core/components/icons/RightArrowIcon'

interface ICorrectiveActionComment {
  viewMode?: ECorrectiveActionViewMode
  title?: string
  subTitle?: string
  subSubTitle?: string
  answer?: string
  comment: string
  images: ICorrectiveActionImage[]
  contractorName?: string
  dueDate?: string | null
  children?: React.ReactNode
  initialCA?: boolean
  status?: string
  contactPersonName?: string
  responsiblePartyName?: string
  subLocations?: (string | undefined)[]
  location?: string | undefined
  locationHierarchies?: any
  locationId?: string | undefined
  isLocationEnabled?: boolean
  isSubLocationEnabled?: boolean
}

const CorrectiveActionComment: React.FC<ICorrectiveActionComment> = ({
  title,
  answer,
  comment = 'No Description Provided.',
  images,
  subTitle,
  subSubTitle,
  contractorName = 'No Contractor Provided',
  dueDate = null,
  children,
  initialCA,
  status,
  contactPersonName,
  responsiblePartyName,
  subLocations,
  location,
  locationHierarchies,
  isLocationEnabled = false,
  isSubLocationEnabled = false,
}) => {
  const vm: ICorrectiveActionPageViewModelProps =
    useCorrectiveActionPageViewModel()
  const {
    correctiveAction,
    isShea,
    contractor,
    taskValue,
    locationValue,
    contactEmail,
    contactName,
    contactPhone,
    contactRole,
    lotName,
    address
  } = vm
  const getLot = (location: string | undefined, address: string | undefined) => { 
    if (!location && !address) return ''
    const locationValue = location ?? ''
    const addressValue = address ?? ''
    return `${locationValue} - ${addressValue}`
  }

  const isClosed = status == ECorrectiveActionStatus.CLOSED
  const renderHeader = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'spaceAround',
          flexDirection: 'column',
        }}
      >
        <ETypography font='HXXSDS' color='gray700'>
          {title ?? 'Corrective Action'}
        </ETypography>
        <ETypography color='neutral950' font='SS' sx={{ fontSize: '16px' }}>
          {initialCA ? subTitle : subTitle ?? ''}
        </ETypography>
        <ETypography color='neutral950' font='SS' sx={{ fontSize: '16px' }}>
          {initialCA ? subSubTitle : subSubTitle ?? ''}
        </ETypography>
      </Box>
    )
  }
  return (
    <InnerPageContainer
      collapsible={!initialCA}
      header={renderHeader()} // Why is the padding being Changed here in Figma?
      sx={{ padding: '24px' }}
      renderRightItems={() =>
        initialCA ? (
          <EdifyButton
            secondary
            disabled
            title={status ?? 'Open'}
            textStyle={{
              color: isClosed ? AppColors.baseWhite : AppColors.gray700,
            }}
            buttonStyle={{
              backgroundColor: isClosed
                ? AppColors.success800
                : AppColors.neutral700,
              height: '44px',
              whiteSpace: 'nowrap',
              minWidth: 'max-content',
            }}
          />
        ) : (
          <></>
        )
      }
    >
      <>
        {answer && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              marginTop: '24px',
              gap: '24px',
            }}
          >
            <EdifyButton
              secondary
              disabled
              buttonStyle={{
                flex: 1,
                border: `1px solid ${AppColors.neutral800}`,
                background: AppColors.neutral600,
              }}
              textStyle={{ color: AppColors.gray700 }}
              noBackground
              title={answer || 'No'}
            />
            <EdifyButton
              secondary
              disabled
              buttonStyle={{
                flex: 1,
                border: `1px solid ${AppColors.neutral600}`,
                background: AppColors.baseWhite,
              }}
              textStyle={{ color: AppColors.gray700 }}
              noBackground
              title={'Yes'}
            />
          </Box>
        )}
        {!isShea ||
          (!initialCA && (
            <>
              {images.length > 0 && (
                <Grid item xs={12}>
                  <EdifyFormField>
                    <EdifyFieldLabel label='Images' />
                    <Box
                      style={{
                        display: 'flex',
                        gap: '24px',
                        flexWrap: 'wrap',
                        marginBottom:
                          (!dueDate || !contractorName) && children
                            ? '24px'
                            : '',
                      }}
                    >
                      {images.map((image, i) => {
                        return (
                          <EdifyAttachmentViewer
                            key={image.id ?? image}
                            attachment={image.path ?? image}
                            name={image.title ?? `Image ${i + 1}`}
                            description={
                              image.description ?? 'No description provided.'
                            }
                          />
                        )
                      })}
                    </Box>
                  </EdifyFormField>
                </Grid>
              )}

              <EdifyFormField>
                <EdifyFieldLabel label={initialCA ? 'DESCRIPTION' : 'NOTES'} />
                <EParagraph>{parse(comment)}</EParagraph>
                {/* <EParagraph>{JSON.stringify(comment + '', null,2)}</EParagraph> */}
              </EdifyFormField>
            </>
          ))}
      </>
      {!isShea && images.length > 0 && (
        <EdifyFormField>
          <EdifyFieldLabel label='Images' />
          <Box
            style={{
              display: 'flex',
              gap: '24px',
              flexWrap: 'wrap',
              marginBottom:
                (!dueDate || !contractorName) && children ? '24px' : '',
            }}
          >
            {images.map((image, i) => {
              return (
                <EdifyAttachmentViewer
                  key={image.id ?? image}
                  attachment={image.path ?? image}
                  name={image.title ?? `Image ${i + 1}`}
                  description={image.description ?? 'No description provided.'}
                />
              )
            })}
          </Box>
        </EdifyFormField>
      )}
      {!isShea && (
        <Grid item xs={12}>
          <EdifyFormField>
            <EdifyFieldLabel label={initialCA ? 'DESCRIPTION' : 'NOTES'} />
            <EParagraph>{parse(comment)}</EParagraph>
          </EdifyFormField>
        </Grid>
      )}
      {/* TEMP FOR SHEA HOME ONLY */}
      {initialCA && isShea && (
        <Box>
          <Grid
            container
            spacing={2}
            style={{
              marginBottom: children ? '24px' : '0',
            }}
          >
            <CALabel
              title='Division'
              text={correctiveAction?.levelName ?? ''}
            />
            <CALabel
              title='Community'
              text={correctiveAction?.projectName ?? ''}
            />
            <CALabel title='Lot #' text={getLot(location, address)} />
            <CALabel title='Location' text={locationValue ?? ''} />
            <CALabel title='Task' text={taskValue ?? ''} />
            <CALabel
              title='Trade'
              text={correctiveAction?.contractorName ?? ''}
            />

            {contactRole !== undefined ? (
              <CALabel
                title='Contact Person Role'
                text={contactRole ?? 'Contractor'}
              />
            ) : (
              <Grid item xs={6} />
            )}
            <Grid item xs={6}>
              <EdifyFormField sx={{ flex: 1 }}>
                <EdifyFieldLabel label={'Contact Person Info'} />
                <ETypography font='MM' color='gray700'>
                  {contactName ?? ''}
                </ETypography>
                <ETypography font='MM' color='gray700'>
                  {contactEmail ?? ''}
                </ETypography>
                <ETypography font='MM' color='gray700'>
                  {contactPhone ?? ''}
                </ETypography>
              </EdifyFormField>
            </Grid>
            <Grid item xs={12}>
              <EdifyFormField>
                <EdifyFieldLabel label={initialCA ? 'DESCRIPTION' : 'NOTES'} />
                <EParagraph>{parse(comment)}</EParagraph>
              </EdifyFormField>
            </Grid>
            {images.length > 0 && (
              <Grid item xs={12}>
                <EdifyFormField>
                  <EdifyFieldLabel label='Images' />
                  <Box
                    style={{
                      display: 'flex',
                      gap: '24px',
                      flexWrap: 'wrap',
                      marginBottom:
                        (!dueDate || !contractorName) && children ? '24px' : '',
                    }}
                  >
                    {images.map((image, i) => {
                      return (
                        <EdifyAttachmentViewer
                          key={image.id ?? image}
                          attachment={image.path ?? image}
                          name={image.title ?? `Image ${i + 1}`}
                          description={
                            image.description ?? 'No description provided.'
                          }
                        />
                      )
                    })}
                  </Box>
                </EdifyFormField>
              </Grid>
            )}

            <CALabel
              title='Due Date'
              text={dueDate ? dateToMMMDYYYY(dueDate) : 'NA'}
            />
          </Grid>
        </Box>
      )}
      {initialCA && !isShea && (
        <Box>
          <Box
            style={{
              display: 'flex',
              gap: '24px',
              flexWrap: 'wrap',
              marginBottom: children ? '24px' : '0',
            }}
          >
            <EdifyFormField sx={{ flex: 1 }}>
              {/* What do we want to say here */}
              <EdifyFieldLabel
                label={contractorName ? 'Contractor' : 'Responsible Party'}
              />
              <ETypography font='MM' color='gray700'>
                {contractorName
                  ? contractorName
                  : responsiblePartyName
                    ? responsiblePartyName
                    : 'NA'}
              </ETypography>
              {/* <Input
              disabled
              data-testid='ResponsibleParty'
              disableUnderline
              placeholder={contractorName}
              className={'form-control'}
              value={contractorName}
            /> */}
            </EdifyFormField>

            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label='Contact' />
              <ETypography font='MM' color='gray700'>
                {contactPersonName ? contactPersonName : 'NA'}
              </ETypography>
              {/* <Input
                disabled
                data-testid='ResponsibleParty'
                disableUnderline
                placeholder={contactPersonName}
                className={'form-control'}
                value={contactPersonName}
              /> */}
            </EdifyFormField>

            <EdifyFormField sx={{ flex: 1 }}>
              <EdifyFieldLabel label='Due Date' />
              <ETypography font='MM' color='gray700'>
                {dueDate ? dateToMMMDYYYY(dueDate) : 'NA'}
              </ETypography>
            </EdifyFormField>
          </Box>
          {/* SubLocation SEction */}
          {isLocationEnabled && (
            <Box
              style={{
                display: 'flex',
                gap: '24px',
                flexDirection: 'row',
                flexWrap: 'wrap',
                marginBottom: children ? '24px' : '0',
              }}
            >
              <EdifyFormField sx={{ flex: 1 }}>
                <EdifyFieldLabel label='Location' />
                <ETypography font='MM' color='gray700'>
                  {location ?? 'NA'}
                </ETypography>
              </EdifyFormField>
              {isSubLocationEnabled && (
                <EdifyFormField sx={{ flex: 4 }}>
                  <EdifyFieldLabel label='Sub Locations' />
                  {subLocations && subLocations.length > 0 ? (
                    <ETypography font='MM' color='gray700'>
                      {subLocations?.map((s, i) => {
                        return (
                          <span key={i}>
                            {s}
                            <LevelBadge
                              level={i}
                              label={
                                locationHierarchies[i]
                                  ? locationHierarchies[i].name
                                  : ''
                              }
                            />
                            {i < subLocations.length - 1 && (
                              <RightArrowIcon
                                sx={{ marginLeft: '12px', marginRight: '12px' }}
                              />
                            )}
                          </span>
                        )
                      })}
                    </ETypography>
                  ) : (
                    <ETypography font='MM' color='gray700'>
                      NA
                    </ETypography>
                  )}
                </EdifyFormField>
              )}
            </Box>
          )}
        </Box>
      )}
      {children && children}
    </InnerPageContainer>
  )
}

interface ICALabel {
  title: string
  text: string
}
const CALabel: React.FC<ICALabel> = ({ title, text }) => {
  return (
    <Grid item xs={6}>
      <EdifyFormField>
        <EdifyFieldLabel label={title} />
        <ETypography font='MM' color='gray700'>
          {text}
        </ETypography>
      </EdifyFormField>
    </Grid>
  )
}

export default CorrectiveActionComment
