// PersonProfileForm.tsx
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Input, Box, SxProps } from '@mui/material'
import { EdifyFieldLabel, EdifyFormField } from '../../components/form'
import { phoneInputFormatter } from '../../../core/utils/input-formatters/InputFormatters'
import FormErrorText from '../../components/form/FormErrorText'

import { personUpdate } from '../../../data/data'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import { getCurrentPerson } from '../../../domain/domain'
import { IVerifyOTPPayload } from '../../../domain/interfaces/IUserDetails'
import { EdifyButton } from '../../components/buttons'
import NotificationsSettings from '../../components/forms/PersonProfileForm/NotificationsSettings'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'

type ProfileInputs = {
  fullName: string
  email: string
  phone: string
}

interface IProfileFormProps {
  setUserName: (name: string) => void
  uploadImage?: string | null
  sx?: SxProps
}

export const UserPersonForm: React.FC<IProfileFormProps> = ({
  setUserName,
  uploadImage,
  sx,
}) => {
  const { resetProfileTileCallback } = useOrganizationProvider()
  const [person, setPerson] = useState<IVerifyOTPPayload | undefined>(undefined)
  const [emailChecked, setEmailChecked] = useState<boolean>(true)
  const [smsChecked, setSMSChecked] = useState<boolean>(false)
  const [SMSTermsChecked, setSMSTermsChecked] = useState<boolean>(false)
  const [SMSTermsChanged, setSMSTermsChanged] = useState<boolean>(false)
  const [notificationError, setNotificationError] = useState<
    string | undefined
  >(undefined)

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ProfileInputs>({
    mode: 'onSubmit',
  })

  const onSubmit = handleSubmit(async (data) => {
    const res = await personUpdate(
      {
        ...(data.phone !== '' && { phone: data.phone }),
        imageURL: uploadImage ?? '',
        fullName: data.fullName,
        smsNotifEnabled: smsChecked,
        emailNotifEnabled: emailChecked,
      },
      person?.id ?? '',
    )

    if (res.isLeft()) {
      ErrorToast({ title: 'Error saving new changes.' })
    } else {
      SuccessToast({ title: 'Profile Successfully Update.' })
      // window.location.reload()
      resetProfileTileCallback(data.fullName, uploadImage ?? '' )
      setUserName(data.fullName)
    }
  })

  const handlePhoneInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target
    const formattedValue = phoneInputFormatter(value)
    setValue('phone', formattedValue)
  }

  useEffect(() => {
    if (!emailChecked && !smsChecked) {
      setNotificationError('You must select at least one of these options')
    } else {
      setNotificationError(undefined)
    }
  }, [emailChecked, smsChecked])
  useEffect(() => {
    const personData = getCurrentPerson()
    setPerson(personData ?? undefined)
    setEmailChecked(personData?.emailNotifEnabled ?? true)
    setSMSChecked(personData?.smsNotifEnabled ?? false)

    setValue('fullName', personData?.fullName ?? '')
    setValue('email', personData?.email ?? '')
    setValue(
      'phone',
      personData?.phone ? phoneInputFormatter(personData?.phone) : '',
    )
  }, [setValue])

  return (
    <InnerPageContainer headerTitle='Profile Settings' sx={{ ...sx }}>
      <form onSubmit={onSubmit}>
        <Box>
          <Box style={{ display: 'flex', flex: 1 }}>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel required label='NAME' />
              <Input
                disableUnderline
                placeholder='Enter name'
                {...register('fullName', { required: 'Name is required' })}
                className={`form-control ${errors?.fullName ? 'error' : ''}`}
              />
              {errors.fullName && (
                <FormErrorText>{errors.fullName.message}</FormErrorText>
              )}
            </EdifyFormField>
          </Box>
          <Box style={{ display: 'flex', flex: 1 }}>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel required label='EMAIL' />
              <Input
                disableUnderline
                disabled
                placeholder='Enter email'
                {...register('email', { required: 'Email is required' })}
                className={`form-control ${errors?.email ? 'error' : ''}`}
              />
              {errors.email && (
                <FormErrorText>{errors.email.message}</FormErrorText>
              )}
            </EdifyFormField>
            <EdifyFormField sx={{ flex: 1, marginRight: '24px' }}>
              <EdifyFieldLabel label='PHONE NUMBER' />
              <Controller
                name='phone'
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    disableUnderline
                    placeholder='000-000-0000'
                    onChange={handlePhoneInputChange}
                    className={`form-control ${errors?.phone ? 'error' : ''}`}
                  />
                )}
              />
              {errors.phone && (
                <FormErrorText>{errors.phone.message}</FormErrorText>
              )}
            </EdifyFormField>
          </Box>

          <NotificationsSettings
            setEmailChecked={setEmailChecked}
            setSMSChecked={setSMSChecked}
            emailChecked={emailChecked}
            smsChecked={smsChecked}
            setSMSTermsChecked={setSMSTermsChecked}
            SMSTermsChecked={SMSTermsChecked}
            setSMSTermsChanged={setSMSTermsChanged}
            SMSTermsChanged={SMSTermsChanged}
            notificationError={notificationError}
          />
          {notificationError && (
            <FormErrorText>{notificationError}</FormErrorText>
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '20px',
            }}
          >
            <EdifyButton
              disabled={notificationError != undefined}
              type='submit'
              primary
              title='Save Changes'
            />
          </Box>
        </Box>
      </form>
    </InnerPageContainer>
  )
}

export default UserPersonForm
