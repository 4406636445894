import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/page/PageContainer'
import {
  CorrectiveActionPageViewModel,
  ICorrectiveActionPageViewModelProps,
  useCorrectiveActionPageViewModel,
} from './CorrectiveActionPageViewModel'
import CorrectiveActionFormV2 from '../../components/forms/CorrectiveActionForm/CorrectiveActionFormV2'
import CorrectiveActionComment from '../../components/forms/CorrectiveActionForm/CorrectiveActionComment'
import { ICorrectiveAction } from '../../../domain/interfaces/ICorrectiveAction'
import { EdifyButton } from '../../components/buttons'
import { Box, Button, Link } from '@mui/material'
import { dateTodayMMMDYYYYatTime } from '../../../core/core'
import { ETypography } from '../../components/fonts/ETypography'
import { getCurrentUser, getLocationById } from '../../../domain/domain'
import parse from 'html-react-parser'
import { locationKeys } from '../location/SubLocationViewModel'
import { ISubLocationHierarchyName } from '../../../domain/interfaces/ILocation'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { useNavigate } from 'react-router-dom'
import { ROUTE_SUBMISSIONS } from '../submissions/SubmissionsPage'
import { AppColors, AppFonts } from '../../Theme'
import { ROUTE_CORRECTIVE_ACTIONS } from '../corrective-actions/CorrectiveActionsPage'
import { getSubmissionById } from '../../../data/data'
import usePermission from '../../../core/hooks/usePermission'

const _CorrectiveActionPage: React.FC = () => {
  const vm: ICorrectiveActionPageViewModelProps =
    useCorrectiveActionPageViewModel()
  const { flags } = useOrganizationProvider()
  const [showV2Form, setShowV2Form] = useState<boolean>(false)
  const [apiCall, setApiCall] = useState('comment')
  const [locationError, setLocationError] = useState<string | undefined>()
  const [locationLoading, setLocationLoading] = useState<boolean>()
  const [locationHierarchies, setLocationHierarchies] = useState<
    ISubLocationHierarchyName[]
  >([])
  const user = getCurrentUser()
  const navigate = useNavigate()
  const [canViewSubmission, setCanViewSubmission] = useState<boolean>(false)
  // const canClose = usePermission()

  const getLocation = async (id: string) => {
    setLocationError(undefined)
    setLocationLoading(true)
    const res = await getLocationById(id)
    setLocationLoading(false)

    if (res.isLeft()) {
      setLocationError('Error loading location')
    }

    const hierarchies = locationKeys.map((key, index) => {
      return {
        id: index + '',
        name: res.right?.[key] ?? '',
        level: index + 1,
      }
    })
    setLocationHierarchies(hierarchies)
  }
  useEffect(() => {
    if (vm.correctiveAction && vm.correctiveAction?.locationId)
      getLocation(vm.correctiveAction?.locationId)
  }, [vm.correctiveAction])
  const renderInitialCorrectiveActionSection = () => {
    const {
      question,
      images,
      dueDate,
      notes,
      contractorName,
      answer,
      authorName,
      responsiblePartyName,
      contactPersonName,
      location,
      createdAt = '',
      sublocationLevel1Name,
      sublocationLevel2Name,
      sublocationLevel3Name,
      sublocationLevel4Name,
      sublocationLevel5Name,
    } = vm.correctiveAction as ICorrectiveAction
    return (
      <CorrectiveActionComment
        key={'og-ca'}
        initialCA={true}
        status={vm.correctiveAction?.status ?? 'NA'}
        viewMode={vm.viewMode}
        comment={notes ?? 'No Description Provided.'}
        images={images ?? []}
        title={question}
        subTitle={`Reported by ${authorName}`}
        subSubTitle={`${dateTodayMMMDYYYYatTime(createdAt)}`}
        dueDate={dueDate ?? null}
        contractorName={contractorName ?? 'No Contractor Provided'}
        responsiblePartyName={responsiblePartyName}
        contactPersonName={contactPersonName}
        answer={answer}
        location={location}
        locationHierarchies={locationHierarchies}
        isLocationEnabled={flags.isLocationEnabled}
        isSubLocationEnabled={flags.isSubLocationEnabled}
        subLocations={[
          sublocationLevel1Name,
          sublocationLevel2Name,
          sublocationLevel3Name,
          sublocationLevel4Name,
          sublocationLevel5Name,
        ].filter((slName) => slName != '')}
      />
    )
  }

  const renderCorrectiveAction = () => {
    if (!vm.correctiveAction) return null
    const {
      question,
      images,
      dueDate,
      notes,
      contractorName,
      answer,
      authorName,
      authorId,
      status,
      createdAt = '',
      contactPersonName,
      location,
      responsiblePartyName,
      sublocationLevel1Name,
      sublocationLevel2Name,
      sublocationLevel3Name,
      sublocationLevel4Name,
      sublocationLevel5Name,
    } = vm.correctiveAction as ICorrectiveAction

    // First case no comments, this is the initial corrective action created by the formIO form
    if (vm.comments.length === 0) {
      return (
        <CorrectiveActionComment
          initialCA={true}
          title={question}
          answer={answer}
          images={images ?? []}
          subTitle={`Submitted by ${authorName}`}
          subSubTitle={dateTodayMMMDYYYYatTime(createdAt)}
          status={status}
          // Remove this when we have the correct date
          dueDate={dueDate ?? null}
          contractorName={contractorName}
          contactPersonName={contactPersonName}
          responsiblePartyName={responsiblePartyName}
          comment={notes ?? 'No Description Provided.'}
          location={location}
          locationHierarchies={locationHierarchies}
          isLocationEnabled={flags.isLocationEnabled}
          isSubLocationEnabled={flags.isSubLocationEnabled}
          subLocations={[
            sublocationLevel1Name,
            sublocationLevel2Name,
            sublocationLevel3Name,
            sublocationLevel4Name,
            sublocationLevel5Name,
          ].filter((slName) => slName != '')}
        >
          {renderFirstCommentSection(authorId ?? '')}
        </CorrectiveActionComment>
      )
    }

    // Second case, there are comments
    const comments = vm.comments.map(
      ({ id, senderId, notes, images, createdAt, senderName }, i) => {
        const name = senderName
          ? senderName
          : vm.correctiveAction
            ? senderId == vm.correctiveAction.authorId
              ? vm.correctiveAction?.authorName
              : vm.correctiveAction?.contractorName
            : ''
        return (
          <CorrectiveActionComment
            key={id}
            comment={notes}
            viewMode={vm.viewMode}
            images={images}
            // title={name ?? ''}
            title={senderName ?? ''} //TODO CHECK IF THIS FIX WORKS
            subTitle={dateTodayMMMDYYYYatTime(createdAt)}
          >
            {/* the first comment can either show a button group or be toggled to a form*/}
            {i === 0 && renderFirstCommentSection(senderId)}
          </CorrectiveActionComment>
        )
      },
    )

    // add the initial corrective action to the top of the comments
    return [renderInitialCorrectiveActionSection(), ...comments]
  }

  // This first comment is a little tricky, renders forms with in forms, with different logic for different users
  const renderFirstCommentSection = (senderId: string) => {
    // this is a more logical way but contractor id not setup on BE yet
    // const isNotContractorComment = ownerId != vm.user?.id
    const isNotContractorComment = senderId == vm.correctiveAction?.authorId

    if (
      showV2Form
      //  ||
      // (vm.isContractor && !vm.isClosed && vm.correctiveAction?.status == 'Open')
    ) {
      return (
        <CorrectiveActionFormV2
          {...vm.correctiveAction}
          setCorrectiveAction={vm.setCorrectiveAction}
          viewMode={vm.viewMode}
          apiCall={apiCall}
          closeForm={() => setShowV2Form(false)}
        />
      )
    }
    return renderCommentButtonGroup(senderId)
  }

  const renderCommentButtonGroup = (senderId: string) => {
    if (!vm.correctiveAction) return null

    const isNotContractorComment = senderId == vm.correctiveAction?.authorId
    //1. if completed, show completed
    if (vm.isClosed) {
      return (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ETypography font='SS' color='neutral950'>
            {`Marked as closed by
            ${vm.correctiveAction.closedByName ?? 'Unknown'} on
            ${dateTodayMMMDYYYYatTime(vm.correctiveAction.closedAt ?? '')}`}
          </ETypography>
        </Box>
      )
    }

    //2. if under review, show under review, with out option to comment
    // for contractors
    if (vm.isContractor) {
      return (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {isNotContractorComment ? (
            <EdifyButton
              buttonStyle={{ marginRight: '24px' }}
              secondary
              onClick={commentClicked}
              title='Comment'
            />
          ) : (
            <EdifyButton
              disabled
              buttonStyle={{ marginRight: '24px' }}
              secondary
              title='Waiting for review'
            />
          )}
        </Box>
      )
    }

    // 4. Admin button group to send back, comment, complete
    if (user && user.roles[0].name == 'READ_ONLY_ADMIN') {
      return <></>
    }
    return (
      <Box
        style={{
          marginTop: '24px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {/* <EdifyButton
          onClick={() => navigate(-1)}
          buttonStyle={{ marginRight: '0px', padding: 0 }}
          primary
          noBackground
          title='Go back'
        /> */}
        {/* only show these if AUTHOR for now */}

        <Box sx={{ display: 'flex', gap: '24px' }}>
          {/* There is not a great way to do this but makes the most sense at this point */}
          <>
            {vm.isUnderReview &&
              vm.comments.length > 0 &&
              vm.comments[0].senderId != vm.correctiveAction.authorId && (
              <EdifyButton
                secondary
                onClick={sendBackClicked}
                title='Send back'
              />
            )}
            {/* This could make sense to */}
            {/* {vm.isUnderReview && vm.comments.length > 0 && (
              <EdifyButton
                secondary
                onClick={sendBackClicked}
                title='Set back to open'
              />
            )} */}
          </>
          <EdifyButton secondary onClick={commentClicked} title='Comment' />
          {/* An admin should be able to close a CA if there are no comments, but backend won't allow it at the moment */}

          <EdifyButton onClick={closeClicked} primary title={'Close'} />
        </Box>
      </Box>
    )
  }
  const commentClicked = () => {
    setShowV2Form(true)
    setApiCall('comment')
  }
  const sendBackClicked = () => {
    setShowV2Form(true)
    setApiCall('sendBack')
  }

  const closeClicked = () => {
    setShowV2Form(true)
    setApiCall('close')
  }

  const handleGoToSubmission = () => {
    const submissionId = vm.correctiveAction!.submissionId
    const formId = vm.correctiveAction!.formId

    navigate(`/forms/submissions/${formId}/submission/${submissionId}`, {
      state: {},
    })
  }
  const checkCanViewSubmission = async () => {
    if (vm.correctiveAction!.submissionId) {
      const res = await getSubmissionById(vm.correctiveAction!.submissionId)
      if (res.right) {
        setCanViewSubmission(true)
      }
    }
  }
  useEffect(() => {
    checkCanViewSubmission()
    setShowV2Form(vm.isContractor && !vm.isClosed && vm.correctiveAction?.status == 'Open')
  }, [vm.correctiveAction])

  const renderLinkToSubmission = (canView: boolean) => {
    if (vm.isContractor) return null
    if (!canView) return null

    // return <Button onClick={handleGoToSubmission} variant="text">View Submission</Button>

    // return <EdifyButton
    //   title={'View Submission'}
    //   onClick={handleGoToSubmission}
    // />

    return (
      <Link
        sx={{
          paddingTop: '8px',
          ...AppFonts.textMMedium,
          '&: hover': {
            cursor: 'pointer',
          },
        }}
        onClick={handleGoToSubmission}
        underline='hover'
        color={AppColors.primary600}
      >
        View Submission
      </Link>
    )
  }

  const renderBreadCrumbs = () => {
    if (vm.isContractor) return []

    const submissionId = vm.correctiveAction!.submissionId
    const formId = vm.correctiveAction!.formId

    const breadCrumbs = [
      {
        title: 'All Corrective Actions',
        to: ROUTE_CORRECTIVE_ACTIONS,
      },
      {
        title: `Form: ${vm.correctiveAction?.formTitle ?? 'Loading...'}`,
        to: `/forms/submissions/${formId}`,
      },
      // {
      //   title: `Submission by ${vm.correctiveAction!.authorName}`,
      //   to: `/forms/submissions/${formId}/submission/${submissionId}`,
      // },
      {
        title: 'Corrective Action',
        to: '#',
      },
    ]
    return breadCrumbs
  }

  return (
    <PageContainer
      title={
        vm.correctiveAction?.formTitle ? 'Corrective Action' : 'Loading...'
      }
      breadCrumbs={renderBreadCrumbs()}
      // subtitle={`Form: ${vm.correctiveAction!.formTitle}`}
      subTitleComponent={renderLinkToSubmission(canViewSubmission)}
      mainPageLoading={vm.correctiveActionLoading || vm.commentsLoading}
      mainPageReload={vm.reloadCorrectiveAction}
      mainPageError={vm.correctiveActionLoadError || vm.commentsError}
    >
      {renderCorrectiveAction()}
    </PageContainer>
  )
}

export const CorrectiveActionPage: React.FC = () => {
  return (
    <CorrectiveActionPageViewModel>
      <_CorrectiveActionPage />
    </CorrectiveActionPageViewModel>
  )
}
