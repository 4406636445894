export interface IRole {
  id: string
  name: string
  description: string
  userCreate?: number
  userRead?: number
  userEdit?: number
  formCreate?: number
  formEdit?: number
  formRead?: number
  createdAt: string
  updatedAt: string
  contractorCreate?: number
  goalCreate?: number
  goalEdit?:number
  contractorEdit?: number
  correctiveActionEditOwn?: number
  correctiveActionRead?: number
  levelCreate?: number
  levelEdit?: number
  levelDelete?: number
  levelRead?: number
  submissionCreateAll?: number
  submissionEditAll?: number
  submissionEditHierarchy?:number
  submissionEditOwn?: number
  submissionReadAll?: number
  submissionReadOwn?: number
  projectCreate?: number
  projectEdit?: number
  projectRead?: number
  certificateCreate?: number
  trainingCertificateEditOwn?: number
  trainingCertificateRead?: number
  trainingCertificateEditAll?: number
  trainingCertificateCreate?: number
  trainingCertificateCreateOwn?: number
 
}

export enum ERole {
  UserCreate = 'userCreate',
  UserRead = 'userRead',
  UserEdit = 'userEdit',
  FormCreate = 'formCreate',
  FormEdit = 'formEdit',
  FormRead = 'formRead',
  GoalCreate = 'goalCreate',
  GoalEdit = 'goalEdit',
  GoalDelete = 'goalDelete',
  ContractorCreate = 'contractorCreate',
  ContractorEdit = 'contractorEdit',
  CorrectiveActionEditOwn = 'correctiveActionEditOwn',
  CorrectiveActionRead = 'correctiveActionRead',
  LevelCreate = 'levelCreate',
  LevelEdit = 'levelEdit',
  LevelDelete = 'levelDelete',
  LevelRead = 'levelRead',
  SubmissionCreateAll = 'submissionCreateAll',
  SubmissionEditAll = 'submissionEditAll',
  SubmissionEditHierarchy = 'submissionEditHierarchy',
  SubmissionEditOwn = 'submissionEditOwn',
  SubmissionReadAll = 'submissionReadAll',
  SubmissionReadOwn = 'submissionReadOwn',
  ProjectCreate = 'projectCreate',
  ProjectEdit = 'projectEdit',
  ProjectRead = 'projectRead',

  TrainingCertificateCreate = 'trainingCertificateCreate',
  TrainingCertificateEdit = 'trainingCertificateEditAll',
  TrainingCertificateEditOwn = 'trainingCertificateEditOwn',
  TrainingCertificateRead = 'trainingCertificateRead',
  TrainingCertificateCreateOwn = 'trainingCertificateCreateOwn'
}




