import { IUser } from './IUser'


export interface IDivisionStats {
  total_divisions: number,
  total_active_divisions: number,
  total_inactive_divisions: number,
  total_account_holders: number
}

export interface IProject {
  id: string
  name: string
  orgId: string
  createdAt: string
  updatedAt: string
  startDate: string
  endDate: string
  active: boolean
  deleted?: boolean 
  daysRemaining?:string
  pointOfContact?:IUser
  imageURL?: string
  favorited?: boolean 
  levels?:ILevel[]
}
// DOuble check this when you do EDIT PROJECT TICKET
export interface IProjectFormData {
  id?: string
  name?: string
  endDate?: string | null
  startDate?: string | null
  active?: boolean
  pointOfContact?:string | null
  imageURL?: string | null
  levelOneIds?:string[] | null
}

export interface ILevel {
  id: string
  name: string
  level: number
  orgId: string
  parentId?: string
  deleted?: boolean
  createdAt: string
  updatedAt: string
  active?: boolean 
  favorited?: boolean
  projects: IProject[]
  pointOfContact?: IUser
}

export interface ILevelList {
  data: ILevel[]
  message: string
  total_count?: number
}

export interface IProjectList {
  data: IProject[]
  message: string
  total_count: number 
}

export interface IDeletedObject {
  id: string
  deleted: boolean 
}

// just 1 (division) for now, will go up to 5
export enum LevelOptions {
  One = '1',
}