import { Box, Typography } from '@mui/material'
import React from 'react'
import { ROUTE_NOTIFICATIONS } from './notifications/NotificationsPage'
import { useLocation, useNavigate } from 'react-router-dom'
import PageContainer from '../components/page/PageContainer'
import { EdifyButton } from '../components/buttons'

import ErrorContainer from '../components/error/ErrorContainer'
import { ROUTE_ORG_SELECTION } from './org_selection/OrgSelectionPage'

//TODO: NEED ACTUAL DESIGN FOR THIS PAGE
const NotFoundPage: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { from } = location.state || { from: { pathname: '/' } }

  // coming from the /notifications page show a back link
  const isFromNotification = () => {
    if (!from || from.pathname !== ROUTE_NOTIFICATIONS) return
    return (
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          margin: 32,
        }}
      >
        <ErrorContainer>
          <Typography>
            It seems this link is either broken or the page no longer exists
          </Typography>
          <EdifyButton
            noBackground
            title='Go back to notifications'
            onClick={() => navigate(-1)}
          />
        </ErrorContainer>
      </Box>
    )
  }

  return (
    <PageContainer title='Page Not Found'>
      <EdifyButton
        primary
        noBackground
        title='Return To Home'
        onClick={() => navigate(ROUTE_ORG_SELECTION)}
      >
      </EdifyButton>
      {/* TODO check if the came from safetymojo page */}
      {/* <EdifyButton
        primary
        noBackground
        title='Go Back'
        onClick={() => navigate(-1)}
      >
      </EdifyButton> */}
      {isFromNotification()}
    </PageContainer>
  )
}

export default NotFoundPage
