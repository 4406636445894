import { Circle } from '@mui/icons-material'
import React from 'react'
import { NavbarLinkTile } from '../../tiles/navbar-tile/NavbarLinkTile'
import { Box, CircularProgress } from '@mui/material'
import {
  OrganizationProviderProps,
  useOrganizationProvider,
} from '../../../../providers/OrganizationProvider'
import { IHierarchyName } from '../../../../domain/interfaces/IHierarchyName'
import { ROUTE_LEVELS } from '../../../pages/levels/LevelsPage'
import { ROUTE_PROJECTS } from '../../../pages/projects/ProjectsPage'
import { EdifyButton } from '../../buttons'
import ErrorContainer from '../../error/ErrorContainer'
import { AppColors } from '../../../Theme'

const HierarchyLinks: React.FC = () => {
  const vm: OrganizationProviderProps = useOrganizationProvider()
  if (!vm) return null

  if (vm.hierarchiesLoading) {
    return (
      <Box
        data-testid='HierarchiesLoading'
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (vm.hierarchiesError) {
    return (
      <ErrorContainer
        data-testid='HierarchiesLoadingError'
        error={'Error Loading Forms'}
        textStyle={{ color: AppColors.neutral700 }}
      >
        <EdifyButton
          data-testid='HierarchiesReload'
          buttonStyle={{ padding: '0px 24px' }}
          noBackground
          title='Reload Hierarchies'
          onClick={vm.reloadHierarchies}
        />
      </ErrorContainer>
    )
  }
  // 0 -projects
  // 1 - levels/1
  // Will add more in future
  const getPath = (level: number) => {
    if (level == 1) {
      return `${ROUTE_LEVELS}/1`
    }
    return ROUTE_PROJECTS
  }
  return (
    <>
      {vm.hierarchies?.map((hierarchy: IHierarchyName, i: number) => {
        // TODO figure order
        const title = i == 0 ? vm.getHierarchyName(1, true) : vm.getHierarchyName(0, true)
      
        return (
          <NavbarLinkTile
            pathMatch={getPath(hierarchy.level)}
            key={`${hierarchy.id}-${i}`}
            data-testid='EdifyDrawer_Hierarchies_Incidents'
            route={`${getPath(hierarchy.level)}`}
            iconComponent={Circle}
            iconComponentStyle={{
              height: '6px',
            }}
            linkTitle={title}
            style={{ paddingLeft: '56px' }}
          />
        )
      })}
    </>
  )
}

export default HierarchyLinks
