import React, { useContext, useEffect, useState } from 'react'
import { ISubmission } from '../../../domain/interfaces/ISubmission'
import {
  SubmissionQueryField,
  getFormFilters,
  getSubmissionsWithQueryObject,
} from '../../../domain/domain'
import { ICategory, IForm } from '../../../domain/interfaces/IForm'
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid'
import { useParams } from 'react-router-dom'
import { DEFAULT_LIMIT } from '../../components/datagrid/EdifyDataGrid'
import { IFormFilter } from '../../../domain/interfaces/IFormHeader'

import useQuery from '../../../core/hooks/useQuery'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { getAllFormCategories } from '../../../data/data'
import { normalizeDynamicHeadersForTableView } from '../../../core/utils/formio-formatters/FormIOFormatter'
import useSessionQuery from '../../../core/hooks/useSessionQuery'

export interface SubmissionsPageViewModelProps {
  clearError?: () => void
  clearFilters: (reload: boolean) => void
  fetchSubmissions: (skip: number, query: SubmissionQueryField) => void
  // form?: IForm | undefined
  form: IForm
  formError?: string
  // formHeaders: IFormHeader[] | undefined
  isLoading: boolean
  isLoadMore: boolean
  isSearching: boolean
  loadMore: (paginationModel: GridPaginationModel) => void
  queryHelper: Record<string, any>
  reloadForm: () => void
  reloadPage: () => void
  reloadSubmissions: () => void
  setTotalCount?: (value: number) => void
  sortClickedHandler: (sorter: GridSortModel) => void
  submissionError?: string
  submissions?: ISubmission[] | undefined
  totalCount: number
  // for form settings
  categories: ICategory[]
  setCategories: (cat: ICategory[]) => void
  loadingCategories: boolean
  categoriesError: string | undefined
  setForm: (form: IForm) => void
  normalizedDynamicHeaders: any
  formFilters: IFormFilter[]
  formFiltersError: string | undefined
  formFiltersLoading: boolean
  hasCreatedAtHeader: boolean
}

const SubmissionPageContext =
  React.createContext<SubmissionsPageViewModelProps | null>(null)

export function useSubmissionsPageViewModel(): SubmissionsPageViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(SubmissionPageContext)!
}
interface Props {
  children: React.ReactElement | React.ReactElement[]
  formIdProp?: string
}

export const SubmissionsPageViewModel: React.FC<Props> = ({
  children,
  formIdProp,
}) => {
  let { formId } = useParams()
  // for submission modal
  if (formIdProp) {
    formId = formIdProp
  }
  const orgProvider = useOrganizationProvider()
  const formFromOrgProvider = orgProvider.getFormById(formId!)

  // if webHeaders are set createdAt must be an option, if it is not use the first header as the default sort
  const webHeaders = formFromOrgProvider?.webHeaders ?? []
  const hasCreatedAtHeader = webHeaders.some((x) => x.key === 'createdAt')
    ? true
    : false

  const queryHelper = useQuery({
    skip: 0,
    limit: DEFAULT_LIMIT,
    sortBy: 'createdAt',
    sortOrder: 'desc',
    filters: [],
    filterId: undefined,
  })

  const { query, getQueryStringFromQuery, setNew, defaultQuery } = queryHelper

  const [isLoading, setIsLoading] = useState({
    initialLoad: true,
    loadMore: false,
    search: false,
  })

  //// Form state
  const [form, setForm] = useState<IForm>(formFromOrgProvider)
  // const [formHeaders, setFormHeaders] = useState<IFormHeader[] | undefined>(undefined)
  // Form state
  const [formError, setFormError] = useState<string | undefined>(undefined)

  const [formFilters, setFormFilters] = useState<IFormFilter[]>([])

  const [formFiltersError, setFormFiltersError] = useState<string | undefined>(
    undefined,
  )

  const [formFiltersLoading, setFormFiltersLoading] = useState<boolean>(true)
  // Submission state
  const [totalCount, setTotalCount] = useState<number>(0)
  const [submissionError, setSubmissionError] = useState<string | undefined>(
    undefined,
  )
  const [submissions, setSubmissions] = useState<ISubmission[] | undefined>(
    undefined,
  )

  // category state
  const [loadingCategories, setLoadingCategories] = useState(true)
  const [categories, setCategories] = useState<ICategory[]>([])
  const [categoriesError, setCategoriesError] = useState<string | undefined>(
    undefined,
  )

  const [normalizedDynamicHeaders, setNormalizedDynamicHeaders] =
    useState<any>(undefined)

  const clearFilters = async (reload = false) => {
    // reloads data but doesn't clear inputs
    if (reload) {
      fetchSubmissions(0, { ...defaultQuery, filterId: '', filters: [] })
    }
  }

  const loadCatagories = async () => {
    setLoadingCategories(true)
    setCategoriesError(undefined)
    const res = await getAllFormCategories()
    setLoadingCategories(false)
    if (res.right) {
      setCategories(res.right.data)
      return
    }
    setCategoriesError('Error')
  }

  // handles loading submissions or corrective actions
  const fetchSubmissions = async (
    fetchSkip: number,
    query: Record<string, any>,
  ) => {
    setIsLoading((oldState) => {
      return { ...oldState, loadMore: true }
    })
    setSubmissionError(undefined)
    const newQuery = { ...query, skip: fetchSkip }
    const res = await getSubmissionsWithQueryObject(formId!, newQuery)

    setIsLoading((oldState) => {
      return { ...oldState, initialLoad: false, loadMore: false }
    })
    if (res.isLeft()) {
      // submissionError handles both submissions and corrective actions
      setSubmissionError(res.left?.message)
      // if error set back to what is was
      setNew(query)
      return
    }
    setSubmissions(res.right?.data as ISubmission[])
    setTotalCount(res.right?.total_count || 0)
    setNew(newQuery)
  }

  const loadMore = (paginationModel: GridPaginationModel) => {
    const fetchSkip = paginationModel.page * paginationModel.pageSize
    fetchSubmissions(fetchSkip, query)
  }

  const getAllSubmissions = async () => {
    setSubmissions([])
    fetchSubmissions(0, defaultQuery)
  }

  const sortClickedHandler = (sorter: GridSortModel) => {
    // if (isLoading.initialLoad) return
    const { field, sort } = sorter[0]
    const newQuery = {
      ...query,
      skip: 0,
      sortBy: field,
      sortOrder: sort,
      // sortOrder: sort?.toUpperCase(),
    }
    fetchSubmissions(0, newQuery)
  }

  const loadFormAndSubmissions = async () => {
    setIsLoading((oldState) => {
      return { ...oldState, initialLoad: true }
    })
    setTotalCount(-1)
    let formFromOrgProvider = orgProvider.forms.find(
      (f) => f.id == formId,
    ) as IForm
    if (!formFromOrgProvider) {
      formFromOrgProvider = orgProvider.categoryForms.find(
        (f) => f.id == formId,
      ) as IForm
    }
    setForm(formFromOrgProvider)
    const normalizedDynamicHeaders =
      normalizeDynamicHeadersForTableView(formFromOrgProvider)

    setNormalizedDynamicHeaders(normalizedDynamicHeaders)

    setSubmissionError(undefined)
    setFormError(undefined)

    getAllSubmissions()
  }

  const loadFormFilters = async () => {
    const res = await getFormFilters(form.id)
    setFormFiltersLoading(false)
    if (res.isLeft()) {
      setFormFiltersError(res.left?.message)
      return
    }
    setFormFilters(res.right!)
  }

  useEffect(() => {
    loadCatagories()
    clearFilters()
    loadFormAndSubmissions()
    loadFormFilters()
  }, [formId, formIdProp])

  const reloadPage = () => {
    loadFormAndSubmissions()
    loadFormFilters()
  }

  const onClearError = () => {
    setFormError(undefined)
  }

  return (
    <SubmissionPageContext.Provider
      value={{
        submissions,
        isLoading: isLoading.initialLoad,
        isLoadMore: isLoading.loadMore,
        isSearching: isLoading.search,
        loadMore,
        form,
        formError,
        submissionError,
        clearError: onClearError,
        reloadForm: loadFormAndSubmissions,
        reloadSubmissions: getAllSubmissions,
        reloadPage,
        // formHeaders,
        clearFilters,
        fetchSubmissions,
        queryHelper,
        totalCount,
        sortClickedHandler,
        categories,
        setCategories,
        loadingCategories,
        categoriesError,
        setForm,
        normalizedDynamicHeaders,
        formFilters,
        formFiltersError,
        formFiltersLoading,
        hasCreatedAtHeader,
      }}
    >
      {children}
    </SubmissionPageContext.Provider>
  )
}
export default SubmissionPageContext
