import { Either, Failure } from '../../../core/core'
import {
  currentPerson,
  getInitialToken,
  getToken,
  isLoggedIn,
  loginRepo,
  logout,
  requestOtp,
  verifyOtp,
} from '../../../data/data'
import { currentOrgId } from '../../../data/repositories/orgs/OrgRepository'
import { ISSOResponse } from '../../interfaces/IPerson'
import { IUser } from '../../interfaces/IUser'
import { IVerifyOTPPayload } from '../../interfaces/IUserDetails'

export function isUserLoggedIn(): boolean {
  return isLoggedIn()
}

export async function loginEmail(
  email: string,
): Promise<Either<Failure, boolean>> {
  return loginRepo({ email: email })
}

export async function loginPhone(
  phone: string,
): Promise<Either<Failure, boolean>> {
  return loginRepo({ phone: phone })
}

export async function requestEmailOtpCode(
  email: string,
): Promise<Either<Failure, any>> {
  return requestOtp({ email: email })
}

export async function requestPhoneOtpCode(
  phone: string,
): Promise<Either<Failure, any>> {
  return requestOtp({ phone: phone })
}

export async function verifyEmailOtpCode(
  pin: string,
  email: string,
): Promise<Either<Failure, IVerifyOTPPayload>> {
  return verifyOtp({ email: email }, pin)
}

export async function verifyPhoneOtpCode(
  pin: string,
  phone: string,
): Promise<Either<Failure, IVerifyOTPPayload>> {
  return verifyOtp({ phone: phone }, pin)
}

export function getCurrentPerson(): IVerifyOTPPayload | undefined {
  return currentPerson()
}

export function getCurrentUser(): IUser | undefined {
  const orgId = currentOrgId()
  return currentPerson()?.users.find((user) => user.orgId === orgId)
}

export function logoutUser(): boolean {
  return logout()
}

export function getAuthToken(): string {
  return getToken()
}
