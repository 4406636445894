import React, { useContext, useEffect, useState } from 'react'

import { IDashboardTokens } from '../../../domain/interfaces/IDashboard'
import { adminGetRoles, getCurrentUser } from '../../../domain/domain'
import { getDashboardTokens } from '../../../domain/usecases/dashboards/DashboardsUsecases'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { IRole } from '../../../domain/interfaces/IRole'
import { useParams } from 'react-router-dom'

export interface IRoleSettingsViewModelProps {
  getRole: (id: string) => IRole | undefined
  role: IRole | undefined
}

const RoleSettingsContext =
  React.createContext<IRoleSettingsViewModelProps | null>(null)

export function useRoleSettingsViewModel(): IRoleSettingsViewModelProps {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return useContext(RoleSettingsContext)!
}

interface Props {
  children: React.ReactElement | React.ReactElement[]
}

export const RoleSettingsViewModel: React.FC<Props> = ({ children }) => {
  const { roles } = useOrganizationProvider()
  const { id } = useParams()
  const [role, setRole] = useState<IRole | undefined>(undefined)

  const getRole = (id: string) => {
    if (!roles) return undefined
    const role = roles.find((r) => (r.id === id))
    setRole(role)
  }
  useEffect(() => {
    if (!id) return
    getRole(id)
  })

  return (
    <RoleSettingsContext.Provider
      value={{
        getRole,
        role,
      }}
    >
      {children}
    </RoleSettingsContext.Provider>
  )
}
