import React from 'react'
import { Box, Button, Grid } from '@mui/material'
import PageContainer from '../../components/page/PageContainer'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { OrgSettingsViewModel } from './OrgSettingsViewModel'
import { SettingsInfoBlock } from '../formSettings/FormSettingsPage'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import ErrorContainer from '../../components/error/ErrorContainer'
import { dateToMMMDYYYY } from '../../../core/core'
import PersonPermissions from '../person_profile/PersonPermissions'
import AddFormToOrgForm from './AddFormToOrgForm'
import AddCorrectiveActionToOrgForm from './AddCorrectiveActionToOrgForm'
import { createSubLocationHierarchyName } from '../../../domain/domain'
import OrgFlags from './OrgFlags'

export const ROUTE_ORG_SETTINGS = '/orgSettings'

const _OrgSettingsPage: React.FC = () => {
  const { org } = useOrganizationProvider()

  if (!org)
    return (
      <ErrorContainer>
        <p>no org found</p>
      </ErrorContainer>
    )

  return (
    <PageContainer
      title='Organization Settings'
      breadCrumbs={[
        { title: 'Settings', to: '' },
        { title: 'Organization', to: ROUTE_ORG_SETTINGS },
      ]}
    >
      <InnerPageContainer
        headerTitle='General'
        sx={{ padding: '24px', marginBottom: '24px' }}
      >
        <Grid container>
          <SettingsInfoBlock header='Org Name' value={org.name} />
          <SettingsInfoBlock header='Org ID' value={org.id} />
          <SettingsInfoBlock
            header='Created'
            value={dateToMMMDYYYY(org.createdAt)}
          />
          <SettingsInfoBlock
            header='Expires'
            value={dateToMMMDYYYY(org.terminationDate ?? '')}
          />
          <SettingsInfoBlock
            header='User Limit'
            value={org.userLimit + '' ?? 'NA'}
          />
          <SettingsInfoBlock header='Type' value={org.type ?? 'NA'} />
        </Grid>
      </InnerPageContainer>
      {/* <UpdateOrgForm  org={org}/> */}
      <AddFormToOrgForm />
      <AddCorrectiveActionToOrgForm />
      <OrgFlags />
      {/* <PersonPermissions /> */}
    </PageContainer>
  )
}
const OrgSettingsPage = () => {
  return (
    <OrgSettingsViewModel>
      <_OrgSettingsPage />
    </OrgSettingsViewModel>
  )
}
export default OrgSettingsPage
