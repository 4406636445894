import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { AppColors, AppFonts } from '../../../Theme'
import { UpArrow } from '../../../../core/components/icons/UpArrow'
import { DownArrow } from '../../../../core/components/icons/DownArrow'

interface IEdifyFormGroupCollapseProps {
  collapsed?: boolean
  children: React.ReactNode
  title: string
}

export const EdifyFormGroupCollapse: React.FC<IEdifyFormGroupCollapseProps> = ({
  collapsed = false,
  title = '',
  children,
}) => {
  const [collapse, setCollapse] = useState(collapsed)

  const renderArrow = () => {
    return (
      <Box sx={{pointer:'curser'}}onClick={()=>setCollapse(!collapse)}>
        {collapse ? <UpArrow /> : <DownArrow />}
      </Box>
    )
  }
  return (
    <Box
  
      sx={{
        padding: '12px 20px',
        border: '1px solid #DEE2E6', //Match Formio
        background: AppColors.baseWhite,
        height: 'auto',
        borderRadius: '10px',
        marginBottom: '8px',
      }}
    >
      <Box style={{display:'flex', justifyContent:'space-between'}}>
        <Typography
          className='formio-component-panel card-title'
          sx={{ ...AppFonts.textXLSemibold, color: AppColors.gray700 , fontWeight:400, fontSize:'20px'}}
        >
          {title}
        </Typography>
        {renderArrow()}
      </Box>
      {!collapse && children}
    </Box>
  )
}
export default EdifyFormGroupCollapse
