import React from 'react'
import PageContainer from '../../components/page/PageContainer'
import {
  FormsPageViewModel,
  FormsPageViewModelProps,
  useFormsPageViewModel,
} from './FormsPageViewModel'
import FormsDataGrid from './FormsDataGrid'
import FormsFilter from './FormsFilter'
import { useGlobalProvider } from '../../../providers/GlobalProvider'
// import { EdifyButton } from '../../components/buttons'
// import usePermission from '../../../core/hooks/usePermission'
// import { ERole } from '../../../domain/interfaces/IRole'
export const ROUTE_FORMS = '/forms'

export const FormsPage: React.FC = () => {
  return (
    <FormsPageViewModel>
      <_Page />
    </FormsPageViewModel>
  )
}

export const _Page: React.FC = () => {
  const vm: FormsPageViewModelProps = useFormsPageViewModel()
  const { isGlobalUser, isGlobalUserView } = useGlobalProvider()
  // const canCreate = usePermission(ERole.FormCreate)

  return (
    <PageContainer
      title={'All Forms'}
      pageSettingsLink={
        isGlobalUser && isGlobalUserView
          ? `${ROUTE_FORMS}/settings`
          : undefined
      }
      breadCrumbs={[{ title: 'All Forms', to: `${ROUTE_FORMS}/all` }]}
    >
      <FormsFilter vm={vm} />
      <FormsDataGrid vm={vm} title={'Form'} checkboxSelection={false} />
    </PageContainer>
  )
}
