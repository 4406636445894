import React, { useEffect, useState } from 'react'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'
import { Box, Input } from '@mui/material'
import { EdifyButton } from '../../components/buttons'
import { httpPost, saveToLocalStorage } from '../../../data/data'
import {
  ErrorToast,
  SuccessToast,
} from '../../../core/utils/toast-notifications/ToastNotifications'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { EdifyFieldLabel, EdifyFormField } from '../../components/form'
import ErrorContainer from '../../components/error/ErrorContainer'
import { updateOrg } from '../../../data/repositories/orgs/OrgRepository'
import HierarchyLinks from '../../components/banners/drawer/HierarchyLinks'
import {
  createSubLocationHierarchyName,
  updateLocation,
  updateSubLocationHierarchyName,
} from '../../../domain/domain'
import { ETypography } from '../../components/fonts/ETypography'
import { levelStyles } from './locationStyles'
import {
  Circle,
  DeleteForever,
  DeleteOutline,
  Garage,
  Save,
} from '@mui/icons-material'
import { subLocationHierarchyNameDelete } from '../../../data/repositories/locations/LocationsRepository'
import { useLocationPageViewModel } from './LocationViewModel'
import { ISubLocationHierarchyName } from '../../../domain/interfaces/ILocation'
import { useSubLocationPageViewModel } from './SubLocationViewModel'
import { TrashIcon } from '../../../core/components/icons/TrashIcon'

interface Props {
  locationId: string | undefined
}
const LocationHierarchyForm: React.FC<Props> = ({ locationId }) => {
  const { location, locationHierarchies, setLocationHierarchies } =
    useSubLocationPageViewModel()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [hierarchiesFormState, setHierarchiesFormState] = useState<any>([])
  const setHierarchies = () => {
    const cloneH = []
    const length = locationHierarchies?.length ?? 5
    for (let i = 0; i < 5; i++) {
      if (locationHierarchies && locationHierarchies[i]) {
        const h = locationHierarchies[i]
        const nextH = locationHierarchies[i + 1]
        // const showDelete = nextH && nextH.id ? false : true
        const showDelete = true
        cloneH.push({
          name: h.name,
          level: h.level,
          showDelete: showDelete,
        })
      } else {
        cloneH.push({ id: '', name: '', level: i + 1, showDelete: false })
      }
    }
    setHierarchiesFormState(cloneH)
    return cloneH
  }

  const handleSubmit = async () => {
    // const res = await updateOrg()
    // setLoading(false)
    // if (res.isRight()) {
    //   SuccessToast({
    //     title:
    //       'Successfully added corrective action form to org.',
    //   })
    //   saveToLocalStorage('org', JSON.stringify(res.right))
    //   return
    // }
    // if (res.isLeft()) {
    //   setError(
    //     res.left?.message ?? 'Unsuccessfully added corrective action form.',
    //   )
    // }
  }
  const handleChange = (e: any, index: number) => {
    const cloneState: any = [...hierarchiesFormState]
    cloneState[index]['name'] = e.target.value
    setLocationHierarchies(cloneState)
    setHierarchiesFormState(cloneState)
  }
  const handleDelete = async (index: number) => {
    const cloneState: any = [...hierarchiesFormState]
    cloneState[index]['name'] = ''
    setLocationHierarchies(cloneState)
    setHierarchiesFormState(cloneState)
  }
  const handleUpdate = async () => {
    setLoading(true)
    const res = await updateLocation(
      {
        level1Name: hierarchiesFormState[0].name,
        level2Name: hierarchiesFormState[1].name,
        level3Name: hierarchiesFormState[2].name,
        level4Name: hierarchiesFormState[3].name,
        level5Name: hierarchiesFormState[4].name,
      },
      location!.id!,
    )
    setLoading(false)
    if (res.isRight() && res.right) {
      const { level1Name, level2Name, level3Name, level4Name, level5Name } =
        res.right
      setLocationHierarchies([
        { name: level1Name, level: 1 },
        { name: level2Name, level: 2 },
        { name: level3Name, level: 3 },
        { name: level4Name, level: 4 },
        { name: level5Name, level: 5 },
      ])
    }
  }

  useEffect(() => {
    setHierarchies()
  }, [locationHierarchies])
  return (
    <>
      <InnerPageContainer
      // headerTitle='Add Corrective Action Form to Org'
      // sx={{ marginTop: '24px' }}
      >
        {error && (
          <ErrorContainer sx={{ margin: '24px 0' }}>{error}</ErrorContainer>
        )}
        {hierarchiesFormState &&
          hierarchiesFormState.map((h:any, i:number) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                  gap: '16px',
                }}
                key={`hierarchies-${i}`}
              >
                <ETypography font='MS' color='gray700'>
                  {`Level ${i + 1} `}
                </ETypography>
                <Box
                  sx={{
                    // background: levelStyles[i].background,
                    borderRadius: '5px',
                    display: 'flex',
                    // padding: '8px',
                    flex: 1,
                    alignItems: 'center',
                    gap: '8px',
                    // minHeight: '44px',
                  }}
                >
                  <Circle
                    sx={{
                      fontSize: '12px',
                      color: levelStyles[i].color,
                      margin: '8px 0',
                    }}
                  />
                  <Input
                    id={h.id}
                    disableUnderline
                    className={'form-control'}
                    value={h.name}
                    onChange={(e) => handleChange(e, i)}
                  />
                </Box>
                {/* {h.showDelete && ( */}
                {/* <div onClick={() => handleDelete(i)}>
                  <TrashIcon />
                </div> */}
              </Box>
            )
          })}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '24px',
            width: '100%',
          }}
        >
          <EdifyButton
            onClick={handleUpdate}
            title={'Update Names'}
            loading={loading}
          />
        </Box>
      </InnerPageContainer>
    </>
  )
}

export default LocationHierarchyForm
