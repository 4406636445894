import React, { useState } from 'react'
import {
  SubmissionsPageViewModel,
  SubmissionsPageViewModelProps,
  useSubmissionsPageViewModel,
} from './SubmissionsViewModel'

import PageContainer from '../../components/page/PageContainer'
import { EdifyButton } from '../../components/buttons/edifyButton/EdifyButton'

import { useNavigate, useParams } from 'react-router-dom'
import SubmissionFilter from './SubmissionsFilter'
import SubmissionDataGrid from './SubmissionsDataGrid'
import { ROUTE_FORMS } from '../forms/FormsPage'
import { useGlobalProvider } from '../../../providers/GlobalProvider'
import { useOrganizationProvider } from '../../../providers/OrganizationProvider'
import { canCreateSubmission } from '../submission/submissionPermissionHelpers'
import { getCurrentUser } from '../../../domain/domain'
import { Box, Button } from '@mui/material'
import { showQRCodeMessage } from '../../components/dialogs/qr-code/QRCodeDialog'
import WithResetErrorBoundary from '../wrappers/ErrorBoundaryWrapper'
import DynamicSubmissionFilter from './DynamicSubmissionFilter'
import InnerPageContainer from '../../components/inner-page-container/InnerPageContainer'

export const ROUTE_SUBMISSIONS = '/submissions'
export const ROUTE_CORRECTIVE_ACTIONS = '/correctiveActions'

export const SubmissionsPage: React.FC = () => {
  return (
    <SubmissionsPageViewModel>
      <_Page />
    </SubmissionsPageViewModel>
  )
}

export const _Page: React.FC = () => {
  const navigate = useNavigate()
  const user = getCurrentUser()
  const { formCategory, formId } = useParams()
  const vm: SubmissionsPageViewModelProps = useSubmissionsPageViewModel()
  const { isGlobalUserView, isGlobalUser } = useGlobalProvider()
  const { isUserInOrg, org } = useOrganizationProvider()

  const gotoNewPage = () => {
    navigate(`/${formCategory}${ROUTE_SUBMISSIONS}/${formId}/submission/new`, {
      state: { form: vm.form },
    })
  }

  const showQRCode = () => {
    const url = `${window.location.origin}/anonymous/submissions?orgId=${org?.id}&formId=${formId}`
    showQRCodeMessage(url)
  }

  if (vm.formError) {
    return (
      <PageContainer title='Error Occurred'>
        <EdifyButton
          data-testid='FormErrorButton'
          noBackground
          title='Reload'
          onClick={vm.reloadPage}
        />
      </PageContainer>
    )
  }

  const getTitle = () => {
    return vm.form?.title || 'Submissions'
  }
  // for Testing until BE done
  const [show, setShow] = useState(false)
  return (
    <PageContainer
      mainPageLoading={vm.isLoading}
      pageSettingsLink={
        isGlobalUser && isGlobalUserView
          ? `/${formCategory}${ROUTE_SUBMISSIONS}/${formId}/settings`
          : undefined
      }
      title={getTitle()}
      breadCrumbs={[
        { title: 'All Forms', to: `${ROUTE_FORMS}/all` },
        {
          title: getTitle(),
          to: `/${formCategory}${ROUTE_SUBMISSIONS}/${formId}`,
        },
      ]}
      renderRightItems={() => {
        const canCreate = canCreateSubmission(vm.form, user) && isUserInOrg
        return (
          <Box sx={{ display: 'flex', gap: '24px' }}>
            {vm.form?.allowAnonymous && (
              <EdifyButton
                noBackground
                title={'Generate QR Code'}
                onClick={showQRCode}
              />
            )}
            {canCreate && (
              <EdifyButton
                disabled={!isUserInOrg}
                title={`New ${vm.form?.title}`}
                onClick={gotoNewPage}
              />
            )}
          </Box>
        )
      }}
    >
      {/* <SubmissionFilter vm={vm} /> */}
      <DynamicSubmissionFilter vm={vm} />
      {/* TEMP FIX FOR SORT LOOP BUG<  */}
      {vm.form && vm.form.id == formId && (
        <WithResetErrorBoundary>
          <SubmissionDataGrid vm={vm} title={getTitle()} />
        </WithResetErrorBoundary>
      )}
    </PageContainer>
  )
}
